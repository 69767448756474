<!--
  These components are almost the same:
  - MyRegistrations
  - SingleRegistration
  - NewRegistration
  - Payment

  Remember to update all if they require updating.
-->

<template>
  <div v-if="registration" class="registration">
    <!-- <MelvioBanner melvioLink=/> -->
    <Registration
      :registration="registration"
      :titleTranslationKey="titleTranslationKey"
      :showRegistrationPrices="false"
      :showInvoices="true"
      :showPaymentColumn="true"
    />
  </div>

  <div v-else-if="hasError" class="card">
    <div class="card-content">
      <h1 class="title">{{ $t('payment.title.error') }}</h1>
      <p class="mt-4">{{ $t('registration.getRegistrationError') }}</p>
    </div>
  </div>

  <section v-else-if="isLoading" class="registration card skeleton-wrapper">
    <div class="skeleton-content-wrapper">
      <b-skeleton width="100%" height="20rem" :animated="true"></b-skeleton>
      <b-skeleton width="100%" height="20rem" :animated="true"></b-skeleton>
    </div>
  </section>
</template>

<script lang="ts">
import { defineComponent, onMounted } from '@vue/composition-api';

import { useGetMyRegistrations } from '../hooks/useRegistrationApi';
import useTitle from '../hooks/useTitle';
import router from '../router';
import { stateHasError, stateIsLoading } from '../utils/api-utils';
import { translate } from '../utils/misc-utils';

import Registration from '../components/Registration.vue';

export default defineComponent({
  components: { Registration },
  setup: (props, ctx) => {
    const {
      response: registration,
      execute: getMyRegistrations,
      state: getMyRegisrationsState
    } = useGetMyRegistrations();
    const { setTitle } = useTitle();

    const {
      email: emailRaw,
      reqid: reqIdRaw,
      expiry: expiryRaw,
      hmac: hmacRaw
    } = router.currentRoute.query;

    const reqid = reqIdRaw ? (reqIdRaw as string) : undefined;
    const expiry = expiryRaw ? new Date(expiryRaw as string) : undefined;
    const hmac = hmacRaw ? (hmacRaw as string) : undefined;
    const email = emailRaw ? (emailRaw as string) : undefined;
    const titleTranslationKey = 'registration.registrations';

    const hasError = stateHasError(getMyRegisrationsState);
    const isLoading = stateIsLoading(getMyRegisrationsState);

    onMounted(() => {
      if (email && reqid && expiry && hmac) {
        getMyRegistrations({ email, reqid, expiry, hmac });
      } else {
        router.push({ name: 'my-registrations-login-link' });
      }

      setTitle(translate(ctx, titleTranslationKey));
    });

    return {
      hasError,
      isLoading,
      registration,
      titleTranslationKey
    };
  }
});
</script>
