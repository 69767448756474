<!-- eslint-disable vue/multi-word-component-names -->
<style lang="scss" scoped>
.sort-title {
  display: flex;
  align-items: center;
  font-weight: 700;
  color: $primary;
  color: var(--brandcolor);
  cursor: pointer;
}

.sort-container {
  align-self: flex-end;
}

.sort-title {
  background-color: initial;
  box-sizing: border-box;
  border: none;
  border-radius: 2px;
}
</style>

<style lang="scss">
.sort-title > span {
  display: flex;
}
</style>

<template>
  <div class="sort-container">
    <b-dropdown :value="sortModel" aria-role="list" @change="setSort">
      <template #trigger>
        <b-button class="sort-title">
          <span v-if="sortModel && sortModel.field">
            {{ $t(`order.${sortModel.field}${sortModel.dir}`) }}
          </span>
          <span v-else>
            {{ $t('order.mostrelevant') }}
          </span>
          <b-icon icon="chevron-down" size="is-medium" />
        </b-button>
      </template>
      <b-dropdown-item href="#" :value="null" aria-role="listitem" v-if="keyword">
        {{ $t('order.mostrelevant') }}
      </b-dropdown-item>
      <b-dropdown-item
        href="#"
        :value="{ field: CourseSortfield.Name, dir: Sortdir.Asc }"
        aria-role="listitem"
      >
        {{ $t('order.nameasc') }}
      </b-dropdown-item>
      <b-dropdown-item
        href="#"
        :value="{ field: CourseSortfield.Name, dir: Sortdir.Desc }"
        aria-role="listitem"
      >
        {{ $t('order.namedesc') }}
      </b-dropdown-item>
      <b-dropdown-item
        href="#"
        :value="{ field: CourseSortfield.Begins, dir: Sortdir.Asc }"
        aria-role="listitem"
      >
        {{ $t('order.beginsasc') }}
      </b-dropdown-item>
      <b-dropdown-item
        href="#"
        :value="{ field: CourseSortfield.Begins, dir: Sortdir.Desc }"
        aria-role="listitem"
      >
        {{ $t('order.beginsdesc') }}
      </b-dropdown-item>
      <b-dropdown-item
        href="#"
        :value="{ field: CourseSortfield.Code, dir: Sortdir.Asc }"
        aria-role="listitem"
      >
        {{ $t('order.codeasc') }}
      </b-dropdown-item>
      <b-dropdown-item
        href="#"
        :value="{ field: CourseSortfield.Code, dir: Sortdir.Desc }"
        aria-role="listitem"
      >
        {{ $t('order.codedesc') }}
      </b-dropdown-item>
    </b-dropdown>
  </div>
</template>

<script lang="ts">
import { defineComponent, PropType, ref, watch } from '@vue/composition-api';
import { Sortdir } from '../../api/models/Sortdir';
import { CourseSortfield } from '../../api/models/CourseSortfield';
import { isEqual } from 'lodash/fp';

export interface Sorting {
  field: CourseSortfield;
  dir: Sortdir;
}

export default defineComponent({
  props: {
    sort: {
      type: Object as PropType<Sorting>,
      required: false
    },
    keyword: {
      type: String,
      required: false
    }
  },
  setup: (props, ctx) => {
    const sortModel = ref<Sorting | undefined>(props.sort);

    // Can't set value as undefined in template so converting it here
    const setSort = (newSort: Sorting | null) => {
      sortModel.value = newSort || undefined;
      ctx.emit('sort-changed', sortModel.value);
    };

    watch(
      () => props.sort,
      (val) => {
        if (!isEqual(sortModel.value, val)) {
          sortModel.value = props.sort;
        }
      }
    );

    return {
      sortModel,
      CourseSortfield,
      Sortdir,
      setSort
    };
  }
});
</script>
