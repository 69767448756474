<!-- eslint-disable vue/multi-word-component-names -->
<style lang="scss" scoped>
.search {
  margin-bottom: 2rem;
}

.sorting {
  display: flex;
  justify-content: space-between;
  align-items: center;
  width: 100%;

  &.desktop-filters {
    flex-direction: column;
    margin-top: 0;
  }
}

.filters {
  display: flex;
  flex-direction: column;

  @media (max-width: 800px) {
    position: sticky;
    z-index: 2;
    top: 5rem;
  }
  @media (max-width: 450px) {
    top: 4rem;
  }
}

.course-list {
  display: flex;
  flex-direction: column;
  position: sticky;
  top: 20px;
}

.mainContent {
  display: grid;
  grid-template-columns: 15rem 1fr;
  grid-gap: 1rem;
}

.container {
  margin: 0 6rem;
  max-width: 100%;
}

.filtergroup-label {
  font-weight: 700;
  text-transform: capitalize;
}

.filter-arrow {
  color: $lightGray;
  margin-left: 0.5rem;
}

.mobile-filters {
  display: none;
  padding: 1rem;
  background-color: $bg-white;
  margin: -1rem -0.5rem;

  @media (min-width: 401px) {
    margin: -1rem;
  }
}

.mobile-filters .sorting {
  margin: 0rem;

  @media (max-width: 650px) {
    display: none;
  }
}

@media (min-width: 651px) {
  .only-mobile {
    display: none;
  }
}

.mobile-filter-container {
  padding: 0 1rem;
}

.result-amount {
  color: $lightGray;
  font-weight: 700;
  line-height: 1.5rem;
}

.order-title {
  display: flex;
  align-items: center;
  font-weight: 700;
  color: $primary;
  color: var(--brandcolor);
}

.sorting-top-row {
  display: flex;
  width: 100%;
  flex-direction: row;
  justify-content: space-between;
  margin-bottom: 0.75rem;
  margin-top: 0.25rem;
  align-items: center;
}

header {
  display: flex;
  align-items: center;
  width: 100%;
  margin-bottom: 1rem;
}

.container {
  @media (min-width: $fullhd) {
    margin: 0 auto;
  }
  @media (max-width: $fullhd) {
    margin: 0 3rem;
  }
  @media (max-width: $tablet) {
    margin: 0 2rem;
  }
  @media (max-width: $mobile-small) {
    margin: 0 1rem;
  }
}

@media (min-width: 801px) {
  .hide-desktop {
    display: none;
  }
}

@media (max-width: 800px) {
  .mainContent {
    display: flex;
    flex-direction: column;
    margin-top: 0;
  }
  .mobile-filters {
    display: block;
  }
  .desktop-filters {
    display: none;
  }
}

@media (max-width: $mobile-small) {
  .mobile-filter-container {
    padding: 0 0.5rem;
  }
}
</style>

<template>
  <div>
    <Hero @search="setKeyword" />

    <PromotionCarousel class="promotion-carousel" />

    <div class="mainContent" id="mainContent">
      <div class="filters">
        <div class="desktop-filters">
          <div class="search desktop-filters">
            <label for="desktop-aside-search" class="is-sr-only">{{ $t('search.label') }}</label>
            <SearchInput
              id="desktop-aside-search"
              :placeholder="$t('search.term')"
              @search="setKeyword"
              iconRight
            />
          </div>
          <CatalogFilters @filters-changed="setFilters" />
        </div>
        <MobileFilters class="mobile-filters" @search="setKeyword">
          <template v-slot:filters>
            <CatalogFilters class="mobile-filter-container" @filters-changed="setFilters" />
          </template>
          <template v-slot:sorting>
            <div class="sorting">
              <p class="result-amount" v-if="isLoading">{{ $t('coursesLoading') }}</p>
              <p class="result-amount" v-else>{{ $tc('results', courseCount) }}</p>
              <Sort @sort-changed="setSorting" />
            </div>
          </template>
        </MobileFilters>
        <ScrollToFilters />
      </div>
      <div class="hide-desktop">
        <div class="sorting only-mobile">
          <p class="result-amount" v-if="isLoading">{{ $t('coursesLoading') }}</p>
          <p class="result-amount" v-else>{{ $tc('results', courseCount) }}</p>
          <Sort @sort-changed="setSorting" />
        </div>
        <FilterTags @filters-changed="setFilters" @search="setKeyword" />
      </div>
      <div>
        <div class="sorting desktop-filters">
          <div class="sorting-top-row">
            <p class="result-amount" v-if="isLoading">{{ $t('coursesLoading') }}</p>
            <p class="result-amount" v-else>{{ $tc('results', courseCount) }}</p>
            <Sort @sort-changed="setSorting" :keyword="keyword" :sort="sort">
              <p class="result-amount" v-if="isLoading">{{ $t('coursesLoading') }}</p>
              <p class="result-amount" v-else>{{ $tc('results', courseCount) }}</p>
            </Sort>
          </div>
          <FilterTags @filters-changed="setFilters" @search="setKeyword" />
        </div>
        <CourseList class="course-list" :sort="sort" />
      </div>
    </div>
  </div>
</template>

<script lang="ts">
import {
  computed,
  defineComponent,
  onBeforeMount,
  ref,
  watch,
  onMounted
} from '@vue/composition-api';

import { CourseSortfield } from '../api/models/CourseSortfield';
import { Sortdir } from '../api/models/Sortdir';
import router from '../router';
import { stateIsLoading, useToast } from '../utils/api-utils';
import { filterUndefineds } from '../utils/misc-utils';
import { useListCourses } from '../hooks/useCourseApi';
import useSearchParams from '../hooks/useSearchParams';

import CourseList from '../components/home/CourseList.vue';
import CatalogFilters from '../components/home/CatalogFilters.vue';
import MobileFilters from '../components/home/MobileFilters.vue';
import PromotionCarousel from '../components/home/PromotionCarousel.vue';
import SearchInput from '../components/home/SearchInput.vue';
import Sort, { Sorting } from '../components/home/Sort.vue';
import Hero from '../components/home/Hero.vue';
import useTitle from '../hooks/useTitle';
import { useCatalogSettings } from '../hooks/useCatalogApi';
import { CourseSortOrder } from '../api';
import FilterTags from '../components/home/FilterTags.vue';
import ScrollToFilters from '../components/home/ScrollToFilters.vue';

export default defineComponent({
  components: {
    PromotionCarousel,
    CourseList,
    Hero,
    SearchInput,
    CatalogFilters,
    MobileFilters,
    Sort,
    FilterTags,
    ScrollToFilters
  },
  setup: (props, ctx) => {
    const { warnToast, clearErrorToasts } = useToast(ctx);
    const { keyword, filters } = useSearchParams(ctx);
    const { response: catalogSettings, execute: getCatalogSettings } = useCatalogSettings();

    const { setTitle } = useTitle();

    const sort = ref<Sorting | undefined>(undefined);
    const queryString = computed(() => {
      return filterUndefineds([keyword.value, ...filters.value]).join(' ');
    }); // Combined search keyword + filters to a format that can be sent straight to the API

    const { state: listCoursesState, response: listCoursesResponse } = useListCourses();
    const courseCount = computed(() => listCoursesResponse.value.count);
    const isLoading = stateIsLoading(listCoursesState);
    const defaultCourseSortOrder = computed(() => catalogSettings?.value?.defaultCourseSortOrder);

    const setFilters = (selectedFilters: string[]) => {
      filters.value = selectedFilters;
    };

    const setSorting = (selectedSorting: Sorting | undefined) => {
      sort.value = selectedSorting;
    };

    const setSortingByCourseSortOrder = (sortfield: CourseSortOrder | undefined) => {
      switch (sortfield) {
        case CourseSortOrder.Code:
          setSorting({ field: CourseSortfield.Code, dir: Sortdir.Asc });
          break;
        case CourseSortOrder.Date:
          setSorting({ field: CourseSortfield.Begins, dir: Sortdir.Asc });
          break;
        case CourseSortOrder.Datedesc:
          setSorting({ field: CourseSortfield.Begins, dir: Sortdir.Desc });
          break;
        case CourseSortOrder.Name:
          setSorting({ field: CourseSortfield.Name, dir: Sortdir.Asc });
          break;
      }
    };

    // This sets the search keyword which causes the computed queryString to recalculate,
    // which in turn causes the watcher below to push the new search params to the router
    const setKeyword = (searchInput: string) => {
      if (searchInput && /[:"+]/gm.test(searchInput)) {
        warnToast('search.invalidinput');
        return;
      } else {
        clearErrorToasts();
      }

      if (searchInput && searchInput.length > 0) {
        setSorting(undefined);
        keyword.value = searchInput;
      } else {
        setSortingByCourseSortOrder(defaultCourseSortOrder.value);
        keyword.value = undefined;
      }
    };

    onBeforeMount(() => {
      setTitle();

      if (keyword.value && keyword.value.length > 0) {
        setSorting(undefined);
      } else {
        setSortingByCourseSortOrder(defaultCourseSortOrder.value);
      }
    });

    onMounted(() => {
      getCatalogSettings();
    });

    watch(queryString, () => {
      // prevent trying to navigate to the current URL on page load
      if (ctx.root.$route.query.q === queryString.value) {
        return;
      }

      router.push({
        name: 'home',
        ...(queryString.value
          ? {
              query: {
                q: queryString.value,
                page: '1'
              }
            }
          : {})
      });
    });

    watch(defaultCourseSortOrder, () => {
      setSortingByCourseSortOrder(defaultCourseSortOrder.value);
    });

    return {
      courseCount,
      isLoading,
      keyword,
      setKeyword,
      setFilters,
      setSorting,
      sort,
      CourseSortfield,
      Sortdir,
      defaultCourseSortOrder
    };
  }
});
</script>
