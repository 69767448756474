import { computed, onMounted, Ref, ref, SetupContext, watch } from '@vue/composition-api';
import { isValid, parse } from 'date-fns';
import { difference, mapValues, sumBy, uniqBy, values } from 'lodash/fp';
import { HellewiCatalog, HellewiCatalogItem, HellewiCatalogItemType } from '../api';
import { useCatalogSettings, useGetCatalog, useGetCatalogUnfiltered } from './useCatalogApi';
import useSearchParams from './useSearchParams';

import { translate } from '../utils/misc-utils';

export interface FilterGroup {
  filters: Filter[];
  name: string;
  type: string;
}

export interface Filter {
  keyword: string;
  name: string;
  inputId: string;
  disabled: boolean;
  subFilters: Filter[];
  courseCount: number;
  parent?: string; // Parent keyword
  siblings: string[]; // Sibling keyword,
  type?: HellewiCatalogItemType;
  translatelabel?: boolean;
}

const filterNames: Record<string, string[]> = {}; // resetFilterGroupsia varten

// empty string as keyword probably breaks something, filter those
// out later
const getKeyword = (catalogItem: HellewiCatalogItem): string =>
  catalogItem.keywords && catalogItem.keywords.length > 0 ? catalogItem.keywords[0] : '';

// Make these refs 'global' by scoping them out of useCatalogFilters scope
const filterGroups = ref<FilterGroup[]>([]);
const selected = ref<string[]>([]);
const selectedParents = ref<string[]>([]);

type catalogFiltersReturnType = {
  resetCatalogFilterGroups: () => void;
  filterGroups: Ref<FilterGroup[]>;
  selected: Ref<string[]>;
  isGroupOpen: (groupName: string) => boolean;
  toggleGroupOpen: (groupName: string) => void;
  selectedParents: Ref<string[]>;
  openSubFilters: Ref<string[]>;
  toggleSubfilterGroup: (
    keyword: string,
    subFilters: Filter[],
    desiredGroupOpenState?: boolean | null
  ) => void;
  getFilterTags: () => Filter[];
  findFiltersForKeyword: (catalogFilters: Filter[], keyword: string) => Filter[];
  getFilterGroupIndividualFilters: () => Filter[];
  parseDateinputFilter: (
    keyword: string,
    ctx?: SetupContext
  ) => { dateStr: string | false; codeStr: string; comparatorStr: string };
};

export const useCatalogFilters = (ctx: SetupContext): catalogFiltersReturnType => {
  const { filters, query: queryString } = useSearchParams(ctx);
  const { response: settings, execute: getCatalogSettings } = useCatalogSettings();
  const { response: catalog, execute: getCatalog } = useGetCatalog();
  const { response: catalogUnfiltered, execute: getCatalogUnfiltered } = useGetCatalogUnfiltered();

  const openSubFilters = ref<string[]>([]);

  // by default all groups are closed except the ones listed below
  const closedGroups = ref<string[]>(
    localStorage.getItem('closed_filtergroups')?.split('|') ||
      difference(
        [...values(HellewiCatalogItemType), HellewiCatalogItemType.Categorysubject],
        [
          HellewiCatalogItemType.Tag,
          HellewiCatalogItemType.Teachingformat,
          HellewiCatalogItemType.Department,
          HellewiCatalogItemType.Category,
          HellewiCatalogItemType.Subject,
          HellewiCatalogItemType.Language,
          HellewiCatalogItemType.Levelofstudy,
          HellewiCatalogItemType.Unit,
          HellewiCatalogItemType.Categorysubject
        ]
      )
  );

  const combinedCatalog = computed<HellewiCatalog | undefined>(() => {
    if (!catalogUnfiltered.value || !catalog.value) {
      return;
    }

    return mapValues((catalogItems: HellewiCatalogItem[]) => {
      const filtered: HellewiCatalogItem[] = Object.values(
        // eslint-disable-next-line @typescript-eslint/no-explicit-any
        (catalog.value as any) as HellewiCatalogItem[]
      ).flat();

      return catalogItems.map((ci: HellewiCatalogItem) => {
        const found = filtered.find(
          (c: HellewiCatalogItem) => c.keywords?.[0] === ci.keywords?.[0]
        );
        return found ? found : { ...ci, coursecount: 0 };
      });
    })(catalogUnfiltered.value) as HellewiCatalog;
  });

  // Toggle subFilterGroup open / closed
  const toggleSubfilterGroup = (
    keyword: string,
    subFilters: Filter[],
    desiredGroupOpenState: boolean | null = null
  ) => {
    if (
      desiredGroupOpenState === false ||
      (desiredGroupOpenState === null &&
        (openSubFilters.value.find((key) => key === keyword) ||
          subFilters.some(({ disabled }) => !disabled)))
    ) {
      // Close group
      openSubFilters.value = openSubFilters.value.filter(
        (subFilterKeyword) => subFilterKeyword !== keyword
      );
      subFilters.forEach((subFilter) => (subFilter.disabled = true));
      return;
    }
    // Open group
    openSubFilters.value.push(keyword);
    subFilters.forEach((subFilter) => (subFilter.disabled = false));
  };

  const filterShouldBeHidden = (keyword: string, coursecount?: number) =>
    coursecount === 0 && !selected.value.includes(keyword);

  const catalogItemToFilter = (
    catalogItem: HellewiCatalogItem,
    subCatalogItems: HellewiCatalogItem[]
  ): Filter => {
    const keyword = getKeyword(catalogItem);

    if (catalogItem) {
      if (!catalogItem.parent && !(catalogItem.type in filterNames)) {
        filterNames[catalogItem.type] = [catalogItem.type];
      } else if (catalogItem.parent) {
        const parent = catalogItem.parent.split(':')[0];
        if (parent in filterNames && !filterNames[parent].includes(catalogItem.type)) {
          filterNames[parent].push(catalogItem.type);
        }
      }
    }

    const subFilters = subCatalogItems
      .filter((sci) => sci.parent === keyword)
      .map((x) => catalogItemToFilter(x, []))
      .filter(
        ({ courseCount, keyword: kw }) =>
          !filterShouldBeHidden(kw, courseCount) ||
          (catalogItem.parent && selectedParents.value.includes(catalogItem.parent))
      )
      .map((x: Filter) => {
        return { ...x, disabled: !openSubFilters.value.includes(keyword) };
      });

    const subFilterCourseCountSum = sumBy((f) => f.courseCount, subFilters);

    // Generate siblings for subFilters
    subFilters.forEach(
      (subFilter) =>
        (subFilter.siblings = subFilters
          .filter(({ keyword: kw }) => subFilter.keyword !== kw)
          .map(({ keyword: kw }) => kw))
    );

    // Open subFilterGroup if a subFilter is active
    if (
      subFilters.some(
        ({ keyword: kw, parent }) =>
          parent && selected.value.includes(kw) && !openSubFilters.value.includes(parent)
      )
    ) {
      toggleSubfilterGroup(keyword, subFilters, true);
    }

    return {
      inputId: `input-${keyword}`,
      name: catalogItem.name,
      keyword,
      disabled:
        subFilters.length > 0
          ? subFilterCourseCountSum === 0
          : filterShouldBeHidden(keyword, catalogItem.coursecount),
      courseCount: subFilters.length > 0 ? subFilterCourseCountSum : catalogItem.coursecount || 0,
      parent: catalogItem.parent,
      subFilters,
      siblings: [],
      type: catalogItem.type,
      translatelabel: catalogItem.translatelabel
    };
  };

  const fetchFilters = (): FilterGroup[] => {
    if (!combinedCatalog.value || !catalogUnfiltered.value) {
      return [];
    }
    const {
      subject,
      department,
      category,
      period,
      weekday,
      location,
      tag,
      teachingformat,
      language,
      levelofstudy,
      educationsector,
      unit,
      educationtype,
      locationgroup,
      coursetype,
      date,
      term
    } = combinedCatalog.value;

    const updateCourseCounts = (
      catalogItems: HellewiCatalogItem[],
      subCatalogItems: HellewiCatalogItem[]
    ): Filter[] => {
      return catalogItems.map((x) => catalogItemToFilter(x, subCatalogItems));
    };

    // filter automatic tags based on enabledcatalogitemtypes
    // filter out only those tags that has specific setting for keyword
    // show only tags that don't have setting or setting is true
    const filteredtag = tag.filter((t) => {
      if (!t.keywords) {
        return true;
      }
      return t.keywords.every((kw) => {
        const [, value] = kw.split(':');
        return settings.value?.enabledcatalogitemtypes[value] !== false;
      });
    });
    const specialTags = tag.filter((t) => {
      if (!t.keywords) {
        return true;
      }
      return t.keywords.every((kw) => {
        const [, value] = kw.split(':');
        return settings.value?.enabledcatalogitemtypes[value] === true;
      });
    });

    const filterGroups = [
      {
        name: HellewiCatalogItemType.Date,
        type: 'checkbox',
        filters: updateCourseCounts(
          date.filter((f) => {
            return (
              (f.name === 'begins' && settings.value?.enabledcatalogitemtypes['date']) ||
              settings.value?.enabledcatalogitemtypes[f.name]
            );
          }),
          []
        )
      },
      {
        name: HellewiCatalogItemType.Tag,
        type: 'checkbox',
        filters: updateCourseCounts(
          settings.value?.enabledcatalogitemtypes[HellewiCatalogItemType.Tag]
            ? filteredtag
            : specialTags,
          []
        )
      },
      {
        name: HellewiCatalogItemType.Teachingformat,
        type: 'checkbox',
        filters: updateCourseCounts(teachingformat, [])
      },
      {
        name: HellewiCatalogItemType.Unit,
        type: 'checkbox',
        filters: updateCourseCounts(unit, [])
      },
      {
        name: HellewiCatalogItemType.Department,
        type: 'checkbox',
        filters: updateCourseCounts(department, [])
      },

      {
        name: HellewiCatalogItemType.Category,
        type: 'checkbox',
        filters: updateCourseCounts(
          category.filter((c) => !c.parent),
          []
        )
      },
      {
        name: 'categorysubject',
        type: 'checkbox',
        filters: updateCourseCounts(
          category.filter((c) => !c.parent),
          subject
        )
      },
      {
        name: HellewiCatalogItemType.Subject,
        type: 'checkbox',
        filters: updateCourseCounts(
          subject.filter((s) => !s.parent),
          []
        )
      },
      {
        name: HellewiCatalogItemType.Language,
        type: 'checkbox',
        filters: updateCourseCounts(language, [])
      },
      {
        name: HellewiCatalogItemType.Levelofstudy,
        type: 'checkbox',
        filters: updateCourseCounts(levelofstudy, [])
      },
      {
        name: HellewiCatalogItemType.Educationsector,
        type: 'checkbox',
        filters: updateCourseCounts(educationsector, [])
      },
      {
        name: HellewiCatalogItemType.Period,
        type: 'checkbox',
        filters: updateCourseCounts(period, [])
      },
      {
        name: HellewiCatalogItemType.Weekday,
        type: 'checkbox',
        filters: updateCourseCounts(weekday, [])
      },
      {
        name: HellewiCatalogItemType.Locationgroup,
        type: 'checkbox',
        filters: updateCourseCounts(locationgroup, [])
      },
      {
        name: HellewiCatalogItemType.Location,
        type: 'checkbox',
        filters: updateCourseCounts(location, [])
      },
      {
        name: HellewiCatalogItemType.Educationtype,
        type: 'checkbox',
        filters: updateCourseCounts(educationtype, [])
      },
      {
        name: HellewiCatalogItemType.Coursetype,
        type: 'checkbox',
        filters: updateCourseCounts(coursetype, [])
      },
      {
        name: HellewiCatalogItemType.Term,
        type: 'checkbox',
        filters: updateCourseCounts(term, [])
      }
    ].filter(
      (filterGroup) =>
        (filterGroup.name === 'date' && filterGroup.filters.length > 0) ||
        (filterGroup.name === HellewiCatalogItemType.Tag && specialTags.length > 0) ||
        settings.value?.enabledcatalogitemtypes[filterGroup.name]
    );
    return filterGroups;
  };

  const updateFilterGroups = () => {
    if (settings.value && catalog.value && catalogUnfiltered.value) {
      filterGroups.value = fetchFilters();
    }
  };

  const resetCatalogFilterGroups = () => {
    selected.value = [];
    selectedParents.value = [];
    ctx.emit('filters-changed', selected.value);
  };

  const isGroupOpen = (groupName: string) => !closedGroups.value.includes(groupName);

  const toggleGroupOpen = (groupName: string) => {
    if (closedGroups.value.includes(groupName)) {
      closedGroups.value = closedGroups.value.filter((fg) => fg !== groupName);
    } else {
      closedGroups.value.push(groupName);
    }

    localStorage.setItem('closed_filtergroups', closedGroups.value.join('|'));
  };

  // Break down filter group filters into single array of filters
  const getFilterGroupIndividualFilters = () =>
    filterGroups.value
      .flatMap(({ filters: filterGroupFilters }) => filterGroupFilters)
      .flatMap((filter) => (filter.siblings ? [filter, ...filter.subFilters] : [filter]));

  const findFiltersForKeyword = (catalogFilters: Filter[], keyword: string) => {
    return catalogFilters.filter((filter) => {
      const filteredFilters =
        (filter.type !== HellewiCatalogItemType.Dateinput && filter.keyword === keyword) ||
        (filter.type === HellewiCatalogItemType.Dateinput &&
          filter.keyword.indexOf(keyword.slice(0, keyword.indexOf(':'))) !== -1 &&
          filter.keyword.indexOf('currentdate') === -1);

      if (
        filter.type === HellewiCatalogItemType.Dateinput &&
        filter.keyword.indexOf(keyword.slice(0, keyword.indexOf(':'))) !== -1 &&
        filter.keyword !== keyword
        // &&
        // parseDateinputFilter(keyword).dateStr
      ) {
        filter.keyword = keyword;
      }
      return filteredFilters;
    });
  };

  const parseDateinputFilter = (
    keyword: string,
    ctx?: SetupContext
  ): { dateStr: string | false; codeStr: string; comparatorStr: string } => {
    const code = keyword.slice(0, keyword.indexOf(':'));
    const rest = keyword.slice(keyword.indexOf(':'));

    // eslint-disable-next-line no-useless-escape
    const match = rest.match(/\:([!<>=]*)(.*)/);

    const comparatorMatch = match ? match[1] : '';
    const dateMatch = match ? match[2] : '';

    const codeStr = ctx ? translate(ctx, `filterTags.${code}`) : '';
    const comparatorStr = ctx ? translate(ctx, `filterTags.${comparatorMatch}`) : '';
    const dateIsValid = isValid(parse(dateMatch, 'yyyy-MM-dd', new Date()));
    const dateStr = dateIsValid
      ? parse(dateMatch, 'yyyy-MM-dd', new Date()).toLocaleDateString()
      : false;

    return { dateStr, codeStr, comparatorStr };
  };

  const getFilterTags = () => {
    const currentFilters = getFilterGroupIndividualFilters();
    const tagsWithDuplicates = selectedParents.value
      .concat(selected.value)
      .flatMap((keyword) => findFiltersForKeyword(currentFilters, keyword))
      // Fetch parent tags for subfilters
      .flatMap((filter) =>
        filter.parent?.includes('category:')
          ? findFiltersForKeyword(currentFilters, filter.parent).concat([filter])
          : [filter]
      )
      .flatMap((filter) => (filter ? [filter] : []))
      // If all sibling filters are active displaying only the parent tag will suffice
      .filter(
        ({ siblings }) =>
          !(
            siblings.length > 0 &&
            siblings.every((siblingKeyword) => selected.value.includes(siblingKeyword))
          )
      )
      // Don't show subfilter tag if it's the only child for the parent filter
      .filter(({ parent, siblings }) => !(parent?.includes('category:') && siblings.length === 0))
      .sort((a, b) => (a.subFilters.length > b.subFilters.length ? -1 : 1));
    return uniqBy('name', tagsWithDuplicates);
  };

  watch(filters, () => {
    selected.value = filters.value;
  });
  watch(queryString, () => getCatalog({ q: queryString.value }));
  watch([catalog, settings, catalogUnfiltered], () => {
    updateFilterGroups();
  });

  onMounted(() => {
    getCatalogSettings();
    getCatalogUnfiltered();
    getCatalog({ q: queryString.value });

    selected.value = filters.value;

    // This needs to be here for hot-reloading to work
    updateFilterGroups();
  });

  return {
    resetCatalogFilterGroups,
    filterGroups,
    selected,
    isGroupOpen,
    toggleGroupOpen,
    selectedParents,
    openSubFilters,
    toggleSubfilterGroup,
    getFilterTags,
    findFiltersForKeyword,
    getFilterGroupIndividualFilters,
    parseDateinputFilter
  };
};
