<style lang="scss" scoped>
.image-container {
  display: flex;
  flex-direction: column;
  margin-bottom: 1.5rem;
  width: fit-content;
  max-width: 100%;
}

.image-container.main {
  margin-bottom: 1rem;
}

.image {
  max-height: min(250px, 40vh);
  background-size: cover;
  background-position: center;
  transition: max-height 0.1s ease-out;
}

.main-image {
  max-height: min(400px, 40vh);
}

.enlarge-image-button {
  align-self: center;
  margin-top: 0.5rem;
}

.image.show-full-image {
  max-height: 1200px;
  transition: max-height 0.3s ease-in-out;
}

.invisible-placeholder-image {
  opacity: 0;
  pointer-events: none;
  display: block;
}

.clickable {
  cursor: pointer;
}

@media (max-width: 600px) {
  .main-image {
    max-width: calc(100% + 2rem);
    width: calc(100% + 2rem);
    margin-left: -1rem;
  }
}
@media (min-width: 601px) and (max-width: 700px) {
  .main-image {
    margin-left: -2rem;
    max-width: calc(100% + 4rem);
    width: calc(100% + 4rem);
  }
}
@media (min-width: 701px) {
  .image {
    width: max-content;
    max-width: 100%;
  }
}
</style>
<template>
  <div class="image-container" v-bind:class="{ main: mainImage }">
    <div
      @click="toggleShowImage()"
      ref="imageElement"
      v-bind:style="{ backgroundImage: `url(${image.url})` }"
      class="image"
      v-bind:class="{
        'main-image': mainImage,
        'show-full-image': showFullImage,
        clickable: showToggle
      }"
    >
      <img
        ref="invisibleImageElement"
        v-bind:src="image.url"
        class="invisible-placeholder-image"
        :alt="image.alt ? image.alt : fallbackAltText"
      />
    </div>
    <b-button
      type="is-link is-light"
      size="is-small"
      :icon-right="showFullImage ? 'chevron-up' : 'chevron-down'"
      v-if="showToggle"
      @click="toggleShowImage()"
      class="enlarge-image-button"
      :aria-label="showFullImage ? $t('course.shrinkImage') : $t('course.enlargeImage')"
    >
      {{ showFullImage ? $t('course.shrinkImage') : $t('course.enlargeImage') }}
    </b-button>
  </div>
</template>

<script lang="ts">
import { defineComponent, PropType, ref, onMounted, watch } from '@vue/composition-api';
import { HellewiImage } from '../../api';

interface Image {
  width: number;
  height: number;
}

export default defineComponent({
  props: {
    image: {
      required: true,
      type: Object as PropType<HellewiImage>
    },
    mainImage: {
      type: Boolean
    },
    fallbackAltText: {
      type: String,
      required: false
    }
  },
  setup() {
    const showFullImage = ref<boolean>(false);
    const imgNatural = ref<Image | null>(null);
    const imgVisible = ref<Image | null>(null);
    const imageElement = ref<HTMLElement | null>(null);
    const invisibleImageElement = ref<HTMLImageElement | null>(null);
    const showToggle = ref(false);

    const onImageSizeChange = () => {
      imgVisible.value = imageElement.value
        ? {
            height: imageElement.value?.offsetHeight,
            width: imageElement.value?.offsetWidth
          }
        : null;
    };

    onMounted(() => {
      onImageSizeChange();
      if (imageElement.value) {
        const imageSizeObserver = new ResizeObserver(() => {
          onImageSizeChange();
        });
        imageSizeObserver.observe(imageElement.value);
      }
    });

    const setImageNaturalDimensions = (target: HTMLImageElement) => {
      const { naturalHeight, naturalWidth } = target;
      imgNatural.value =
        naturalHeight && naturalWidth
          ? {
              height: naturalHeight,
              width: naturalWidth
            }
          : null;
    };

    watch(invisibleImageElement, (invisibleImageElement) => {
      if (!invisibleImageElement) {
        return;
      }
      invisibleImageElement.onload = () => setImageNaturalDimensions(invisibleImageElement);
    });

    watch([imgNatural, imgVisible], ([imgNatural, imgVisible]) => {
      if (!imgNatural || !imgVisible) {
        return;
      }
      const visibleAspectRatio = imgVisible.width / imgVisible.height;
      const naturalAspectRatio = imgNatural.width / imgNatural.height;
      // Added 0.05 to not show the toggle when only few pixels are hidden. Also hides a glitch with small screens.
      showToggle.value = visibleAspectRatio > naturalAspectRatio + 0.05 || showFullImage.value;
    });

    const toggleShowImage = () => {
      if (showToggle.value) {
        showFullImage.value = !showFullImage.value;
      }
      onImageSizeChange();
    };

    return {
      showFullImage,
      imageElement,
      showToggle,
      toggleShowImage,
      invisibleImageElement
    };
  }
});
</script>
