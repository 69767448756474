import { memoize } from 'lodash/fp';
import { ref } from '@vue/composition-api';
import { CalloutsApi, CalloutsApiInterface, Configuration, HellewiCallout } from '../api';
import { Api, ApiEndpoint, ApiEndpointInitialization, RequestState } from '../utils/api-utils';

export const useCalloutsApi: Api<CalloutsApiInterface> = memoize(() => {
  const api = ref<CalloutsApiInterface | undefined>(undefined);

  const changeConfiguration = (configuration: Configuration) => {
    api.value = new CalloutsApi(configuration);
  };

  return {
    api,
    changeConfiguration
  };
});

export const useGetLocalCallouts: ApiEndpoint<void, HellewiCallout[]> = memoize(() => {
  const initial: HellewiCallout[] = [];
  const { api } = useCalloutsApi();
  const state = ref<RequestState>(RequestState.Uninitialized);
  const response = ref<HellewiCallout[]>(initial);

  ApiEndpointInitialization(api, state, response, initial);

  const execute = async () => {
    if (
      !api.value ||
      state.value === RequestState.Uninitialized ||
      // request already ongoing, don't start a new one
      state.value === RequestState.Loading ||
      // don't load again if this is already successfully loaded
      state.value === RequestState.Success
    ) {
      return;
    }

    try {
      state.value = RequestState.Loading;
      response.value = await api.value.getLocalCallouts();
      state.value = RequestState.Success;
    } catch {
      response.value = initial;
      state.value = RequestState.Error;
    }
  };

  return {
    initial,
    state,
    response,
    execute
  };
});
