import Vue from 'vue';
import VueRouter, { RouteConfig } from 'vue-router';
import TenantLanguage from './components/TenantLanguage.vue';
import Cart from './views/Cart.vue';
import Course from './views/Course.vue';
import Survey from './views/Survey.vue';
import Help from './views/Help.vue';
import Home from './views/Home.vue';
import MyRegistrations from './views/MyRegistrations.vue';
import MyRegistrationsLoginLink from './views/MyRegistrationsLoginLink.vue';
import NewRegistration from './views/NewRegistration.vue';
import NotFound from './views/NotFound.vue';
import Payment from './views/Payment.vue';
import SingleRegistration from './views/SingleRegistration.vue';

Vue.use(VueRouter);

const routes: RouteConfig[] = [
  {
    path: '/:tenant',
    component: TenantLanguage,
    props: true
  },
  {
    path: '/:tenant/:language',
    component: TenantLanguage,
    props: true,
    children: [
      {
        path: '/',
        name: 'home',
        component: Home
      },
      {
        path: 'cart',
        name: 'cart',
        component: Cart
      },
      {
        path: 'registration',
        name: 'single-registration',
        component: SingleRegistration
      },
      {
        path: 'payment/:paymentmethod/:status',
        name: 'payment',
        props: true,
        component: Payment
      },
      {
        path: 'help',
        name: 'help',
        component: Help
      },
      {
        path: 'course/:id',
        name: 'course',
        component: Course
      },
      {
        path: 'my-registrations/login-link',
        name: 'my-registrations-login-link',
        component: MyRegistrationsLoginLink
      },
      {
        path: 'new-registration',
        name: 'new-registration',
        component: NewRegistration
      },
      {
        path: 'my-registrations',
        name: 'my-registrations',
        component: MyRegistrations
      },
      {
        path: 'survey/:id',
        name: 'survey',
        component: Survey
      },
      {
        // https://github.com/vuejs/vue-router/issues/724#issuecomment-301260298
        path: 'not-found',
        alias: '*',
        name: 'NotFound',
        component: NotFound
      }
    ]
  },
  {
    path: '/not-found',
    alias: '*',
    name: 'NotFoundBrandless',
    component: NotFound
  }
];

const router = new VueRouter({
  mode: 'history',
  scrollBehavior(to, from) {
    // Don't scroll to top if pagination or search params change in the current path
    if (
      to.path === from.path &&
      (to.query.q !== from.query.q || to.query.page !== from.query.page)
    ) {
      return;
    }
    if (to.hash) {
      return { selector: to.hash };
    }

    return { x: 0, y: 0 };
  },
  routes
});

export default router;
