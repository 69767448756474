// Duplicate file from API/src/json-schema/agelimit.ts

import { subYears, endOfDay, startOfDay, isBefore, isAfter, isSameDay } from 'date-fns';

export const calculateMinAndMaxBirthDates = (
  courseStartDate: string,
  courseEndDate: string,
  minAge?: number,
  maxAge?: number,
  minimumAgeOnFirstDay = false // Toggle for minimum age on the first day
  // maybe in the future should also add minimumAgeOnYear or minimumAgeOnSemester to allow enrolling based of age on the calendar year or school semester...
): { minBirthDate?: Date; maxBirthDate?: Date } => {
  // If minimum age is 5 then the person must be 5 years old on the last day of the course (so 4 year olds are allowed to enroll if they become 5 during the course)
  // If minimum age is 5 and minimumAgeOnFirstDay is true then the person must be 5 years old on the first day of the course (so 4 year olds are not allowed to enroll even if they become 5 during the course)
  // If maximum age is 10 then the person must be at most 10 years old on the first day of the course (11th birthday may be on the second day of the course)

  const startDate = new Date(courseStartDate);
  const endDate = new Date(courseEndDate);
  const maxBirthDate = maxAge ? subYears(startDate, maxAge + 1) : undefined;
  const referenceDate = minimumAgeOnFirstDay ? startOfDay(startDate) : endOfDay(endDate);
  const minBirthDate = minAge ? subYears(referenceDate, minAge) : undefined;

  return { minBirthDate, maxBirthDate };
};

export const parseBirthDateFromPin = (pin: string): Date => {
  const day = parseInt(pin.slice(0, 2), 10);
  const month = parseInt(pin.slice(2, 4), 10);
  const birthYearLastTwoDigits = parseInt(pin.slice(4), 10);
  return new Date(birthYearLastTwoDigits + 1900, month - 1, day);
};

export const adjustBirthDateForLastHundredYears = (birthDate: Date): Date => {
  const currentYear = new Date().getFullYear();
  const birthYear = birthDate.getFullYear();
  return currentYear - birthYear > 95
    ? new Date(birthYear + 100, birthDate.getMonth(), birthDate.getDate())
    : birthDate;
};

export const isParticipantTooYoung = (minBirthDate: Date, birthDate: Date): boolean => {
  return isBefore(minBirthDate, birthDate);
};

export const isParticipantTooOld = (maxBirthDate: Date, birthDate: Date): boolean => {
  return isSameDay(maxBirthDate, birthDate) || isAfter(maxBirthDate, birthDate);
};
