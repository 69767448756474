<style lang="scss" scoped>
header {
  display: flex;
  justify-content: space-between;

  .icon {
    margin-top: -5px;
    margin-right: -5px;
  }
}

.card {
  overflow: visible;
}

p {
  margin-bottom: 1rem;
}

.title {
  font-size: 1.6rem;
}

.payerSection {
  margin: 2rem 0;
}
</style>

<template>
  <div class="card" v-if="shownCartItems.length > 0">
    <div class="card-content">
      <header>
        <h2 class="title">{{ $t('client.info') }}</h2>
        <b-icon
          v-if="clientCount > 1 && selectedItems.length === 0 && client.id !== '1'"
          icon="close"
          @click.native="removeClient(client.id)"
        />
      </header>

      <p>{{ $t('client.signupforcourses') }}</p>

      <CartItem
        v-for="(item, itemIndex) in shownCartItems"
        :key="item.id"
        :item="item"
        :item-index="itemIndex"
        :schema="schema"
        :client="client"
        :coursedetails="client.courses[`${item.itemid.id}-${item.itemid.lessonid}`]"
        :selected="selectedItems.includes(item.id)"
        :showCheckbox="clientCount > 1 || cartItems.length > 1"
        :ajvErrors="ajvErrors"
        @toggle-item="toggleCartItem"
        @change-details="updateCourseDetails"
        @change-prices="updateCoursePrices"
        @remove-cart-item="removeCartItem"
      />

      <RegistrationForm
        v-if="schema"
        :schema="schema"
        :client="client"
        :selectedCourseIds="selectedCourseIds"
        :isPayer="isPayer"
        :ajvErrors="ajvErrors"
        :validationTrigger="validationTrigger"
        :cartItemAgeLimits="cartItemAgeLimits"
        @update-client="updateClientFields"
        @validate="passValidationResults"
      />

      <p>{{ $t('client.requiredfields') }}</p>
    </div>
  </div>
</template>

<script lang="ts">
import { defineComponent, ref, watch, PropType, computed } from '@vue/composition-api';
import { isEqual } from 'lodash/fp';

import { HellewiCartItem } from '../../api';

import CartItem from './CartItem.vue';
import RegistrationForm, { CartItemAgeLimits } from './RegistrationForm.vue';
import { AjvError, Client, ClientCourseDetails } from '../../views/Cart.vue';

export interface CourseDetailsChangePayload {
  courseAndLessonId: string;
  details: ClientCourseDetails;
}

export default defineComponent({
  props: {
    schema: {
      type: Object,
      required: true
    },
    client: {
      type: Object as PropType<Client>,
      required: true
    },
    cartItems: {
      type: Array as PropType<HellewiCartItem[]>,
      required: true
    },
    clientCount: {
      type: Number,
      required: true
    },
    availableCartItems: {
      type: Array as PropType<HellewiCartItem[]>,
      required: true
    },
    ajvErrors: {
      type: Array as PropType<AjvError[]>,
      required: false
    },
    validationTrigger: {
      type: Boolean,
      required: false
    },
    isPayer: {
      type: Boolean,
      required: true
    }
  },
  components: {
    RegistrationForm,
    CartItem
  },
  setup: (props, ctx) => {
    const selectedItems = ref<number[]>(props.client.selectedCartItemIds);
    const selectedCartItems = computed<HellewiCartItem[]>(() =>
      props.cartItems.filter((ci) => selectedItems.value.includes(ci.id))
    );
    // show also unselected cart items, but only once, ie not the cart items
    // that are for courses / lessons that are already shown
    const shownCartItems = computed<HellewiCartItem[]>(() =>
      selectedCartItems.value
        .concat(props.availableCartItems)
        .filter(
          (ci, index, arr) =>
            arr.findIndex((existing) => isEqual(existing.itemid, ci.itemid)) === index
        )
        .sort(
          (a, b) =>
            a.course?.code?.localeCompare(b.course?.code as string) ||
            a.course?.name?.localeCompare(b.course?.name as string) ||
            (a.lesson?.begins?.getTime() || 0) - (b.lesson?.begins?.getTime() || 0) ||
            0
        )
    );

    // used for deciding which fields to include in RegistrationForm, so lesson ids
    // don't matter here
    const selectedCourseIds = computed<string[]>(() =>
      selectedCartItems.value.map((ci) => ci.itemid.id)
    );

    const hasPayer = computed<boolean>(() => props.schema.properties.payer !== undefined);

    // this is used for caching question answers also in this component so that
    // race condition doesn't make two emits that cancel each others
    const courses = ref<{ [courseId: string]: ClientCourseDetails }>(props.client.courses);

    const updateClientFields = (newClient: Client) => {
      ctx.emit('update-client', newClient);
    };

    const removeCartItem = (id: number) => {
      ctx.emit('remove-cart-item', id);
    };

    // Price updates are in a different event because they cause a new PostRegistrationPrice request
    const updateCoursePrices = ({ courseAndLessonId, details }: CourseDetailsChangePayload) => {
      courses.value[courseAndLessonId] = details;
      ctx.emit('update-prices', {
        ...props.client,
        courses: courses.value
      });
    };

    const updateCourseDetails = ({ courseAndLessonId, details }: CourseDetailsChangePayload) => {
      courses.value[courseAndLessonId] = details;
      ctx.emit('update-client', {
        ...props.client,
        courses: courses.value
      });
    };

    const toggleCartItem = (id: number) => {
      if (selectedItems.value.includes(id)) {
        selectedItems.value = selectedItems.value.filter((selected) => selected !== id);
      } else {
        selectedItems.value.push(id);
      }
    };

    const cartItemAgeLimits = computed(() =>
      shownCartItems.value
        .filter(({ course }) => course.ageLimits && course.begins && course.ends)
        .reduce(
          (acc, { course }) => {
            const { maxAge, minAge } = course.ageLimits || {};
            const startDate = course.begins?.toISOString().split('T')[0]; // Convert to JSON date string
            const endDate = course.ends?.toISOString().split('T')[0]; // Convert to JSON date string

            if (maxAge && (!acc.maxAge || maxAge > acc.maxAge)) {
              acc.maxAge = maxAge;
            }
            if (minAge && (!acc.minAge || minAge < acc.minAge)) {
              acc.minAge = minAge;
            }

            if (startDate && (!acc.startDate || startDate < acc.startDate)) {
              acc.startDate = startDate;
            }

            if (endDate && (!acc.endDate || endDate > acc.endDate)) {
              acc.endDate = endDate;
            }

            return acc;
          },
          {
            minAge: undefined,
            maxAge: undefined,
            startDate: '',
            endDate: ''
          } as CartItemAgeLimits
        )
    );

    watch(
      () => props.client.selectedCartItemIds,
      (ids) => {
        selectedItems.value = ids;
      }
    );

    const removeClient = (id: string) => {
      ctx.emit('remove-client', id);
    };

    const passValidationResults = (result: boolean) => {
      ctx.emit('validate', result);
    };

    watch(selectedItems, (selectedCartItemIds) => {
      ctx.emit('update-client', { ...props.client, courses: courses.value, selectedCartItemIds });
    });

    return {
      hasPayer,
      passValidationResults,
      removeCartItem,
      removeClient,
      selectedCourseIds,
      selectedItems,
      shownCartItems,
      toggleCartItem,
      updateClientFields,
      updateCourseDetails,
      updateCoursePrices,
      cartItemAgeLimits
    };
  }
});
</script>
