<!-- eslint-disable vue/multi-word-component-names -->
<!--
  These components are almost the same:
  - MyRegistrations
  - SingleRegistration
  - NewRegistration
  - Payment

  Remember to update all if they require updating.
-->

<template>
  <div>
    <div v-if="isSuccess">
      <div class="notification is-success is-light callout">
        {{ $t('payment.title.success') }}
      </div>

      <div v-if="registration" class="registration">
        <Registration
          :registration="registration"
          :titleTranslationKey="titleTranslationKey"
          :showRegistrationPrices="false"
          :showInvoices="true"
          :showPaymentColumn="false"
        />
      </div>
    </div>

    <div v-else-if="isOkError" class="card">
      <div class="card-content">
        <h1 class="title">{{ $t('payment.title.error') }}</h1>
        <p class="mt-4">{{ $t('payment.content.error') }}</p>
      </div>
    </div>

    <div v-else-if="isCancel" class="card">
      <div class="card-content">
        <h1 class="title">{{ $t('payment.title.cancel') }}</h1>
        <p class="mt-4">{{ $t('payment.content.cancel') }}</p>
      </div>
    </div>
  </div>
</template>

<script lang="ts">
import { computed, defineComponent, onBeforeMount, onMounted } from '@vue/composition-api';

import { useGetPaymentRequest } from '../hooks/usePaymentApi';
import useTitle from '../hooks/useTitle';
import router from '../router';
import { stateHasError, stateIsLoading } from '../utils/api-utils';
import { translate } from '../utils/misc-utils';

import Registration from '../components/Registration.vue';

export default defineComponent({
  components: { Registration },
  props: {
    status: {
      type: String,
      required: true
    },
    paymentmethod: {
      type: String,
      required: true
    }
  },
  setup: (props, ctx) => {
    const {
      response: registration,
      execute: getPaymentRequest,
      state: getPaymentRequestState
    } = useGetPaymentRequest();
    const { setTitle } = useTitle();

    const titleTranslationKey = 'registration.registrations';
    const hasError = stateHasError(getPaymentRequestState);
    const isLoading = stateIsLoading(getPaymentRequestState);

    const isSuccess = computed<boolean>(
      () => props.status === 'ok' && !hasError.value && !isLoading.value
    );
    const isOkError = computed<boolean>(() => props.status === 'ok' && hasError.value);
    const isCancel = props.status === 'cancel';

    onBeforeMount(async () => {
      // cancel would be good to have so that we could get all the registration
      // details also when user cancels the payment, but it's not implemented in
      // API yet
      if (props.status === 'ok') {
        getPaymentRequest({ ...props, query: router.currentRoute.query });
      }
    });

    onMounted(() => {
      setTitle(translate(ctx, titleTranslationKey));
    });

    return { isCancel, isOkError, isSuccess, registration, titleTranslationKey };
  }
});
</script>
