<style lang="scss" scoped>
.collapse-title-container {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  padding-left: 0;
  padding-right: 0;
  flex-wrap: wrap;
}

.collapse-title-details {
  display: flex;
  flex-direction: row;
  align-items: center;
  font-weight: 400;
  margin-top: 0.3rem;
  flex-wrap: wrap;
}

.collapse-title-icon {
  margin-right: 0.3rem;
}

.collapse-title-detail {
  margin-right: 1.5rem;
}

.collapse-card {
  box-shadow: none;
  border: none !important;
}

.card-header {
  box-shadow: none;
  cursor: default;
}

.collapse-card-header {
  box-shadow: none;
  cursor: pointer;
  :hover {
    color: $primary;
  }
}

.card-header-title {
  font-weight: $weight-normal;
}

.period-name {
  font-weight: $weight-semibold;
}

.weekdayLong {
  text-transform: capitalize;
}
</style>

<style lang="scss">
div.lessons-table div.table-wrapper table.table > thead {
  display: none !important;
}

div.lessons-table tbody tr > td {
  flex-direction: row-reverse;
}
</style>

<template>
  <b-table class="lessons-table" :data="lessons" :mobile-cards="true" v-if="lessons.length > 0">
    <b-table-column custom-key="day" v-slot="props">
      <span class="weekdayLong">
        {{ $d(new Date(props.row.begins), 'weekdayLong') }}
        <template v-if="!course.privatelessons">
          {{ new Date(props.row.begins) | dateRange(new Date(props.row.ends)) }}
        </template>
      </span>
    </b-table-column>
    <b-table-column custom-key="time" v-slot="props">
      {{ new Date(props.row.begins) | timeRange(new Date(props.row.ends)) }}
    </b-table-column>
    <b-table-column custom-key="availability" v-slot="props">
      <CourseAvailability
        class="availability-container"
        :participantcount="props.row.participantcount"
        :statuses="course.statuses"
      />
    </b-table-column>
    <b-table-column custom-key="buttons" v-slot="props">
      <b-button
        type="is-primary is-small"
        icon-left="cart"
        @click="addToCart(props.row.id)"
        :disabled="!props.row.participantcount.registrationopen || props.row.participantcount.full"
        :aria-label="course.privatelessons ? $t('cart.reservetime') : $t('cart.addtocart')"
      >
        {{ course.privatelessons ? $t('cart.reservetime') : $t('cart.addtocart') }}
      </b-button>
    </b-table-column>
  </b-table>
  <b-message
    v-else
    type="is-primary is-light"
    has-icon
    icon="information"
    icon-size="is-medium"
    :closable="false"
  >
    {{ $t('lessonsCollapse.lessonsNotAvailable') }}
  </b-message>
</template>

<script lang="ts">
import { computed, defineComponent, PropType, watch } from '@vue/composition-api';
import { filter } from 'lodash/fp';

import { useAddToCart } from '../../hooks/useCartApi';
import { useGetCourse } from '../../hooks/useCourseApi';

import { HellewiCourse, HellewiCourseLesson } from '../../api';
import { RequestState } from '../../utils/api-utils';

import router from '../../router';

import CourseAvailability from '../CourseAvailability.vue';

export default defineComponent({
  components: { CourseAvailability },
  props: {
    course: {
      type: Object as PropType<HellewiCourse>,
      required: true
    },
    unlistedid: {
      type: String,
      required: false
    }
  },
  setup: (props) => {
    const { execute: addToCartRequest, state: addToCartState } = useAddToCart();
    const { execute: getCourseRequest } = useGetCourse();

    const addToCart = (lessonid: number) => {
      const unlisted = router.currentRoute.query.unlistedid
        ? {
            expiry: String(router.currentRoute.query.expiry),
            reqid: String(router.currentRoute.query.reqid),
            unlistedid: String(router.currentRoute.query.unlistedid),
            hmac: String(router.currentRoute.query.hmac)
          }
        : {};
      addToCartRequest([
        {
          id: props.course.id,
          lessonid,
          ...unlisted
        }
      ]);
    };

    const lessons = computed<HellewiCourseLesson[]>(() =>
      filter((l) => Boolean(l.participantcount?.registrationopen), props.course.lessons)
    );

    // fetch course information after add to cart request so that new information
    // is updated
    watch(addToCartState, (newState, oldState) => {
      const unlisted = router.currentRoute.query.unlistedid
        ? {
            expiry: new Date(String(router.currentRoute.query.expiry)),
            reqid: String(router.currentRoute.query.reqid),
            unlistedid: String(router.currentRoute.query.unlistedid),
            hmac: String(router.currentRoute.query.hmac)
          }
        : {};
      if (oldState === RequestState.Loading) {
        getCourseRequest({
          id: props.course.id,
          ...unlisted
        });
      }
    });

    return { addToCart, lessons };
  }
});
</script>
