<style lang="scss" scoped>
.filtergroup {
  margin-bottom: 1rem;

  header {
    display: flex;
    justify-content: space-between;
    align-items: center;
    width: 100%;
    margin-bottom: 1rem;
    cursor: pointer;

    .icon {
      margin-left: 0.2rem;
    }

    & > div {
      width: 85%;
    }
  }

  footer {
    margin: 1rem 0 2rem 0;
  }
}

.filter {
  display: flex;
  flex-direction: column;
  width: 100%;
  margin-bottom: 0.8rem;
}

.search-filters .input-container {
  text-align: left;
  width: 85%;
  padding-left: 0.5rem;
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: flex-start;
}

.filter-amount {
  width: 15%;
  color: $lightGray;
  text-align: center;
  box-sizing: border-box;
}

.filtergroup-label {
  font-weight: 700;
  text-transform: capitalize;
  display: block;
}

.filter-container {
  display: flex;
  justify-content: space-between;
  flex-direction: row;
}

.search-filters {
  min-height: 50rem;
}

.subfilters {
  margin: 0.8rem 0 0 1.2rem;
}

.filtergroup-button,
.subfilter-button {
  background-color: initial;
  color: $lightGray;
  box-sizing: border-box;
  border: none;
  border-radius: 2px;
  display: flex;
}
.subfilter-button {
  height: 20px;
  width: 30px;
}
</style>

<style global>
¯ .filter-container .control-label {
  overflow-wrap: break-word;
  word-wrap: break-word;
  max-width: 11rem;
}
.subfilter-button > span {
  line-height: 1em;
}
.subfilter-toggle {
  height: 1rem;
}
.checkbox-with-subfilters > .control-label {
  overflow-wrap: break-word;
  max-width: 136px;
}
</style>

<template>
  <div id="search-filters" class="search-filters">
    <div class="filtergroup" v-for="filterGroup in filterGroups" :key="filterGroup.name">
      <header
        @click="toggleGroupOpen(filterGroup.name)"
        role="button"
        :aria-controls="filterGroup.name"
      >
        <div>
          <h4 class="filtergroup-label">
            {{ $t(filterGroup.name) }}
          </h4>
        </div>
        <aside class="filter-amount">
          <b-button
            class="filtergroup-button"
            :aria-label="getFilterGroupToggleButtonLabel(filterGroup.name)"
          >
            <b-icon
              :icon="isGroupOpen(filterGroup.name) ? 'chevron-up' : 'chevron-down'"
              size="is-medium"
            />
          </b-button>
        </aside>
      </header>
      <b-collapse
        aria-role="list"
        animation="slide"
        :aria-id="filterGroup.name"
        :open="isGroupOpen(filterGroup.name)"
      >
        <div class="filters">
          <div
            v-for="filter in filterGroup.filters"
            v-show="!filter.disabled"
            aria-role="listitem"
            :key="filter.inputId"
            :class="[
              'filter',
              filterGroup.type,
              {
                'has-children': filter.subFilters.length > 0
              }
            ]"
          >
            <div
              aria-role="listitem"
              key="coursesbeginningdate"
              :class="['filter', 'date']"
              v-if="filter.type === HellewiCatalogItemType.Dateinput"
            >
              <div class="filter-container">
                <div class="input-container">
                  <b-field :label="$t(`${filter.name}`)">
                    <b-datepicker
                      :placeholder="$t(`${filter.name}`)"
                      icon="calendar-today"
                      icon-right-clickable
                      trap-focus
                      :icon-right="'close-circle'"
                      @icon-right-click="clearDate(filter)"
                      @input="dateChanged(filter, $event)"
                      :value="getDatefilterValue(filter.name)"
                      :first-day-of-week="1"
                      :years-range="[-1, 1]"
                    >
                    </b-datepicker>
                  </b-field>
                </div>
              </div>
            </div>
            <div
              class="filter-container"
              @change="filterClick(filter)"
              v-if="filter.type !== HellewiCatalogItemType.Dateinput"
            >
              <div class="input-container">
                <b-checkbox
                  :id="filter.inputId"
                  :type="filterGroup.type"
                  :value="getFilterCheckboxValue(filter)"
                  :native-value="filter.keyword"
                  :disabled="filter.disabled"
                  :indeterminate="isIndeterminateFilter(filter)"
                  :class="[{ 'checkbox-with-subfilters': filter.subFilters.length > 0 }]"
                >
                  {{ filter.translatelabel ? $t(filter.name) : filter.name }}
                </b-checkbox>
                <b-button
                  class="subfilter-button"
                  v-if="filter.subFilters.length > 0"
                  :aria-label="getFilterGroupToggleButtonLabel(filterGroup.name)"
                  @click.stop="
                    toggleSubfilterGroup(
                      filter.keyword,
                      filter.subFilters,
                      !openSubFilters.find((key) => key === filter.keyword)
                    )
                  "
                >
                  <b-icon
                    custom-class="subfilter-toggle"
                    :icon="
                      openSubFilters.find((key) => key === filter.keyword)
                        ? 'chevron-up'
                        : 'chevron-down'
                    "
                  />
                </b-button>
              </div>
              <aside class="filter-amount" v-if="filter.type !== HellewiCatalogItemType.Date">
                {{ filter.courseCount }}
              </aside>
            </div>
            <div v-if="openSubFilters.find((key) => key === filter.keyword)" class="subfilters">
              <div
                v-for="subFilter in filter.subFilters"
                v-show="!subFilter.disabled"
                :key="subFilter.keyword"
                :class="['filter', filterGroup.type]"
                class="filter-container"
              >
                <div class="input-container" @change="filterClick(subFilter)">
                  <b-checkbox
                    :id="subFilter.inputId"
                    :type="filterGroup.type"
                    :value="getSubFilterCheckboxValue(filter, subFilter)"
                    :native-value="subFilter.keyword"
                    :disabled="subFilter.disabled"
                  >
                    {{ subFilter.name }}
                  </b-checkbox>
                </div>

                <aside class="filter-amount">{{ subFilter.courseCount }}</aside>
              </div>
            </div>
          </div>
        </div>
      </b-collapse>
    </div>
    <footer>
      <a class="reset-filter" @click.prevent="resetFilterGroups()" href="#">
        {{ $t('deselectFilters') }}
      </a>
    </footer>
  </div>
</template>

<script lang="ts">
import { defineComponent } from '@vue/composition-api';
import { useCatalogFilters, Filter } from '../../hooks/useCatalogFilters';
import { HellewiCatalogItemType } from '../../api';
import { translate } from '../../utils/misc-utils';

export default defineComponent({
  setup: (props, ctx) => {
    const {
      isGroupOpen,
      toggleGroupOpen,
      resetCatalogFilterGroups,
      toggleSubfilterGroup,
      filterGroups,
      selected,
      selectedParents,
      openSubFilters
    } = useCatalogFilters(ctx);

    const filterClick = (filter: Filter) => {
      if (filter.disabled) {
        return;
      }

      if (
        filter.subFilters.length > 0 &&
        !selectedParents.value.includes(filter.keyword) &&
        !openSubFilters.value.includes(filter.keyword)
      ) {
        // Clicked closed subfiltergroup. Toggle the group open.
        toggleSubfilterGroup(filter.keyword, filter.subFilters, true);
      }

      setTimeout(() => {
        if (filter.subFilters.length > 0) {
          // This is a parent filter
          const subFilterKeywords = filter.subFilters.map((sf) => sf.keyword);

          if (
            selectedParents.value.includes(filter.keyword) ||
            subFilterKeywords.some((subFilter) => selected.value.includes(subFilter))
          ) {
            // This is an active parent filter or an active subfilter exists.
            // Disable this parent and all subfilters. Toggle the group closed.
            selectedParents.value = selectedParents.value.filter((kw) => kw !== filter.keyword);
            selected.value = selected.value.filter((kw) => !subFilterKeywords.includes(kw));
            toggleSubfilterGroup(filter.keyword, filter.subFilters, false);
          } else {
            // Add parent filter and all subfilters.
            selectedParents.value = [...selectedParents.value, filter.keyword];
            selected.value = [...selected.value, ...subFilterKeywords];
          }
        } else {
          if (selected.value.includes(filter.keyword)) {
            // This filter is active
            if (!filter.parent || !filter.parent.includes('category')) {
              // Remove this filter
              // If parent keyword includes 'category' toggling the subfilter needs special logic implemented below
              selected.value = selected.value.filter((kw) => filter.keyword !== kw);
            } else if (
              selectedParents.value.includes(filter.parent) &&
              filter.siblings.every((sibling) => selected.value.includes(sibling))
            ) {
              // Subfilter
              // This, parent and sibling filters are active. Remove siblings and parent but keep this filter active.
              selected.value = selected.value.filter((kw) => !filter.siblings.includes(kw));
              selectedParents.value = selectedParents.value.filter((kw) => kw !== filter.parent);
            } else if (!filter.siblings.find((sibling) => selected.value.includes(sibling))) {
              // Subfilter
              // This is last active sibling filter. Activate all siblings and parent filter.
              selected.value = selected.value.concat(filter.siblings);
              selectedParents.value = [...selectedParents.value, filter.parent];
            } else {
              // Remove this filter
              selected.value = selected.value.filter((kw) => filter.keyword !== kw);
            }
          } else {
            // if filter keyword includes 'begins' remove all other begins filters as there can by only one
            if (filter.keyword.includes('begins:')) {
              selected.value = selected.value.filter((kw) => kw.indexOf('begins') === -1);
            }
            // Activate this filter
            selected.value = [...selected.value, filter.keyword];
          }
        }

        ctx.emit('filters-changed', selected.value);
      }, 1);
    };

    const resetFilterGroups = () => {
      resetCatalogFilterGroups();
      ctx.emit('filters-changed', selected.value);
    };

    const getSubFilterCheckboxValue = (filter: Filter, subFilter: Filter) =>
      subFilter.parent &&
      selectedParents.value.includes(subFilter.parent) &&
      filter.subFilters.every(({ keyword }) => selected.value.includes(keyword))
        ? false
        : selected.value.includes(subFilter.keyword);

    const getFilterCheckboxValue = (filter: Filter) =>
      filter.subFilters.length > 0
        ? selectedParents.value.includes(filter.keyword) ||
          filter.subFilters.some(({ keyword }) => selected.value.includes(keyword))
        : selected.value.includes(filter.keyword);

    const isIndeterminateFilter = (filter: Filter) =>
      filter.subFilters.length > 0 &&
      filter.subFilters.some(({ keyword }) => selected.value.includes(keyword)) &&
      !filter.subFilters.every(({ keyword }) => selected.value.includes(keyword)) &&
      !selectedParents.value.includes(filter.keyword);

    const dateChanged = (filter: Filter, date: Date) => {
      date.setHours(12);
      const coursesbeginsFilter = {
        ...filter,
        keyword: `${filter.name}:>=${date.toISOString().split('T')[0]}`
      };
      filterClick(coursesbeginsFilter);
    };
    const clearDate = (filter: Filter) => {
      selected.value = selected.value.filter((kw) => kw.indexOf(filter.name) === -1);
      ctx.emit('filters-changed', selected.value);
    };
    const getDatefilterValue = (keyword: string) => {
      const dateFilter = selected.value.find((kw) => kw.indexOf(keyword) !== -1);
      if (dateFilter && dateFilter.indexOf('currentdate') === -1) {
        return new Date(dateFilter.split('>=')[1]);
      }
      return undefined;
    };
    const getFilterGroupToggleButtonLabel = (filterGroupName: string) =>
      isGroupOpen(filterGroupName)
        ? translate(ctx, 'filterGroupClose')
        : translate(ctx, 'filterGroupOpen');

    return {
      clearDate,
      dateChanged,
      filterClick,
      HellewiCatalogItemType,
      resetFilterGroups,
      filterGroups,
      isGroupOpen,
      toggleGroupOpen,
      openSubFilters,
      getDatefilterValue,
      getFilterCheckboxValue,
      getSubFilterCheckboxValue,
      isIndeterminateFilter,
      toggleSubfilterGroup,
      getFilterGroupToggleButtonLabel
    };
  }
});
</script>
