<style lang="scss" scoped>
.filters {
  display: flex;
  flex-direction: column;
}

.dropdowns .filtergroup-button {
  font-weight: 700;
  border-width: 2px;

  @media (min-width: 400px) {
    margin-right: 1rem;
  }
}

.filter-arrow {
  color: $lightGray;
  margin-left: 0.5rem;
}

.search-button {
  display: none;
}
.filters .modal-card-foot {
  justify-content: flex-end;
}

.filter-modal {
  padding-top: 6rem;
}

.dropdowns {
  display: flex;
  flex-direction: row;
  align-items: center;
}

@media (max-width: 800px) {
  .search-button {
    display: block;
    margin-left: 1rem;
  }
}
@media (max-width: 650px) {
  .dropdowns {
    flex-direction: column;
    align-items: flex-start;
  }
  .filters-container {
    width: 100%;
  }
  .filtergroup-button {
    width: 100%;
  }
}
</style>

<template>
  <div class="filters">
    <div class="dropdowns">
      <div class="filters-container">
        <b-button
          type="is-primary"
          outlined
          role="button"
          class="filtergroup-button"
          @click="filterModalOpen = true"
        >
          <span v-if="filterCount && filterCount > 0">
            {{ $t('filtersActive') }}
          </span>
          <span v-else>
            {{ $t('filter') }}
          </span>
          <span v-if="filterCount && filterCount > 0">({{ filterCount }})</span>
        </b-button>
        <b-modal
          v-model="filterModalOpen"
          aria-role="dialog"
          has-modal-card
          full-screen
          :destroy-on-hide="false"
          :can-cancel="false"
          aria-modal
          aria-label="Filters"
          class="filter-modal"
        >
          <template #default="props">
            <div class="modal-card" style="width: auto">
              <section class="modal-card-body">
                <slot name="filters" />
              </section>
              <footer class="modal-card-foot">
                <b-button
                  type="is-primary"
                  :label="$t('seeResults')"
                  :aria-label="$t('seeResults')"
                  @click="props.close"
                />
              </footer>
            </div>
          </template>
        </b-modal>
      </div>
      <slot name="sorting" />
    </div>
    <slot name="tags" />
  </div>
</template>

<script lang="ts">
import { defineComponent, ref, watch } from '@vue/composition-api';
import { useCatalogFilters } from '../../hooks/useCatalogFilters';
import useSearchParams from '../../hooks/useSearchParams';

export default defineComponent({
  props: {
    currentKeyword: {
      type: String
    }
  },

  setup: (props, ctx) => {
    const { getFilterTags, selected, selectedParents, filterGroups } = useCatalogFilters(ctx);
    const { keyword: searchKeyword } = useSearchParams(ctx);
    const filterModalOpen = ref<boolean>(false);
    const inputValue = ref<string | undefined>();
    const filterCount = ref<number>();

    const updateValue = (value: string) => {
      inputValue.value = value;
    };

    const search = () => {
      ctx.emit('search', inputValue.value);
    };

    const updateFilterCount = () => {
      filterCount.value = getFilterTags().length + (searchKeyword.value ? 1 : 0);
    };

    watch([selected, selectedParents, filterGroups], updateFilterCount);

    return {
      updateValue,
      search,
      filterModalOpen,
      filterCount
    };
  }
});
</script>
