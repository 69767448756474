<!--
  These components are almost the same:
  - MyRegistrations
  - SingleRegistration
  - NewRegistration
  - Payment

  Remember to update all if they require updating.
-->

<template>
  <div v-if="registration" class="registration">
    <Registration
      :registration="registration"
      :titleTranslationKey="titleTranslationKey"
      :showRegistrationPrices="true"
      :showInvoices="false"
      :showPaymentColumn="false"
    />
  </div>
</template>

<script lang="ts">
// eslint-disable-next-line @typescript-eslint/no-explicit-any
declare const fbq: any;

import { defineComponent, onMounted } from '@vue/composition-api';

import { usePostRegistration } from '../hooks/useRegistrationApi';
import useTitle from '../hooks/useTitle';
import { translate } from '../utils/misc-utils';

import { useGtm } from '@gtm-support/vue2-gtm';
import { getOr } from 'lodash/fp';

import Registration from '../components/Registration.vue';

export default defineComponent({
  components: { Registration },
  setup(props, ctx) {
    const { response: registration } = usePostRegistration();
    const { setTitle } = useTitle();
    const gtm = useGtm();

    const titleTranslationKey = getOr(false, 'value.paymentrequired', registration)
      ? 'registration.confirmationTitlePayerRequired'
      : 'registration.confirmationTitle';
    const purchaseValue = (registration.value?.invoicestotal?.amount || 0) / 100;

    const googleTagManagerConversion = () => {
      if (gtm?.enabled()) {
        gtm.trackEvent({
          event: 'Conversion',
          value: purchaseValue
        });
      }
    };

    const metaPixelConversion = () => {
      /* eslint-disable */

      if (typeof fbq !== 'undefined' && fbq) {
        fbq('track', 'Purchase', {
          currency: 'EUR',
          value: purchaseValue,
          content_ids: [registration.value?.products.map((product) => product.course?.code)]
        });
      }
      /* eslint-enable */
    };

    if (registration.value?.invoices?.length) {
      googleTagManagerConversion();
      metaPixelConversion();
    }

    onMounted(() => {
      setTitle(translate(ctx, titleTranslationKey));
    });

    return {
      registration,
      titleTranslationKey
    };
  }
});
</script>
