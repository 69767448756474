<style scoped lang="scss">
$title-color: $white;

#app .card-content {
  background: $primary;
  background: var(--brandcolor);
}

.card {
  border-radius: 13px;
  box-shadow: none;
}

.carousel {
  background: transparent;
  box-shadow: none !important;

  .card-content {
    padding: 1.5rem;
  }
}

.title {
  margin-bottom: 0 !important;
  margin-right: 0.5rem;
  color: $title-color;
  font-weight: $weight-bold;
}

.arrow {
  color: $title-color;
}

.content {
  display: flex;
  justify-content: space-between;
  align-items: center;
  height: 1rem;
}

.image {
  object-fit: cover;
}
</style>

<style lang="scss">
// !important css rule is used to override buefy generated inline styles

$carousel-side-peek: 4rem;
$carousel-side-peek-desktop: 5rem;

#app .promotion-carousel {
  margin-right: 0;
  overflow: hidden;

  .carousel {
    margin-right: $carousel-side-peek;
    overflow: visible;
    width: auto;

    @media (min-width: $desktop) {
      margin-right: $carousel-side-peek-desktop;
    }
  }

  @media (min-width: calc(#{$widescreen} + 1px)) {
    .carousel:has(.carousel-slide:nth-child(-n + 3):last-child),
    .carousel:not(:has(.carousel-slide:nth-child(3))) {
      // Three or less promotions in desktop
      margin-right: -1rem;
      .carousel-slide,
      .carousel-slide:last-child {
        width: calc(33.3% - 1rem) !important;
      }
    }
  }
  @media (min-width: calc(#{$tablet} + 1px)) and (max-width: $widescreen) {
    .carousel:has(.carousel-slide:nth-child(2):last-child) {
      // Two promotions in tablet
      margin-right: -1rem;
      .carousel-slide,
      .carousel-slide:last-child {
        width: calc(50% - 1rem) !important;
      }
    }
  }
  @media (min-width: calc(#{$tablet} + 1px)) and (max-width: $desktop) {
    margin-right: -$main-container-sidepadding-tablet; // 2rem
    .carousel:has(.carousel-slide:nth-child(2):last-child) {
      margin-right: 1rem;
    }
  }

  @media (max-width: $tablet) {
    margin-right: -$main-container-sidepadding-mobile;
  }
  @media (max-width: $mobile-small) {
    margin-right: -$main-container-sidepadding-mobile-small;
  }
}

@mixin carouselWidth($base-width, $global-container-side-padding) {
  width: calc(#{$base-width} - 1rem) !important;
  &:last-child {
    width: calc(
      #{$base-width} + #{$carousel-side-peek} - #{$global-container-side-padding}
    ) !important;
  }
}

#app .carousel-slide {
  margin-right: 1rem;

  .b-image-wrapper {
    padding-top: 200px !important;
  }

  @include carouselWidth(33.3%, 0px);

  @media (min-width: $widescreen) {
    &:last-child {
      width: calc(33.3% - 1rem) !important;
    }
  }

  @media (max-width: $widescreen) {
    @include carouselWidth(50%, -1rem);
    .b-image-wrapper {
      padding-top: 20vw !important;
    }
  }

  @media (max-width: $desktop) {
    @include carouselWidth(50%, $main-container-sidepadding-tablet);
    .b-image-wrapper {
      padding-top: 25vw !important;
    }
  }

  @media (max-width: $tablet) {
    @include carouselWidth(100%, $main-container-sidepadding-mobile);

    .b-image-wrapper {
      padding-top: 40vw !important;
    }
  }
  @media (max-width: $mobile-small) {
    @include carouselWidth(100%, $main-container-sidepadding-mobile-small);
  }
}

.carousel .carousel-arrow {
  opacity: 1;

  & > .icon {
    box-shadow: 2px 2px 10px rgba(0, 0, 0, 0.8);
  }

  & > .icon.has-icons-left {
    left: 1rem;
  }
  & > .icon.has-icons-right {
    right: -3.5rem;

    @media (min-width: $desktop) {
      right: calc(#{-$carousel-side-peek-desktop} + 1rem);
    }
  }
}
</style>

<template>
  <section class="wrapper" v-if="promotions && promotions.length > 0">
    <b-carousel-list
      class="carousel"
      :data="promotions"
      :items-to-show="cardsShown"
      icon-size="is-medium"
    >
      <template #item="promotion">
        <a :href="promotion.url">
          <div class="card">
            <div class="card-image">
              <figure class="image">
                <b-image ratio="2by1" :src="promotion.image" :alt="promotion.text" />
              </figure>
            </div>
            <div class="card-content">
              <div class="content">
                <v-clamp class="title is-6" tag="p" autoresize :max-lines="2">
                  {{ promotion.text }}
                </v-clamp>
                <b-icon icon="chevron-right" class="arrow" />
              </div>
            </div>
          </div>
        </a>
      </template>
    </b-carousel-list>
  </section>
</template>

<script lang="ts">
import { computed, onMounted, onUnmounted, ref, defineComponent } from '@vue/composition-api';
import VClamp from 'vue-clamp';
import { useGetPromotions } from '../../hooks/useBrandApi';

export default defineComponent({
  components: {
    VClamp
  },
  setup: () => {
    const { response: promotions, execute: getPromotions } = useGetPromotions();
    const windowWidth = ref(window.innerWidth);
    const onWidthChange = () => (windowWidth.value = window.innerWidth);

    onMounted(() => {
      getPromotions();
      window.addEventListener('resize', onWidthChange);
    });

    onUnmounted(() => window.removeEventListener('resize', onWidthChange));

    const cardsShown = computed(() => {
      if (windowWidth.value < 769) {
        return 1;
      }
      if (windowWidth.value < 1216) {
        return 2;
      } else {
        return 3;
      }
    });

    return {
      cardsShown,
      promotions
    };
  }
});
</script>
