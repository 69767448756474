<!-- eslint-disable vue/multi-word-component-names -->
<style lang="scss" scoped>
section {
  padding-top: 1rem;
}

.skeleton-wrapper {
  padding: 2rem;
  display: flex;
  justify-content: center;
}

h1 > small {
  color: $lightGray;
  font-size: 1.2rem;
  font-weight: normal;
  display: block;
}

.title {
  color: var(--brandcomplementarycolor);

  &:not(:first-child) {
    margin-top: 0.8em;
  }
}

b {
  font-weight: 600;
}

.registration-box-skeleton {
  width: 22rem;
  display: flex;
  flex-direction: column;
  justify-items: center;
  padding: 3rem;
  overflow-wrap: normal;
  margin-left: 3rem;
  border-radius: 13px;
  box-shadow: none !important;
  border: 1px solid $lightGrayOutline;
}

.skeleton-content-wrapper {
  width: 65%;
}

.dot {
  border-radius: 50%;
  height: 5px;
  width: 5px;
  background: black;
  margin: 0.5rem;
}

.main-content {
  margin-right: 3%;
  margin-bottom: 0;
  width: 70%;
  border-radius: 13px;
  border: 1px solid $lightGrayOutline;
  background: white;
  padding: 3rem;
}

.location {
  display: flex;
  flex-wrap: wrap;
  margin-top: 2rem;
  margin-bottom: 1rem;
}

.course-map {
  width: 100%;
  height: 30rem;
}

.registration-box-desktop {
  position: sticky;
  top: 75px;
  z-index: 19;
  width: 22rem;
  align-self: flex-start;
  display: flex;
  flex-direction: column;
  justify-items: center;
  padding: 3rem 2rem;
  overflow-wrap: normal;
  border-radius: 13px;
  box-shadow: none !important;
  border: 1px solid $lightGrayOutline;
  margin-bottom: 0 !important;
}

.registration-box-mobile {
  display: none;
}

.wrapper {
  max-width: 100%;
  display: flex;
  flex-direction: row;
}

.course-teacher {
  margin-bottom: 1rem;
}

.description {
  word-wrap: break-word;
  margin-bottom: 1rem;
}

.description >>> ul {
  margin: 1rem 0 1rem 2rem;
  list-style-type: '- ';
}

.additionalinfo {
  word-wrap: break-word;
  margin-bottom: 1rem;
}

.askabout {
  word-wrap: break-word;
  margin-bottom: 1rem;
}

.learningobjectives {
  word-wrap: break-word;
  margin-bottom: 1rem;
}

.evaluationcriteria {
  word-wrap: break-word;
}

.collapse-title-container {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  padding-left: 0;
  padding-right: 0;
}

.collapse-title-details {
  display: flex;
  flex-direction: row;
  align-items: center;
  font-weight: 400;
  margin-top: 0.3rem;
  flex-wrap: wrap;
}

.collapse-title-icon {
  margin-right: 0.3rem;
}

.collapse-title-detail {
  display: flex;
  flex-direction: row;
  align-items: center;
  margin-right: 1.5rem;
}

.collapse-card {
  box-shadow: none;
}

.collapse-card-header {
  box-shadow: none;
  :hover {
    color: $primary;
    color: var(--brandcolor);
  }
}

.location-headline {
  margin-right: 0.25rem;
}

article.message {
  margin-bottom: 0.5rem;
  &:last-child {
    margin-bottom: 1.5rem !important;
  }
}

ul.modulechilden {
  margin-top: 0;
  margin-left: 1rem;
  list-style-type: '- ';
}

.courseinfobox {
  margin-bottom: 1rem;
}

@media (min-width: 1026px) {
  .main-content {
    width: 70%;
  }

  .registration-box-desktop {
    top: 75px;
  }
}

@media (max-width: 1025px) {
  .main-content {
    padding: 2rem;
  }

  .skeleton-wrapper {
    flex-direction: column;
    align-items: center;
  }

  .registration-box-skeleton {
    margin-left: 0;
    margin-top: 3rem;
    width: 65%;
  }

  .registration-box-mobile {
    display: relative;
    padding: 2rem;
    display: flex;
    flex-direction: column;
    justify-items: center;
    border-radius: 13px;
    box-shadow: none !important;
    border: 1px solid $lightGrayOutline;
  }

  .side-icon {
    display: flex;
    align-self: center;
  }

  .registration-box-mobile > span {
    padding-bottom: 0.2rem;
  }

  .registration-box-mobile > button {
    margin-top: 2rem;
  }

  .registration-box-desktop {
    display: none;
  }

  .wrapper {
    padding-top: 0;
    display: flex;
    flex-direction: column;
    justify-content: center;
    width: 100%;
  }
  .main-content {
    width: 100%;
  }

  aside {
    display: none;
  }
}

hr {
  margin: 1rem 0;
}

@media (max-width: 700px) {
  .title {
    font-size: 1.8rem;
  }
  .skeleton-wrapper {
    width: 100%;
  }
  .registration-box-skeleton {
    width: 90%;
  }
  .skeleton-content-wrapper {
    width: 90%;
  }
}

@media (max-width: 600px) {
  .main-content {
    padding: 1rem;
  }
  .registration-box-mobile {
    padding: 1rem;
  }
}

@include fullhd {
  .registration-box-desktop {
    padding: 3rem;
  }
}

.additional-images {
  margin: 1rem 0;
}
.age-limits {
  & > p:first-child {
    margin-bottom: 0;
  }
  & > p:last-child {
    margin-bottom: 1rem;
    &::first-letter {
      text-transform: uppercase;
    }
  }
}
</style>

<style lang="scss">
div.lessons-table div.table-wrapper table.table > thead {
  display: none !important;
}
div.lessons-table tbody tr > td {
  flex-direction: row-reverse;
}
</style>

<template>
  <div>
    <div v-if="isLoading">
      <section class="skeleton-wrapper">
        <div class="skeleton-content-wrapper">
          <b-skeleton width="100%" height="3rem" :animated="true"> </b-skeleton>
          <b-skeleton width="100%" height="20rem" :animated="true"></b-skeleton>
        </div>
        <div class="registration-box-skeleton card">
          <b-skeleton width="100%" height="3rem" :animated="true"></b-skeleton>
          <b-skeleton width="100%" height="10rem" :animated="true"></b-skeleton>
        </div>
      </section>
    </div>
    <div v-if="!isLoading && course" class="wrapper">
      <section class="main-content content">
        <BreadCrumbs v-if="course" :course="course" />
        <h1 class="title course-title">
          {{ course.name }}
          <small v-if="course.code">{{ course.code }}</small>
        </h1>
        <SocialShare />
        <CourseImage
          v-if="course.images && course.images.length > 0"
          :image="course.images[0]"
          main-image
          :fallback-alt-text="course.name"
        />
        <div>
          <b-message
            v-for="(notification, i) in course.notifications"
            :key="i"
            type="is-primary is-light"
            has-icon
            icon="information"
            icon-size="is-medium"
            :closable="false"
          >
            {{ notification.text }}
          </b-message>
        </div>

        <div class="description" v-dompurify-html="course.description" />

        <div v-if="course.additionalinfo">
          <p>
            <strong>{{ $t('course.additionalinfo') }}</strong>
          </p>
        </div>
        <div class="additionalinfo" v-dompurify-html="course.additionalinfo" />

        <div v-if="course.modulechildren && course.modulechildren.length > 0" class="courseinfobox">
          <strong>{{ $t('course.includesSubcourses') }}</strong>
          <ul class="modulechilden">
            <li v-for="child in course.modulechildren" :key="child.id">
              <router-link :to="{ name: 'course', params: { id: child.id }, force: true }">{{
                child.name
              }}</router-link>
            </li>
          </ul>
        </div>
        <div v-if="course.moduleparent" class="courseinfobox">
          <strong>{{ $t('course.belongsToModule') }} </strong>
          <router-link
            :to="{ name: 'course', params: { id: course.moduleparent.id }, force: true }"
            >{{ course.moduleparent.name }}</router-link
          >
        </div>

        <div class="age-limits" v-if="ageLimitString">
          <p>
            <strong>{{ $t('agelimit.agelimit') }}</strong>
          </p>
          <p>
            {{ ageLimitString }}
          </p>
        </div>
        <div v-if="course.learningobjectives">
          <p>
            <strong>{{ $t('course.learningobjectives') }}</strong>
          </p>
        </div>
        <div class="learningobjectives" v-dompurify-html="course.learningobjectives" />

        <div v-if="course.evaluationcriteria">
          <p>
            <strong>{{ $t('course.evaluationcriteria') }}</strong>
          </p>
        </div>
        <div class="evaluationcriteria" v-dompurify-html="course.evaluationcriteria" />

        <div v-if="course.askabout">
          <a :href="'mailto:' + course.askabout" class="button is-primary is-small">
            {{ $t('course.askabout') }}
          </a>
        </div>

        <div v-if="course.files && course.files.length > 0">
          <p>
            <strong>{{ $t('course.files') }}</strong>
          </p>
        </div>
        <div class="coursefiles">
          <CourseFile v-for="file in course.files" :file="file" :key="file.url" />
        </div>

        <div class="additional-images" v-if="additionalImages && additionalImages.length > 0">
          <CourseImage
            v-for="image in additionalImages"
            :image="image"
            :key="image.url"
            :fallback-alt-text="course.name"
          />
        </div>

        <hr />

        <LessonsRegistration
          v-if="registrationToLessons"
          :course="course"
          :unlistedid="unlistedid"
        />

        <div v-else>
          <div
            :key="period.name + period.begins"
            v-for="period in sortBy(['begins', 'ends'], course.periods)"
          >
            <LessonsCollapse
              v-if="period.keywords.some((keyword) => keyword.includes('period'))"
              :period="period"
            />
          </div>

          <div v-if="course.periods.length === 0 && course.lessons.length > 0">
            <LessonsCollapse :course="course" />
          </div>
        </div>
        <hr v-if="course.periods.length !== 0 || course.lessons.length !== 0" />

        <RegistrationBox
          :course="course"
          class="registration-box-mobile card"
          @cart-add="addToCart"
        />

        <CourseInfoDl
          :course="course"
          :spacing="true"
          :fields="[
            CourseInfoDlField.Teacher,
            CourseInfoDlField.Location,
            CourseInfoDlField.Ectscredits,
            CourseInfoDlField.Teachingformat
          ]"
          :locationSingleLine="true"
          :locationMapLink="false"
          :horizontal="true"
        />

        <CourseMap
          v-if="course.location && course.location.latlon"
          class="course-map"
          :coordinates="course.location.latlon"
          :text="course.location.name"
        />
      </section>

      <RegistrationBox
        :course="course"
        class="registration-box-desktop card"
        @cart-add="addToCart"
      />
      <script v-html="jsonld" type="application/ld+json"></script>
    </div>
  </div>
</template>

<style lang="scss" scoped></style>

<script lang="ts">
import { computed, defineComponent, onBeforeMount, watch } from '@vue/composition-api';
import { includes, sortBy, uniq } from 'lodash/fp';

import { HellewiCourseStatus } from '../api';
import useTitle from '../hooks/useTitle';
import { useGetCourse } from '../hooks/useCourseApi';
import { useAddToCart } from '../hooks/useCartApi';
import router from '../router';
import { stateHasError, stateIsLoading, stateIsSuccess, useToast } from '../utils/api-utils';
import { translate } from '../utils/misc-utils';
import { getAgeLimitTranslation } from '../utils/agelimit-translation';

import CourseInfoDl, { CourseInfoDlField } from '../components/CourseInfoDl.vue';
import CourseMap from '../components/course/CourseMap.vue';
import LessonsCollapse from '../components/course/LessonsCollapse.vue';
import LessonsRegistration from '../components/course/LessonsRegistration.vue';
import RegistrationBox from '../components/course/RegistrationBox.vue';
import SocialShare from '../components/course/SocialShare.vue';
import CourseImage from '../components/course/CourseImage.vue';
import CourseFile from '../components/course/CourseFile.vue';
import BreadCrumbs from '../components/course/BreadCrumbs.vue';

export default defineComponent({
  components: {
    SocialShare,
    LessonsCollapse,
    LessonsRegistration,
    RegistrationBox,
    CourseMap,
    CourseInfoDl,
    CourseImage,
    CourseFile,
    BreadCrumbs
  },

  setup: (props, ctx) => {
    const { response: course, execute: getCourse, state } = useGetCourse();
    const { setTitle } = useTitle();
    const { warnToast, clearErrorToasts, successToast } = useToast(ctx);
    const {
      response: cartStatus,
      execute: addToCartRequest,
      state: addToCartState,
      errorMessage: addToCartError
    } = useAddToCart();

    const unlistedid = computed(() => router.currentRoute.query.unlistedid as string);

    const parseQueryAndGetCourse = () => {
      const { id } = router.currentRoute.params;
      const {
        unlistedid: unlistedIdRaw,
        reqid: reqIdRaw,
        expiry: expiryRaw,
        hmac: hmacRaw,
        preview: previewRaw
      } = router.currentRoute.query;
      const unlistedid = unlistedIdRaw ? (unlistedIdRaw as string) : undefined;
      const reqid = reqIdRaw ? (reqIdRaw as string) : undefined;
      const expiry = expiryRaw ? new Date(expiryRaw as string) : undefined;
      const hmac = hmacRaw ? (hmacRaw as string) : undefined;
      const preview = previewRaw ? true : undefined;
      getCourse({ id, unlistedid, reqid, expiry, hmac, preview });
    };

    const hasError = stateHasError(state);
    const isLoading = stateIsLoading(state);

    // if there is an error with loading the course, redirect user to
    // a not found -page, and don't leave the invalid url to browser's history
    const checkError = (error: boolean) => {
      if (error) {
        router.replace({ name: 'NotFound' });
      }
    };

    const addToCart = () => {
      if (!course.value) {
        return;
      }

      addToCartRequest([{ id: course.value.id }]);
    };

    const ageLimitString = computed<string | undefined>(() => {
      const { ageLimits } = course?.value || {};
      if (!ageLimits || (!ageLimits.minAge && !ageLimits.maxAge)) {
        return undefined;
      }
      const [translationKey, params] = getAgeLimitTranslation(ageLimits);
      return translate(ctx, translationKey, params);
    });

    const registrationToLessons = computed<boolean>(() =>
      includes(HellewiCourseStatus.RegistrationToLessons, course.value?.statuses)
    );

    onBeforeMount(() => {
      parseQueryAndGetCourse();
      checkError(hasError.value);
    });

    watch(addToCartState, () => {
      if (stateHasError(addToCartState).value) {
        if (addToCartError?.value === 'Course too many times in cart') {
          warnToast('cart.add.error.toomany');
        } else {
          warnToast('cart.add.error.default');
        }

        return;
      }

      if (stateIsSuccess(addToCartState).value && cartStatus.value) {
        successToast('cart.add.success');
        clearErrorToasts();
      }
    });

    watch(course, () => {
      if (course.value) {
        setTitle(course.value.name);
      }
    });

    watch(() => ctx.root.$route, parseQueryAndGetCourse);

    watch(() => router.currentRoute.params, parseQueryAndGetCourse, { deep: true });
    watch(() => router.currentRoute.query, parseQueryAndGetCourse, { deep: true });
    watch(hasError, checkError);

    const additionalImages = computed(() => course.value?.images?.slice(1) || []);

    const { language } = router.currentRoute.params;

    const jsonld = computed(() => {
      return {
        '@context': 'https://schema.org',
        '@type': 'Course',
        name: course.value?.name,
        description: course.value?.description,
        courseCode: course.value?.code,
        numberOfCredits: course.value?.ectscredits,
        keywords: uniq(course.value?.catalogitems.map((item) => item.name)),
        teaches: course.value?.learningobjectives,
        contentLocation: {
          '@type': 'Place',
          name: course.value?.name,
          address: {
            '@type': 'PostalAddress',
            addressLocality: course.value?.location?.city,
            streetAddress: course.value?.location?.address,
            postalCode: course.value?.location?.postalcode
          },
          geo: {
            '@type': 'GeoCoordinates',
            latitude: course.value?.location?.latlon?.lat,
            longitude: course.value?.location?.latlon?.lon
          }
        },
        hasCourseInstance: course.value?.periods?.map(({ begins, ends, name }) => {
          return {
            '@type': 'CourseInstance',
            name,
            endDate: `${ends?.getFullYear()}-${ends?.getMonth()}-${ends?.getDate()}`,
            startDate: `${begins?.getFullYear()}-${begins?.getMonth()}-${begins?.getDate()}`,
            instructor: {
              '@type': 'Person',
              name: course.value?.teacher
            },
            inLanguage: course.value?.language?.name,
            offers: course.value?.prices?.flatMap(({ amount }) => {
              return amount
                ? [
                    {
                      '@type': 'Offer',
                      price: `${amount / 100}`,
                      priceCurrency: 'EUR'
                    }
                  ]
                : [];
            })
          };
        }),
        provider: {
          '@type': 'Organization',
          name: course.value?.catalogitems.find((item) => item.type === 'tenant')?.name,
          url: `https://${course.value?.tenant}`
        },
        author: {
          '@type': 'Person',
          name: course.value?.teacher
        }
      };
    });

    return {
      addToCart,
      course,
      CourseInfoDlField,
      isLoading,
      language,
      registrationToLessons,
      sortBy,
      additionalImages,
      jsonld,
      unlistedid,
      ageLimitString
    };
  }
});
</script>
