import { render, staticRenderFns } from "./PromotionCarousel.vue?vue&type=template&id=4d4e6ddd&scoped=true"
import script from "./PromotionCarousel.vue?vue&type=script&lang=ts"
export * from "./PromotionCarousel.vue?vue&type=script&lang=ts"
import style0 from "./PromotionCarousel.vue?vue&type=style&index=0&id=4d4e6ddd&prod&scoped=true&lang=scss"
import style1 from "./PromotionCarousel.vue?vue&type=style&index=1&id=4d4e6ddd&prod&lang=scss"


/* normalize component */
import normalizer from "!../../../node_modules/@vue/vue-loader-v15/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "4d4e6ddd",
  null
  
)

export default component.exports