import { find, includes, isEmpty, isEqual, map, memoize } from 'lodash/fp';
import PCancelable from 'p-cancelable';
import { ref } from '@vue/composition-api';

import {
  Configuration,
  CourseApi,
  CourseApiInterface,
  GetCourseRequest,
  HellewiCourse,
  HellewiCourseCount,
  HellewiCoursePartial,
  HellewiCourseStatus,
  HellewiParticipantCount,
  ListCoursesRequest
} from '../api';
import { Api, ApiEndpoint, ApiEndpointInitialization, RequestState } from '../utils/api-utils';

export const useCourseApi: Api<CourseApiInterface> = memoize(() => {
  const api = ref<CourseApiInterface | undefined>(undefined);

  const changeConfiguration = (configuration: Configuration) => {
    api.value = new CourseApi(configuration);
  };

  return {
    api,
    changeConfiguration
  };
});

export const useGetCourseCount: ApiEndpoint<void, HellewiCourseCount | undefined> = memoize(() => {
  const initial = undefined;
  const { api } = useCourseApi();
  const state = ref<RequestState>(RequestState.Uninitialized);
  const response = ref<HellewiCourseCount | undefined>(initial);

  ApiEndpointInitialization(api, state, response, initial);

  const execute = async () => {
    if (
      !api.value ||
      state.value === RequestState.Uninitialized ||
      // request already ongoing, don't start a new one
      state.value === RequestState.Loading ||
      // don't load again if this is already successfully loaded
      state.value === RequestState.Success
    ) {
      return;
    }

    try {
      state.value = RequestState.Loading;
      response.value = await api.value.getCourseCount({});
      state.value = RequestState.Success;
    } catch {
      response.value = initial;
      state.value = RequestState.Error;
    }
  };

  return {
    initial,
    state,
    response,
    execute
  };
});

export const useGetCourse: ApiEndpoint<GetCourseRequest, HellewiCourse | undefined> = memoize(
  () => {
    const initial = undefined;
    const { api } = useCourseApi();
    const state = ref<RequestState>(RequestState.Uninitialized);
    const response = ref<HellewiCourse | undefined>(initial);

    ApiEndpointInitialization(api, state, response, initial);

    const execute = async (requestParams: GetCourseRequest) => {
      if (
        !api.value ||
        state.value === RequestState.Uninitialized ||
        // course already loaded successfully, don't load again
        // registration to lessons -course data is reloaded always as the
        // lesson participant counts are fetched with this request
        (state.value === RequestState.Success &&
          response.value?.id === requestParams.id &&
          !includes(HellewiCourseStatus.RegistrationToLessons, response.value?.statuses))
      ) {
        return;
      }

      try {
        response.value = initial;
        state.value = RequestState.Loading;
        response.value = await api.value.getCourse(requestParams);
        state.value = RequestState.Success;
      } catch {
        state.value = RequestState.Error;
      }
    };

    return {
      initial,
      state,
      response,
      execute
    };
  }
);

export interface ListCoursesResponseCourse extends HellewiCoursePartial {
  participantcount?: HellewiParticipantCount;
}

export interface ListCoursesResponse {
  count: number;
  courses: ListCoursesResponseCourse[];
}

export const useListCourses: ApiEndpoint<ListCoursesRequest, ListCoursesResponse> = memoize(() => {
  const initial: ListCoursesResponse = { count: 0, courses: [] };
  const { api } = useCourseApi();
  const state = ref<RequestState>(RequestState.Uninitialized);
  const response = ref<ListCoursesResponse>(initial);
  const currentParams = ref<ListCoursesRequest | undefined>(undefined);
  const ongoing = ref<PCancelable<ListCoursesResponse> | undefined>(undefined);

  ApiEndpointInitialization(api, state, response, initial);

  const execute = async (params: ListCoursesRequest) => {
    if (
      !api.value ||
      state.value === RequestState.Uninitialized ||
      // don't load again if this is already successfully loaded
      (state.value === RequestState.Success && isEqual(currentParams.value, params))
    ) {
      return;
    } else if (ongoing.value) {
      // cancel the previous ongoing load
      ongoing.value.cancel();
    }

    ongoing.value = new PCancelable(async (resolve, reject, onCancel) => {
      onCancel(() => reject('cancelled'));
      try {
        if (!api.value) {
          return;
        }
        const responseRaw = await api.value.listCoursesRaw(params);
        const partialCourses = await responseRaw.value();
        const ids = partialCourses.map((course) => course.id);
        const participantCounts = isEmpty(ids)
          ? []
          : await api.value.listCourseParticipantCounts({
              ids
            });

        const count = Math.min(
          9996, // Upper limit of the API is currently 10k, limit to full pages
          parseInt(responseRaw.raw.headers.get('x-total-count') as string, 10)
        );

        const courses = map(
          (course) => ({
            ...course,
            participantcount: find((pc) => pc.id === course.id, participantCounts)
          }),
          partialCourses
        );

        resolve({ count, courses });
      } catch {
        reject();
      }
    });

    try {
      state.value = RequestState.Loading;
      response.value = initial;
      response.value = await ongoing.value;
      currentParams.value = params;
      state.value = RequestState.Success;
    } catch (err) {
      if (err !== 'cancelled') {
        state.value = RequestState.Error;
      }
      // if this request was cancelled, don't touch the request state as the cancelling
      // request will handle the situation
    }
    ongoing.value = undefined;
  };

  return {
    initial,
    state,
    response,
    execute
  };
});
