import { memoize } from 'lodash/fp';
import { ref } from '@vue/composition-api';
import {
  SurveyApi,
  SurveyApiInterface,
  Configuration,
  GetSurveyRequest,
  PostSurveyRequest
} from '../api';
import { Api, ApiEndpoint, ApiEndpointInitialization, RequestState } from '../utils/api-utils';

export const useSurveyApi: Api<SurveyApiInterface> = memoize(() => {
  const api = ref<SurveyApiInterface | undefined>(undefined);

  const changeConfiguration = (configuration: Configuration) => {
    api.value = new SurveyApi(configuration);
  };

  return {
    api,
    changeConfiguration
  };
});

export const useGetSurvey: ApiEndpoint<GetSurveyRequest, unknown> = memoize(() => {
  const initial: unknown = {};
  const { api } = useSurveyApi();
  const state = ref<RequestState>(RequestState.Uninitialized);
  const response = ref<unknown>(initial);

  ApiEndpointInitialization(api, state, response, initial);

  const execute = async (requestParams: GetSurveyRequest) => {
    if (
      !api.value ||
      state.value === RequestState.Uninitialized ||
      // request already ongoing, don't start a new one
      state.value === RequestState.Loading ||
      // don't load again if this is already successfully loaded
      state.value === RequestState.Success
    ) {
      return;
    }
    try {
      state.value = RequestState.Loading;
      response.value = await api.value.getSurvey(requestParams);
      state.value = RequestState.Success;
    } catch {
      response.value = initial;
      state.value = RequestState.Error;
    }
  };

  return {
    initial,
    state,
    response,
    execute
  };
});

export const usePostSurvey: ApiEndpoint<PostSurveyRequest, void> = memoize(() => {
  const initial = undefined;
  const { api } = useSurveyApi();
  const state = ref<RequestState>(RequestState.Uninitialized);
  const response = ref<void>(initial);
  const errorMessage = ref<string | undefined>();

  ApiEndpointInitialization(api, state, response, response.value);

  const execute = async (req: PostSurveyRequest) => {
    if (
      !api.value ||
      state.value === RequestState.Uninitialized ||
      state.value === RequestState.Loading
    ) {
      return;
    }

    try {
      state.value = RequestState.Loading;
      response.value = await api.value.postSurvey(req);
      errorMessage.value = undefined;
      state.value = RequestState.Success;
    } catch (err) {
      if (typeof err === 'object') {
        // eslint-disable-next-line @typescript-eslint/no-explicit-any
        const e = err as any;
        const res = await e.json();
        errorMessage.value = res.message;
      }

      state.value = RequestState.Error;
    }
  };

  return {
    initial,
    state,
    response,
    errorMessage,
    execute
  };
});
