<!-- similar component RegistrationStatus.vue -->

<style lang="scss" scoped>
.status {
  display: flex;
  align-items: center;
}

.status--available {
  color: $available;
}
.status--almostfull {
  color: $almostfull;
}
.status--sparesAvailable {
  color: $sparesAvailable;
}
.status--full,
.status--registrationClosed,
.status--interrupted,
.status--cancelled {
  color: $full;
}
</style>

<template>
  <div class="status">
    <b-icon icon="circle" size="is-small" :class="`status--${availability.availability}`" />
    {{ $tc(`${i18nKey}.${availability.availability}`, availability.places) }}
  </div>
</template>

<script lang="ts">
import { computed, defineComponent, PropType } from '@vue/composition-api';
import { HellewiCourseStatus, HellewiParticipantCount } from '../api';

export enum Availability {
  almostfull = 'almostfull',
  available = 'available',
  cancelled = 'cancelled',
  full = 'full',
  interrupted = 'interrupted',
  registrationClosed = 'registrationClosed',
  sparesAvailable = 'sparesAvailable'
}

const getAvailability = (
  statuses: HellewiCourseStatus[],
  pc: HellewiParticipantCount
): { availability: Availability | undefined; places: number } => {
  if (statuses.includes(HellewiCourseStatus.Cancelled)) {
    return { availability: Availability.cancelled, places: 0 };
  } else if (statuses.includes(HellewiCourseStatus.Interrupted)) {
    return { availability: Availability.interrupted, places: 0 };
  } else if (!pc.registrationopen) {
    return { availability: Availability.registrationClosed, places: 0 };
  } else if (pc.full && pc.sparefull === false) {
    // sparefull = undefined means that there are no spare places
    return { availability: Availability.sparesAvailable, places: pc.spareavailable || 0 };
  } else if (pc.full) {
    return { availability: Availability.full, places: 0 };
  } else if (pc.almostfull && !pc.full) {
    return { availability: Availability.almostfull, places: pc.available || 0 };
  } else if (!pc.almostfull && !pc.full) {
    return { availability: Availability.available, places: pc.available || 0 };
  }
  return { availability: undefined, places: 0 };
};

export default defineComponent({
  props: {
    participantcount: {
      type: Object as PropType<HellewiParticipantCount>,
      required: true
    },
    statuses: {
      type: Array as PropType<HellewiCourseStatus[]>,
      required: true
    }
  },
  setup: (props) => {
    const availability = computed(() => getAvailability(props.statuses, props.participantcount));

    const i18nKey = computed<string>(() =>
      props.statuses.includes(HellewiCourseStatus.RegistrationToLessons)
        ? 'availabilityLessons'
        : 'availability'
    );
    return {
      availability,
      i18nKey
    };
  }
});
</script>
