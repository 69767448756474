var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"breadcrumbs"},[(_vm.department)?_c('span',[_c('router-link',{attrs:{"to":{ name: 'home', query: { q: _vm.department.keywords[0] } }}},[_vm._v(" "+_vm._s(_vm.department.name)+" ")]),(_vm.category || _vm.subject)?_c('b-icon',{attrs:{"icon":"chevron-right","size":"is-small"}}):_vm._e()],1):_vm._e(),(_vm.category)?_c('span',[_c('router-link',{attrs:{"to":{ name: 'home', query: { q: _vm.category.keywords[0] } }}},[_vm._v(_vm._s(_vm.category.name)+" ")]),(_vm.subject)?_c('b-icon',{attrs:{"icon":"chevron-right","size":"is-small"}}):_vm._e()],1):_vm._e(),(_vm.subject)?_c('span',[_c('router-link',{attrs:{"to":{
        name: 'home',
        query: {
          // Use both keywords for search: e.g. 'subject:8 subject:3/8'
          // It depends on store settings which are properly interpreted as tags
          // so better to use both
          q:
            _vm.subject.keywords.length > 1
              ? ((_vm.subject.keywords[0]) + " " + (_vm.subject.keywords[1]))
              : _vm.subject.keywords[0]
        }
      }}},[_vm._v(_vm._s(_vm.subject.name)+" ")])],1):_vm._e()])}
var staticRenderFns = []

export { render, staticRenderFns }