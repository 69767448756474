import { memoize } from 'lodash/fp';
import { ref } from '@vue/composition-api';
import {
  MelvioApi,
  MelvioApiInterface,
  MelvioSurveyStatus,
  Configuration,
  GetMelvioSurveystatusRequest
} from '../api';
import { Api, ApiEndpoint, ApiEndpointInitialization, RequestState } from '../utils/api-utils';

export const useExternalApi: Api<MelvioApiInterface> = memoize(() => {
  const api = ref<MelvioApiInterface | undefined>(undefined);

  const changeConfiguration = (configuration: Configuration) => {
    api.value = new MelvioApi(configuration);
  };

  return {
    api,
    changeConfiguration
  };
});

export const useGetMelvioSurveyStatus: ApiEndpoint<
  GetMelvioSurveystatusRequest,
  MelvioSurveyStatus
> = memoize(() => {
  const initial: MelvioSurveyStatus = { surveyLink: '' };
  const { api } = useExternalApi();
  const state = ref<RequestState>(RequestState.Uninitialized);
  const response = ref<MelvioSurveyStatus>(initial);

  ApiEndpointInitialization(api, state, response, initial);

  const execute = async (requestParams: GetMelvioSurveystatusRequest) => {
    await new Promise((resolve) => setTimeout(resolve, 1000));
    if (
      !api.value ||
      state.value === RequestState.Uninitialized ||
      // request already ongoing, don't start a new one
      state.value === RequestState.Loading ||
      // don't load again if this is already successfully loaded
      state.value === RequestState.Success
    ) {
      return;
    }
    try {
      state.value = RequestState.Loading;
      response.value = await api.value.getMelvioSurveystatus(requestParams);
      state.value = RequestState.Success;
    } catch {
      response.value = initial;
      state.value = RequestState.Error;
    }
  };

  return {
    initial,
    state,
    response,
    execute
  };
});
