import VueI18n, { DateTimeFormats } from 'vue-i18n';

const numberFormats = {
  fi: {
    currency: {
      style: 'currency',
      currency: 'EUR'
    },
    twodecimal: {
      style: 'decimal',
      minimumFractionDigits: 2,
      maximumFractionDigits: 2
    },
    percent: {
      style: 'percent'
    }
  },
  sv: {
    currency: {
      style: 'currency',
      currency: 'EUR'
    }
  },
  en: {
    currency: {
      style: 'currency',
      currency: 'EUR'
    }
  }
};

const dateTimeFormats = {
  fi: {
    weekdayShort: {
      weekday: 'short'
    },
    weekdayLong: {
      weekday: 'long'
    }
  },
  en: {
    weekdayShort: {
      weekday: 'short'
    },
    weekdayLong: {
      weekday: 'long'
    }
  },
  sv: {
    weekdayShort: {
      weekday: 'short'
    },
    weekdayLong: {
      weekday: 'long'
    }
  }
} as DateTimeFormats;

export const initializeI18n = (): VueI18n =>
  new VueI18n({
    locale: 'fi',
    fallbackLocale: 'fi',
    numberFormats,
    dateTimeFormats,
    silentFallbackWarn: true
  });
