import Vue, { ComponentOptions } from 'vue';
import { NavigationGuard } from 'vue-router';
import { SetupContext, getCurrentInstance } from '@vue/composition-api';

export const filterUndefineds = <T>(xs: Array<T | undefined>): T[] =>
  xs.filter((x) => x !== undefined) as T[];

export const translate = (
  context: SetupContext,
  i18nKey: string,
  params?: { [key: string]: string }
): string => {
  if (context.parent && context.parent.$t) {
    const translation = context.parent.$t(i18nKey, params);

    if (translation) {
      return translation.toString();
    }
  }
  return i18nKey;
};

// This and onBeforeRouteLeave are workarounds for vue2 composition api to support route guards inside setup
// https://github.com/vuejs/composition-api/issues/49
// eslint-disable-next-line @typescript-eslint/no-explicit-any
const onHook = (name: keyof ComponentOptions<Vue>, callback: (...args: any) => void) => {
  const vm = getCurrentInstance();
  const merge = Vue.config.optionMergeStrategies[name];

  if (vm && merge) {
    const prototype = Object.getPrototypeOf(vm.proxy.$options);

    if (prototype[name]) {
      delete prototype[name];
    }

    prototype[name] = merge(vm.proxy.$options[name], callback);
  }
};

export const onBeforeRouteLeave = (callback: NavigationGuard<Vue>): void => {
  return onHook('beforeRouteLeave', callback);
};

export const handleWindowUnload = (e: BeforeUnloadEvent): void => {
  e.preventDefault();
  e.returnValue = '';
};
