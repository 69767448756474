import { render, staticRenderFns } from "./RegistrationStatus.vue?vue&type=template&id=caaf608a&scoped=true"
import script from "./RegistrationStatus.vue?vue&type=script&lang=ts"
export * from "./RegistrationStatus.vue?vue&type=script&lang=ts"
import style0 from "./RegistrationStatus.vue?vue&type=style&index=0&id=caaf608a&prod&lang=scss&scoped=true"


/* normalize component */
import normalizer from "!../../../node_modules/@vue/vue-loader-v15/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "caaf608a",
  null
  
)

export default component.exports