<style lang="scss">
.scrollButton {
  display: none;
  position: sticky;
  top: 85px;
  @media (min-width: 801px) {
    display: flex;
  }
}

.fade-enter-active,
.fade-leave-active {
  transition: opacity 0.3s;
}
.fade-enter,
.fade-leave-to {
  opacity: 0;
}
</style>

<template>
  <transition name="fade">
    <b-button
      v-if="scrollButtonVisible"
      @click="scrollToFilters"
      class="scrollButton"
      icon-left="arrow-up"
      rounded
      :aria-label="$t('Takaisin ylös')"
    >
      {{ $t('Takaisin ylös') }}
    </b-button>
  </transition>
</template>

<script lang="ts">
import { defineComponent, ref, onMounted, onBeforeUnmount } from '@vue/composition-api';
import { throttle } from 'lodash';

export default defineComponent({
  setup: () => {
    const scrollButtonVisible = ref(false);

    const scrollToFilters = () => {
      const mainContainer = document.getElementById('mainContent');
      const headerContainers = document.getElementsByClassName('header-container');

      // Scroll target position offset consists of heading height (~70px) and 30px
      const scrollTargetOffset =
        headerContainers.length > 0 ? headerContainers[0].clientHeight + 30 : 100;
      const mainContainerRelativePosition = mainContainer
        ? mainContainer.getBoundingClientRect().top
        : 0;

      window.scrollTo({
        top: mainContainerRelativePosition + window.scrollY - scrollTargetOffset,
        behavior: 'smooth'
      });
    };

    const onScroll = () => {
      const elements = document.getElementsByClassName('reset-filter');
      const scrolledPastFilters =
        elements.length > 0 && elements[0].getBoundingClientRect().top < 0;
      scrollButtonVisible.value = scrolledPastFilters;
    };

    const throttledOnScroll = throttle(onScroll, 100);

    onMounted(() => {
      window.addEventListener('scroll', throttledOnScroll);
    });
    onBeforeUnmount(() => {
      window.removeEventListener('scroll', throttledOnScroll);
    });

    return { scrollToFilters, scrollButtonVisible };
  }
});
</script>
