<!-- eslint-disable vue/multi-word-component-names -->
<template>
  <div>
    <div class="block">
      <h2 class="title is-5">{{ $t('registration.invoice') }} {{ invoice.referencenumber }}</h2>
    </div>
    <div class="block">
      <b-table :data="invoice.items">
        <b-table-column :label="$t('registration.date')" v-slot="props">
          {{ props.row.timestamp | date }}
        </b-table-column>
        <b-table-column :label="$t('registration.invoiceDetails.typeLabel')" v-slot="props">
          {{ $t(`registration.invoiceDetails.type.${props.row.type}`) }}
        </b-table-column>
        <b-table-column :label="$t('registration.invoiceDetails.name')" v-slot="props">
          <span v-if="props.row.client && props.row.course && props.row.name">
            {{ props.row.course.code }} {{ props.row.course.name }}, {{ props.row.name }},
            {{ $t('registration.client') }}:
            {{ props.row.client.firstname }}
            {{ props.row.client.lastname }}
          </span>
          <span v-else-if="props.row.client && props.row.course">
            {{ props.row.course.code }} {{ props.row.course.name }},
            {{ $t('registration.client').toLowerCase() }}:
            {{ props.row.client.firstname }}
            {{ props.row.client.lastname }}
          </span>
          <span v-else>
            {{ props.row.name }}
          </span>
        </b-table-column>
        <b-table-column :label="$t('registration.invoiceDetails.amount')" v-slot="props" numeric>
          {{ $n(props.row.amount / 100, 'currency') }}
        </b-table-column>
        <template #footer v-if="invoice.items.length > 1">
          <td colspan="3">
            <strong>
              {{ $t('registration.total') }}
            </strong>
          </td>
          <td class="has-text-right">
            <strong>
              {{ $n(invoice.total.amount / 100, 'currency') }}
            </strong>
          </td>
        </template>
      </b-table>
    </div>
    <div class="block sent-to-accounting" v-if="invoice.senttoaccounting">
      {{ $t('registration.sentToAccounting') }}
    </div>
  </div>
</template>

<script lang="ts">
import { defineComponent, PropType } from '@vue/composition-api';

import { PurchaseInvoiceNumber } from '../../api';

export default defineComponent({
  props: {
    invoice: {
      type: Object as PropType<PurchaseInvoiceNumber>,
      required: true
    }
  }
});
</script>
