<style lang="scss">
.reg-form {
  margin-top: 1rem;
  margin-bottom: 1rem;
  display: grid;
  grid-template-columns: repeat(2, minmax(0, 1fr));
  gap: 1rem;

  .is-horizontal {
    padding-top: 0;
    flex-direction: row;
    align-items: center;
    display: flex;
    justify-content: flex-start;

    .field-body {
      flex-grow: 1;
      align-self: start;
      width: 100%;
    }
    .field-label {
      flex-shrink: none;
      text-align: left;
      display: none;
    }
    .control-label {
      flex-grow: 1;
      display: block;
    }
    .check {
      margin-top: 0.2rem;
      align-self: start;
    }
  }

  .is-horizontal .label {
    text-align: left;
  }

  select,
  .select {
    min-width: 100%;
    width: 100%;
  }

  input::-webkit-outer-spin-button,
  input::-webkit-inner-spin-button {
    -webkit-appearance: none;
    margin: 0;
  }

  input[type='number'] {
    -moz-appearance: textfield;
  }

  .checkboxSeparator {
    height: 1rem;
    grid-column-start: span 2;

    @media (max-width: 600px) {
      grid-column-start: span 1;
    }
  }

  .field-body {
    flex-direction: column;
  }

  .control-label {
    width: 100%;
  }

  @media (max-width: 600px) {
    grid-template-columns: 1fr;
  }
}

.required {
  &::after {
    color: #ba1427;
    content: '*';
    margin-left: 0.2rem;
  }
}
</style>

<template>
  <ValidationObserver tag="form" class="reg-form" ref="observer">
    <div v-for="(field, i) in otherFields" :key="field.name + i.toString()">
      <ValidationProvider
        :vid="field.name + i.toString()"
        :rules="{
          required: clientRequired.includes(field.name),
          email: field.inputType === 'email',
          pin: (field.label === 'pin' && field.pattern) || '',
          birthday: (field.label === 'birthday' && field.pattern) || '',
          participantTooYoung:
            (field.label === 'pin' || field.label === 'birthday') &&
            ageLimitDates &&
            ageLimitDates.minBirthDate &&
            ageLimitDates.minBirthDate.toString(),
          participantTooOld:
            (field.label === 'pin' || field.label === 'birthday') &&
            ageLimitDates &&
            ageLimitDates.maxBirthDate &&
            ageLimitDates.maxBirthDate.toString()
        }"
        slim
      >
        <div slot-scope="{ errors }" class="koera">
          <b-field
            :type="{ 'is-danger': errors[0] }"
            :message="$t(errors[0])"
            :label="$t('fieldlabel.' + field.label)"
            :label-for="field.name + '-' + field.inputType"
            :custom-class="clientRequired.includes(field.name) ? 'required' : ''"
          >
            <b-input
              lazy
              v-if="field.type === 'string'"
              v-model.trim="model[field.name]"
              :id="field.name + '-' + field.inputType"
            />

            <b-select
              v-if="field.type === 'number'"
              v-model.trim="model[field.name]"
              :placeholder="$t('select')"
              :id="field.name + '-' + field.inputType"
            >
              <option v-for="option in field.oneOf" :key="option.const" :value="option.const">
                {{ option.title }}
              </option>
            </b-select>

            <p class="help is-danger" v-if="errors.length === 0 && clientAjvErrors.length > 0">
              {{ getFieldError(field.name) }}
            </p>
          </b-field>
        </div>
      </ValidationProvider>
    </div>

    <!-- Separate other fields from permission radio buttons if there are any -->
    <div class="checkboxSeparator" v-if="permissionRadioSelectFields.length > 0" />

    <fieldset v-for="(field, i) in permissionRadioSelectFields" :key="field.name + i.toString()">
      <legend class="label">
        {{ $t('fieldlabel.' + field.label) }}
        <span style="color: #ba1427"> * </span>
      </legend>
      <ValidationProvider :vid="field.name + i.toString()" slim>
        <div>
          <b-field grouped>
            <b-radio v-model="model[field.name]" :name="field.name" :native-value="true">
              {{ $t('fieldlabel.permissiontopublishYes') }}
            </b-radio>
          </b-field>
          <b-field grouped>
            <b-radio v-model="model[field.name]" :name="field.name" :native-value="false">
              {{ $t('fieldlabel.permissiontopublishNo') }}
            </b-radio>
          </b-field>
        </div>
      </ValidationProvider>
      <p
        v-if="
          clientAjvErrors.length > 0 &&
            clientAjvErrors[0] &&
            clientAjvErrors[0].params &&
            clientAjvErrors[0].params.missingProperty &&
            clientAjvErrors[0].params.missingProperty.includes(field.name)
        "
        class="help is-danger"
      >
        {{ $t('fieldlabel.permissiontopublishEmptyError') }}
      </p>
    </fieldset>
  </ValidationObserver>
</template>

<script lang="ts">
import { defineComponent, onMounted, ref, watch, PropType, computed } from '@vue/composition-api';
import { find, partition, uniq, uniqWith } from 'lodash/fp';
import { ValidationProvider, ValidationObserver } from 'vee-validate';

import { AjvError, Client } from '../../views/Cart.vue';
import { translate } from '../../utils/misc-utils';
import { HellewiAgeLimits } from '../../api';
import { calculateMinAndMaxBirthDates } from '../../utils/agelimit';

interface FieldCollection {
  properties: Record<string, unknown>;
  required: string[];
}

interface FieldOption {
  title: string;
  const: number;
}

interface ClientField {
  [key: string]: unknown;
  name: string;
  label?: string;
  sort: number;
  type?: string;
  default?: boolean | string | number;
  oneOf?: FieldOption[];
  pattern?: RegExp;
}

export interface CartItemAgeLimits extends HellewiAgeLimits {
  startDate: string;
  endDate: string;
}

export default defineComponent({
  props: {
    schema: {
      type: Object,
      required: true
    },
    client: {
      type: Object as PropType<Client>,
      required: true
    },
    selectedCourseIds: {
      type: Array as PropType<string[]>,
      required: true
    },
    isPayer: {
      type: Boolean,
      required: true
    },
    ajvErrors: {
      type: Array as PropType<AjvError[]>,
      required: false
    },
    validationTrigger: {
      type: Boolean,
      required: false
    },
    cartItemAgeLimits: {
      type: Object as PropType<CartItemAgeLimits>,
      required: false
    }
  },
  components: {
    ValidationProvider,
    ValidationObserver
  },
  setup: (props, ctx) => {
    const model = ref<Record<string, unknown>>({});
    const clientFields = ref<ClientField[]>([]);
    const clientRequired = ref<string[]>([]);
    const observer = ref<InstanceType<typeof ValidationObserver> | null>(null);

    const otherFields = computed(() => clientFields.value.filter((f) => f.type !== 'boolean'));
    const permissionRadioSelectFields = computed(() =>
      clientFields.value.filter((f) => f.type === 'boolean')
    );

    const clientAjvErrors = computed<AjvError[]>(
      () => props.ajvErrors?.filter((err) => props.client.id === err.clientid) || []
    );

    const initFields = () => {
      model.value = clientFields.value.reduce<Record<string, unknown>>((acc, field) => {
        const fieldName = field.name as string;
        if (field.default) {
          acc[fieldName] = field.default;
        }

        if (field.type === 'boolean') {
          acc[fieldName] = undefined; // Force the user to make a choice between boolean radio buttons, undefined value is not valid
          return acc;
        }

        // Set nationality and language fields to finnish by default
        if (fieldName === 'citizenship') {
          const currentLang = localStorage.getItem('lang');
          let finnishOpt;

          if (currentLang === 'en') {
            finnishOpt = field.oneOf?.find((opt) => opt.title === 'Finland');
          } else {
            finnishOpt = field.oneOf?.find((opt) => opt.title.match(/suom/gim));
          }

          acc[fieldName] = finnishOpt?.const || field.oneOf?.[0].const;

          return acc;
        }

        // Fill fields with valid dummy data for testing on localhost, otherwise stop
        if (location.hostname !== 'localhost') {
          return acc;
        }

        if (field.type === 'number') {
          // eslint-disable-next-line @typescript-eslint/no-explicit-any
          acc[fieldName] = field.oneOf?.[0].const;
          return acc;
        }

        switch (fieldName) {
          case 'email':
            acc[fieldName] = 'test@example.com';
            break;
          case 'birthday':
            acc[fieldName] = '123123';
            break;
          case 'pin':
            acc[fieldName] = '021067-9509';
            break;
          case 'postalcode':
            acc[fieldName] = '12312';
            break;
          case 'phone':
          case 'workphone':
          case 'homephone':
            acc[fieldName] = '123123123';
            break;
          default:
            acc[fieldName] = 'test test';
            break;
        }
        return acc;
      }, {});
    };

    const getFieldError = (fieldName: string) => {
      const error = clientAjvErrors.value.find((e) => e.field === fieldName);

      if (!error) {
        return '';
      }

      return translate(ctx, `validation.${error.keyword}`);
    };

    const selectPinField = (pinFields: ClientField[]): ClientField | undefined => {
      if (pinFields.length === 0) {
        return undefined;
      }

      // select payer's billing id field, but change name to 'pin'
      const billingIdField = find((f) => f.name === 'billingid', pinFields);
      if (billingIdField) {
        return { ...billingIdField, name: 'pin', label: 'pin' };
      }

      // select field which requires the full pattern
      const fullPinField = find(
        (f) =>
          f.pattern?.toString() ===
          '/^[0-9]{6}[+\\-Aa][0-9]{3}[0123456789ABCDEFHJKLMNPRSTUVWXYabcdefhjklmnprstuvwxy]$/',
        pinFields
      );
      if (fullPinField) {
        return fullPinField;
      }

      // select birthday pin field, change label to 'birthday'
      const birthdayPinField = find(
        (f) =>
          f.pattern?.toString() ===
          '/^[0-9]{6}$|^[0-9]{6}[+\\-Aa][0-9]{3}[0123456789ABCDEFHJKLMNPRSTUVWXYabcdefhjklmnprstuvwxy]$/',
        pinFields
      );
      if (birthdayPinField) {
        return {
          ...birthdayPinField,
          label: 'birthday'
        };
      }

      // fields should always match one of the above cases, but if not, return just the first one
      return pinFields[0];
    };

    const propertiesToClientFields = (properties: Record<string, unknown>): ClientField[] =>
      Object.entries(properties).map(([key, value]) => {
        const clientField = value as ClientField;
        return {
          ...clientField,
          name: key,
          label: key
        };
      });

    const updateForm = () => {
      let required: string[] = [];
      let fields: ClientField[] = props.schema.properties.registrations.items
        // Filter out courses that haven't been selected
        // eslint-disable-next-line @typescript-eslint/no-explicit-any
        .filter((reg: any) =>
          props.selectedCourseIds.includes(
            reg.properties.course.allOf[0].properties.id.const.toString()
          )
        )
        // Merge client fields together (allOf) and flatten the result
        // eslint-disable-next-line @typescript-eslint/no-explicit-any
        .map((registration: any) => {
          // Map fields to objects with name attribute instead of the name being the key
          // In the same loop add fields that have been marked as required to the combined "required" list
          return registration.properties.client.allOf
            .map((fieldCollection: FieldCollection) => {
              if (fieldCollection.required) {
                required = required.concat(fieldCollection.required);
              }
              return propertiesToClientFields(fieldCollection.properties);
            })
            .flat();
        })
        .flat();

      // Add payer fields if client is marked as payer
      const payer = props.schema.properties.payer;
      if (props.isPayer && payer) {
        required = required.concat(payer.required);
        fields = fields.concat(propertiesToClientFields(payer.properties));
      }

      fields = fields.map((f) => ({
        ...f,
        inputType:
          f.name === 'email'
            ? 'email'
            : f.name === 'postalcode' || f.name === 'phone'
            ? 'number'
            : 'field',
        pattern: f.pattern ? new RegExp(f.pattern) : undefined
      }));

      // Select the correct pin field
      const [pinFields, fieldsWithoutPinWithDuplicates] = partition(
        (f) => f.name === 'pin' || f.name === 'billingid',
        fields
      );
      const pinField = selectPinField(pinFields);

      // filter out duplicates, have to compare only names because payer's and
      // client's fields can have different sort
      //
      // client fields should take priority over payer's so that the fields don't
      // flicker when chaning the "this client is payer"-setting
      const fieldsWithoutPin = uniqWith(
        (a, b) => a.name === b.name,
        fieldsWithoutPinWithDuplicates
      );

      clientFields.value = (pinField ? [...fieldsWithoutPin, pinField] : fieldsWithoutPin)
        // sort by fields' sort
        .sort((a: ClientField, b: ClientField) => a.sort - b.sort);

      clientRequired.value = uniq(required.map((r) => (r === 'billingid' ? 'pin' : r)));
    };

    onMounted(() => {
      updateForm();
      initFields();
    });

    watch(
      () => props.validationTrigger,
      async () => {
        if (!props.validationTrigger || !observer) {
          return;
        }

        const val = await observer.value?.validate();
        ctx.emit('validate', val);
      }
    );

    watch(
      model,
      (fields) => {
        ctx.emit('update-client', { ...props.client, fields });
      },
      { deep: true }
    );

    watch(() => props.selectedCourseIds, updateForm);
    watch(() => props.isPayer, updateForm);
    watch(
      () => props.schema,
      () => {
        updateForm();
        ctx.emit('update-client', { ...props.client, fields: model.value });
      }
    );

    const ageLimitDates = computed(() => {
      const { startDate, endDate, minAge, maxAge } = props.cartItemAgeLimits || {};
      if (!startDate || !endDate || (!minAge && !maxAge)) {
        return null;
      }
      const ageLimits = calculateMinAndMaxBirthDates(startDate, endDate, minAge, maxAge);
      return {
        minBirthDate: ageLimits.minBirthDate?.toISOString().split('T')[0],
        maxBirthDate: ageLimits.maxBirthDate?.toISOString().split('T')[0]
      };
    });

    return {
      permissionRadioSelectFields,
      clientAjvErrors,
      clientFields,
      clientRequired,
      getFieldError,
      model,
      observer,
      otherFields,
      ageLimitDates
    };
  }
});
</script>
