var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"card"},[_c('div',{staticClass:"card-content"},[_c('div',{staticClass:"wrapper"},[_c('section',{staticClass:"mainSection"},[_c('div',{staticClass:"block"},[_c('h2',{staticClass:"title"},[_c('router-link',{attrs:{"to":{ name: 'course', params: { id: _vm.course.id } }}},[_vm._v(" "+_vm._s(_vm.course.name)+" ")])],1),_c('p',{staticClass:"subtitle is-hidden-mobile"},[_vm._v(" "+_vm._s(_vm.course.code)+" ")])]),(_vm.course.notifications && _vm.course.notifications.length > 0)?_c('div',{staticClass:"notifications block",class:{ notificationsColumns: _vm.course.notifications.length > 1 }},_vm._l((_vm.course.notifications),function(notification,i){return _c('b-message',{key:i,attrs:{"type":"is-primary is-light","has-icon":"","icon":"information","icon-size":"is-small","closable":false}},[_vm._v(" "+_vm._s(notification.text)+" ")])}),1):_vm._e(),_c('section',{staticClass:"block"},[_c('CourseInfoDl',{staticClass:"courseDetails",class:{ 'mobile-hidden': !_vm.mobileCardOpen },attrs:{"course":_vm.course,"spacing":true,"fields":[
              _vm.CourseInfoDlField.Periods,
              _vm.CourseInfoDlField.Weekdays,
              _vm.CourseInfoDlField.Location,
              _vm.CourseInfoDlField.Teacher,
              _vm.CourseInfoDlField.Teachingformat
            ],"locationMapLink":true,"locationSingleLine":false},on:{"map-modal-open":function($event){_vm.mapModalOpen = true}}}),_c('CourseInfoDl',{staticClass:"courseDetails hidden",class:{ 'mobile-visible': !_vm.mobileCardOpen },attrs:{"course":_vm.course,"spacing":false,"fields":[_vm.CourseInfoDlField.MobileMinimal]}})],1),_c('footer',{staticClass:"detailsFooter block",class:{ 'mobile-hidden': !_vm.mobileCardOpen }},[_c('router-link',{attrs:{"to":{ name: 'course', params: { id: _vm.course.id } }}},[_vm._v(" "+_vm._s(_vm.$t('details'))+" » ")])],1)]),_c('section',{staticClass:"cartSection",class:{ mobileMinimalSection: !_vm.mobileCardOpen }},[_c('div',{staticClass:"cart-section-content"},[(_vm.price)?_c('h2',{staticClass:"title"},[_vm._v(" "+_vm._s(_vm.$n(_vm.price, 'currency'))+" ")]):_vm._e(),(_vm.showDetailedPrice)?_c('p',{staticClass:"subtitle"},[_vm._v(" "+_vm._s(_vm.$t('cart.viewdetails'))+" ")]):_vm._e(),(
              (_vm.course.registrationbegins ||
                _vm.course.registrationendshard ||
                _vm.course.registrationendssoft) &&
                !_vm.course.statuses.includes(_vm.HellewiCourseStatus.RegistrationToLessons)
            )?_c('div',{staticClass:"add-to-cart-availibility"},[(_vm.addButtonShown)?_c('b-button',{staticClass:"addToCartButton",attrs:{"type":"is-primary","disabled":_vm.addButtonDisabled,"icon-left":"cart","aria-label":_vm.$t('cart.addtocart')},on:{"click":function($event){return _vm.addToCart(_vm.course.id)}}},[_vm._v(" "+_vm._s(_vm.$t('cart.addtocart'))+" ")]):_vm._e()],1):_c('router-link',{attrs:{"to":{ name: 'course', params: { id: _vm.course.id } }}},[_c('b-button',{staticClass:"addToCartButton",attrs:{"type":"is-primary","icon-left":"calendar","aria-label":_vm.course.statuses.includes(_vm.HellewiCourseStatus.RegistrationToLessons)
                  ? _vm.$t('cart.checktimes')
                  : _vm.$t('registration.details')}},[_vm._v(" "+_vm._s(_vm.course.statuses.includes(_vm.HellewiCourseStatus.RegistrationToLessons) ? _vm.$t('cart.checktimes') : _vm.$t('registration.details'))+" ")])],1),_c('CourseInfoDl',{class:{ 'mobile-hidden': !_vm.mobileCardOpen },attrs:{"course":_vm.course,"spacing":true,"fields":[_vm.CourseInfoDlField.RegistrationTime]}}),(
              _vm.course.registrationbegins ||
                _vm.course.registrationendshard ||
                _vm.course.registrationendssoft ||
                _vm.course.statuses.includes(_vm.HellewiCourseStatus.Cancelled) ||
                _vm.course.statuses.includes(_vm.HellewiCourseStatus.Interrupted)
            )?_c('div',{staticClass:"cart-availability"},[(_vm.participantcount)?_c('CourseAvailability',{staticClass:"availability-container",class:{ mobileMinimalSection: !_vm.mobileCardOpen },attrs:{"participantcount":_vm.participantcount,"statuses":_vm.course.statuses}}):_vm._e()],1):_vm._e()],1)]),_c('b-button',{staticClass:"mobile-toggle-open visible-only-mobile",attrs:{"type":"is-ghost","aria-label":_vm.$t(_vm.mobileCardOpen ? 'showLess' : 'showMore')},on:{"click":_vm.toggleMobileCardOpen}},[_vm._v(" "+_vm._s(_vm.$t(_vm.mobileCardOpen ? 'showLess' : 'showMore'))+" "),_c('b-icon',{attrs:{"icon":_vm.mobileCardOpen ? 'chevron-up' : 'chevron-down'}})],1)],1)]),(_vm.course.location && _vm.course.location.latlon)?_c('b-modal',{staticClass:"modal",attrs:{"aria-label":"Map Modal","aria-modal":""},model:{value:(_vm.mapModalOpen),callback:function ($$v) {_vm.mapModalOpen=$$v},expression:"mapModalOpen"}},[_c('div',{staticClass:"modal-wrapper"},[_c('CourseMap',{staticClass:"course-map",attrs:{"coordinates":_vm.course.location.latlon,"text":_vm.course.location.name}}),_c('b-button',{staticClass:"closeBtn",attrs:{"type":"is-primary","aria-label":_vm.$t('close')},on:{"click":function($event){_vm.mapModalOpen = false}}},[_vm._v(" "+_vm._s(_vm.$t('close'))+" ")])],1)]):_vm._e()],1)}
var staticRenderFns = []

export { render, staticRenderFns }