<style lang="scss" scoped>
.tags-container {
  display: flex;
  flex-direction: column;
  width: 100%;
  @media (max-width: 650px) {
    margin-top: 0.5rem;
  }
}
.search-input {
  width: 100%;

  > span.icon.is-right {
    width: 32px;
  }
}
.tags {
  margin-bottom: 0;
}

.reset-filter {
  line-height: 1.5rem;
  height: 1.5rem;
  align-self: flex-start;
}
</style>

<style lang="scss">
.tags-container .tag {
  background-color: var(--brandcolor) !important;
}
</style>

<template>
  <div class="tags-container">
    <b-taglist v-if="tags.length > 0" class="tags">
      <b-tag
        v-for="tag in tags"
        v-bind:key="tag.keyword"
        type="is-primary"
        closable
        aria-close-label="Close tag"
        @close="removeFilter(tag)"
        v-on:keyup.native.enter="removeFilter(tag)"
      >
        {{ getTagText(tag) }}
      </b-tag>
      <a class="reset-filter" @click.prevent="resetAllFilters" href="#">
        {{ $t('deselectFilters') }}
      </a>
    </b-taglist>
  </div>
</template>

<script lang="ts">
import { defineComponent, ref, watch } from '@vue/composition-api';
import { useCatalogFilters, Filter } from '../../hooks/useCatalogFilters';
import useSearchParams from '../../hooks/useSearchParams';
import { translate } from '../../utils/misc-utils';

import { HellewiCatalogItemType } from '../../api';

export default defineComponent({
  setup: (props, ctx) => {
    const {
      resetCatalogFilterGroups,
      filterGroups,
      selected,
      selectedParents,
      getFilterTags,
      findFiltersForKeyword,
      getFilterGroupIndividualFilters,
      parseDateinputFilter
    } = useCatalogFilters(ctx);
    const { keyword: searchKeyword } = useSearchParams(ctx);

    const tags = ref<Filter[]>([]);
    const filters = ref<Filter[]>([]);

    const getSearchAsFilter = (): Filter => ({
      keyword: 'search',
      name: `${translate(ctx, 'search.term')}: ${searchKeyword.value}`,
      inputId: '',
      disabled: false,
      subFilters: [],
      courseCount: -1,
      siblings: []
    });

    const clearSearch = () => {
      ctx.emit('search', '');
    };

    const getFiltersAndTags = () => {
      filters.value = getFilterGroupIndividualFilters();
      tags.value = searchKeyword.value
        ? [getSearchAsFilter(), ...getFilterTags()]
        : getFilterTags();
    };

    const removeFilter = (removableFilter: Filter) => {
      if (removableFilter.keyword === 'search') {
        clearSearch();
        return;
      }
      selected.value = selected.value.filter(
        (selectedKeyword) =>
          // Delete clicked keyword
          removableFilter.keyword !== selectedKeyword &&
          // Delete similar name filters
          !filters.value
            .filter(({ keyword }) => selected.value.includes(keyword))
            .find(
              ({ name, keyword }) => selectedKeyword === keyword && name === removableFilter.name
            ) &&
          // Delete subfilters
          !removableFilter.subFilters.map(({ keyword }) => keyword).includes(selectedKeyword)
      );
      selectedParents.value = selectedParents.value.filter(
        (selectedParentKeyword) =>
          // Delete clicked keyword
          removableFilter.keyword !== selectedParentKeyword &&
          // Delete parent filters when no active subfilters
          selected.value.some((kw) =>
            findFiltersForKeyword(filters.value, kw).some(
              ({ parent }) => parent === selectedParentKeyword
            )
          )
      );
      ctx.emit('filters-changed', selected.value);
    };

    const getTagText = (tag: Filter) => {
      if (tag.type === HellewiCatalogItemType.Dateinput) {
        const { dateStr, codeStr, comparatorStr } = parseDateinputFilter(tag.keyword, ctx);
        return dateStr ? `${codeStr} ${comparatorStr} ${dateStr}` : translate(ctx, tag.name);
      } else {
        return tag.translatelabel ? translate(ctx, tag.name) : tag.name;
      }
    };

    const resetAllFilters = () => {
      clearSearch();
      resetCatalogFilterGroups();
    };

    watch([selected, selectedParents, filterGroups], getFiltersAndTags);

    return { getTagText, tags, resetAllFilters, removeFilter };
  }
});
</script>
