<!-- eslint-disable vue/multi-word-component-names -->
<style scoped lang="scss">
.nav-title {
  font-size: 1.4rem;
  font-weight: 600;
  color: $darkGrey;
  color: var(--brandcolor);
  overflow-wrap: break-word;
}

.header-container {
  position: sticky;
  top: 0;
  z-index: 66;
  margin-bottom: 1rem;
}

.header {
  width: 100%;
  background: $bg-white;
  z-index: 66;
  border-bottom: 1px solid #d0d9d2;
}

.header-desktop {
  padding: 0.5rem 4rem;

  .icon {
    margin-left: 0.5rem !important;
  }
}

.navbar {
  z-index: 39 !important;
  border-bottom: 1px solid #d0d9d2;
}

.navbar-menu {
  z-index: 99;
}

.header-mobile {
  display: none;
}

.collapse {
  display: none;
}

.navbar-item {
  font-weight: 600;
}

.cart {
  display: flex;
  align-items: center;
  flex-direction: column;
  padding: 0;
  margin-right: -0.5rem;
}

.cartText {
  display: block;
}

.collapse-content {
  margin-left: 1rem;
}

.cart-icon {
  position: relative;
  align-items: center;
  margin-bottom: 0.5rem;
}

.cartCount {
  position: absolute;
  top: -1px;
  right: -1px;
  font-size: 12px;
  color: white;
  background: #ba1427;
  border-radius: 9px;
  height: 18px;
  width: 18px;
  text-align: center;
}

#app .dropdown-btn {
  display: flex;
  align-items: center;
  justify-items: center;
}

.cartLabel {
  span {
    display: block;
  }

  .timeWarning {
    font-size: 14px;
    font-weight: 600;
    color: #ba1427;
    position: absolute;
    top: 25px;
    width: 4rem;
  }
}

@media (max-width: 1025px) {
  .header-mobile {
    display: grid;
    grid-template-columns: 2rem 1fr 3rem;
    align-items: center;
    padding: 1rem;
  }

  .collapse {
    display: block;
    z-index: 55;
    background: $bg-white;
    width: 100%;
  }

  .header-desktop {
    display: none;
  }

  .nav-title {
    text-align: center;
    font-size: 0.9rem;
    margin: 0;
    max-height: 2.1rem;
    line-height: 1rem;
    overflow: hidden;
  }

  .cart-icon {
    margin: 0;

    .cartCount {
      top: -5px;
      right: -2px;
      height: 18px;
      width: 18px;
    }
  }

  .cartLabel {
    display: flex;
    flex-direction: column;
    align-items: center;
    margin-top: -0.2rem;

    .timeWarning {
      position: unset;
      width: auto;
      font-size: 12px;
      top: 0;
    }

    .cartText {
      display: none;
    }
  }
}

@media (max-width: 450px) {
  .header-mobile {
    padding: 0.5rem 1rem;
  }
}
</style>

<style lang="scss">
.navbar-brand {
  flex-shrink: 1;
  .navbar-item {
    flex-shrink: 1;
  }
}
</style>

<template>
  <div v-if="brand" class="header-container">
    <b-navbar class="header header-desktop" wrapper-class="container">
      <template slot="brand">
        <b-navbar-item tag="router-link" :to="{ name: 'home' }">
          <h1 class="nav-title">
            {{ brand.name }}
          </h1>
        </b-navbar-item>
      </template>

      <template slot="end">
        <b-navbar-item tag="router-link" :to="{ name: 'home' }">
          {{ $t('nav.courses') }}
        </b-navbar-item>
        <b-navbar-item tag="router-link" :to="{ name: 'my-registrations-login-link' }">
          {{ $t('nav.myregistrations') }}
        </b-navbar-item>
        <b-navbar-item tag="router-link" :to="{ name: 'help' }">
          {{ $t('nav.help') }}
        </b-navbar-item>
        <b-navbar-dropdown>
          <template #label>
            {{ $t('nav.language') }}<b-icon icon="earth" style="margin-left: 0.3rem" />
          </template>
          <b-navbar-item @click.prevent="setLanguage('fi')" aria-role="listitem" href="#">
            Suomeksi
          </b-navbar-item>
          <b-navbar-item @click.prevent="setLanguage('sv')" aria-role="listitem" href="#">
            På Svenska
          </b-navbar-item>
          <b-navbar-item @click.prevent="setLanguage('en')" aria-role="listitem" href="#">
            In English
          </b-navbar-item>
        </b-navbar-dropdown>
        <b-navbar-item tag="router-link" :to="{ name: 'cart' }">
          <span>{{ $t('nav.cart') }}</span>
          <div class="cartLabel">
            <b-tooltip
              :label="$tc('nav.tooltip', minutesLeft)"
              position="is-bottom"
              size="is-small"
              multilined
              :active="minutesLeft >= 0"
            >
              <div class="cart-icon">
                <b-icon icon="cart" />
                <span class="cartCount" v-if="cartStatus && cartStatus.count > 0">
                  {{ cartStatus.count }}
                </span>
                <span v-if="minutesLeft === 0" class="timeWarning">&lt;1&nbsp;min</span>
                <span class="timeWarning" v-if="minutesLeft > 0">{{ minutesLeft }} &nbsp; min</span>
              </div>
            </b-tooltip>
          </div>
        </b-navbar-item>
      </template>
    </b-navbar>

    <nav class="header header-mobile">
      <div @click="isOpen = !isOpen" aria-controls="nav-items">
        <b-icon v-if="isOpen" icon="close" class="nav-item" />
        <b-icon v-else icon="menu" class="nav-item" />
      </div>

      <b-navbar-item tag="router-link" :to="{ name: 'home' }">
        <h1 class="nav-title">
          {{ brand.name }}
        </h1>
      </b-navbar-item>

      <b-navbar-item tag="router-link" :to="{ name: 'cart' }" class="nav-item cart">
        <div class="cart-icon">
          <b-icon icon="cart" />
          <span class="cartCount" v-if="cartStatus && cartStatus.count > 0">
            {{ cartStatus.count }}
          </span>
        </div>
        <div class="cartLabel">
          <span class="cartText">{{ $t('nav.cart') }}</span>
          <span v-if="minutesLeft === 0" class="timeWarning">&lt;1&nbsp;min</span>
          <span class="timeWarning" v-if="minutesLeft > 0">{{ minutesLeft }} &nbsp; min</span>
        </div>
      </b-navbar-item>

      <b-collapse v-model="isOpen" aria-id="nav-items">
        <div class="content collapse-content">
          <b-navbar-item
            class="nav-item"
            tag="router-link"
            :to="{ name: 'home' }"
            @click.native="isOpen = !isOpen"
          >
            {{ $t('nav.courses') }}
          </b-navbar-item>
          <b-navbar-item
            class="nav-item"
            tag="router-link"
            :to="{ name: 'my-registrations-login-link' }"
            @click.native="isOpen = !isOpen"
          >
            {{ $t('nav.myregistrations') }}
          </b-navbar-item>
          <b-navbar-item
            class="nav-item"
            tag="router-link"
            :to="{ name: 'help' }"
            @click.native="isOpen = !isOpen"
          >
            {{ $t('nav.help') }}
          </b-navbar-item>

          <b-navbar-item class="nav-item">
            <b-dropdown aria-role="list">
              <template #trigger>
                <div class="dropdown-btn">
                  {{ $t('nav.language') }}
                  <b-icon icon="earth" style="margin-left: 0.3rem" />
                </div>
              </template>

              <b-dropdown-item aria-role="listitem" @click="setLanguage('fi')" href="#">
                Suomeksi
              </b-dropdown-item>
              <b-dropdown-item aria-role="listitem" @click="setLanguage('sv')" href="#">
                På Svenska
              </b-dropdown-item>
              <b-dropdown-item aria-role="listitem" @click="setLanguage('en')" href="#">
                In English
              </b-dropdown-item>
            </b-dropdown>
          </b-navbar-item>
        </div>
      </b-collapse>
    </nav>
  </div>
</template>

<script lang="ts">
import {
  computed,
  defineComponent,
  onBeforeUnmount,
  onBeforeMount,
  ref,
  watch
} from '@vue/composition-api';
import { useCartStatus } from '../hooks/useCartApi';
import { useGetBrand } from '../hooks/useBrandApi';
import { DialogProgrammatic as Dialog } from 'buefy';
import router from '../router';
import { translate } from '../utils/misc-utils';

export default defineComponent({
  setup: (props, ctx) => {
    const CART_REFRESH_TIMER = 61000; // time between each refresh in milliseconds

    const { response: cartStatus, execute: getCartStatus } = useCartStatus();
    const { response: brand, execute: getBrand } = useGetBrand();
    const isOpen = ref<boolean>(false);

    const timer = ref<number>(0);
    const minutesLeft = computed(() => {
      if (!cartStatus.value?.timeleft) {
        return;
      }
      if (cartStatus.value.timeleft < 60) {
        return 0;
      }

      return Math.round(cartStatus.value.timeleft / 60);
    });

    const setLanguage = (newLang: string) => {
      const curLang = ctx.root.$i18n.locale;
      if (newLang === curLang) {
        return;
      }

      localStorage.setItem('lang', newLang);
      // lang in path is either in the end /<lang>
      // or in the middle /<lang>/
      const curLangRe = new RegExp(`/${curLang}(?:/|$)`);
      isOpen.value = false;

      router.push(
        {
          path: router.currentRoute.path.replace(curLangRe, `/${newLang}/`),
          query: { ...router.currentRoute.query }
        },
        () => {
          router.go(0);
        }
      );
    };

    watch(cartStatus, (newVal, oldVal) => {
      if (!newVal) {
        return;
      }

      // Display a modal if the cart expires
      // Do not display the modal if the cart was emptied by deleting the last item or completing the registration
      if (!newVal.timeleft && oldVal?.timeleft && oldVal?.timeleft > 0 && !newVal.manuallyCleared) {
        Dialog.alert({
          message: translate(ctx, 'cart.expired'),
          confirmText: translate(ctx, 'close'),
          onConfirm: () => router.push({ name: 'home' })
        });
      }
    });

    watch(cartStatus, () => {
      clearInterval(timer.value);

      if (cartStatus.value && cartStatus.value.count > 0 && cartStatus.value.timeleft) {
        timer.value = window.setInterval(
          () => {
            getCartStatus();
          },
          cartStatus.value.timeleft < CART_REFRESH_TIMER / 1000
            ? cartStatus.value.timeleft * 1000 + 5000
            : CART_REFRESH_TIMER
        );
      }
    });

    onBeforeMount(() => {
      getCartStatus();
      getBrand();
    });

    onBeforeUnmount(() => {
      clearInterval(timer.value);
    });

    return { isOpen, minutesLeft, cartStatus, brand, setLanguage };
  }
});
</script>
