<style lang="scss" scoped>
h4 {
  font-weight: $weight-semibold;
}

.modal-wrapper {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;

  .closeBtn {
    position: absolute;
    bottom: 0;
  }
}

.title {
  font-size: 1.6rem;

  a {
    color: $darkGrey;
    color: var(--brandcomplementarycolor);
  }
}

.card {
  margin-bottom: 2rem;
  box-shadow: none;
  border: 1px solid $lightGrayOutline;
  border-radius: 13px;
}

.wrapper {
  display: flex;
  flex-direction: row;
}

.mainSection {
  width: 100%;
}

.courseDetails {
  margin-top: 1rem;
  column-count: 2;
  column-width: 100px;
}

.detailsFooter {
  margin-top: 1rem;
  font-weight: $weight-bold;
}

.cartSection {
  width: 50%;
  border-left: 1px solid $lightGrayOutline;
  padding-left: 3rem;
  margin-left: 1rem;
}

.subtitle {
  min-height: 1rem;
  color: $lightGray;
  font-size: 0.9rem;
}

.addToCartButton {
  margin-bottom: 1.5rem;
  width: 100%;
  font-weight: $weight-bold;
  font-size: 1.1rem;
  height: 3rem;
  border-radius: 9px;
}

.availability-container {
  margin-top: 1rem;
}

.cart {
  margin-right: 0.5rem !important;
}

.course-map {
  width: 80%;
  height: 80%;
  position: absolute;
  top: 10%;
  right: 10%;
}

.detail-container {
  margin-bottom: 1rem;
}

.notifications {
  .message {
    width: 100%;
    break-inside: avoid;
    page-break-inside: avoid;
    margin-bottom: 0.5rem;
  }
}

@media (min-width: $tablet) {
  .notificationsColumns {
    column-count: 2;
  }
}

@media (max-width: $widescreen) {
  .wrapper {
    flex-direction: column;
  }

  .cartSection {
    width: 100%;
    border-top: 1px solid $lightGrayOutline;
    border-left: 0;
    padding-top: 2rem;
    padding-left: 0;
    margin-top: 2rem;
    margin-left: 0rem;
  }

  .cart-section-content {
    margin: 0 auto;
    max-width: 30rem;
  }
}

@media (max-width: $desktop) {
  .course-map {
    width: 100%;
    right: 0;
  }
}

@media (max-width: $tablet) {
  .courseDetails {
    display: flex;
    flex-direction: column;
  }
  .card {
    margin-bottom: 1rem;
  }
}

.mobile-toggle-open {
  margin: 1rem -1rem -1rem;
  border: none;
  color: var(--brandcolor);

  @media (min-width: 400px) {
    margin: 1rem -2rem -2rem;
  }
}

.hidden {
  display: none;
}

@media (min-width: 550px) {
  .visible-only-mobile {
    display: none;
  }
}

@media (max-width: 550px) {
  .addToCartButton {
    margin-bottom: 1rem;
    font-size: 1rem;
    height: 2.5rem;
  }
  .block {
    margin-bottom: 0.5rem;
  }
  .courseDetails {
    font-size: 0.9rem;
  }
  .title {
    font-size: 1.2rem;
  }
  .card {
    margin-bottom: 1rem;
  }
  .mobile-hidden {
    display: none;
  }
  .mobile-visible {
    display: initial;
  }
  .mobileMinimalSection {
    padding-top: 0;
    border-top-width: 0;
    margin-top: 0;
  }
}
</style>

<style lang="scss">
.card > .modal .animation-content {
  width: 100%;
  height: 100%;
  max-width: 100% !important;
}

.card .notifications .message .message-body {
  color: #285475 !important;

  padding: 0.5rem 0.8rem;
  .media-left {
    margin-right: 0.2rem;
  }
}

// Show more/less icon margin
.mobile-toggle-open > span {
  display: flex;
  & > .icon {
    margin-left: 0 !important;
  }
}
</style>

<template>
  <div class="card">
    <div class="card-content">
      <div class="wrapper">
        <section class="mainSection">
          <div class="block">
            <h2 class="title">
              <router-link :to="{ name: 'course', params: { id: course.id } }">
                {{ course.name }}
              </router-link>
            </h2>
            <p class="subtitle is-hidden-mobile">
              {{ course.code }}
            </p>
          </div>

          <div
            class="notifications block"
            v-if="course.notifications && course.notifications.length > 0"
            :class="{ notificationsColumns: course.notifications.length > 1 }"
          >
            <b-message
              v-for="(notification, i) in course.notifications"
              :key="i"
              type="is-primary is-light"
              has-icon
              icon="information"
              icon-size="is-small"
              :closable="false"
            >
              {{ notification.text }}
            </b-message>
          </div>

          <section class="block">
            <CourseInfoDl
              class="courseDetails"
              :class="{ 'mobile-hidden': !mobileCardOpen }"
              :course="course"
              :spacing="true"
              :fields="[
                CourseInfoDlField.Periods,
                CourseInfoDlField.Weekdays,
                CourseInfoDlField.Location,
                CourseInfoDlField.Teacher,
                CourseInfoDlField.Teachingformat
              ]"
              :locationMapLink="true"
              :locationSingleLine="false"
              @map-modal-open="mapModalOpen = true"
            />
            <CourseInfoDl
              class="courseDetails hidden"
              :class="{ 'mobile-visible': !mobileCardOpen }"
              :course="course"
              :spacing="false"
              :fields="[CourseInfoDlField.MobileMinimal]"
            />
          </section>

          <footer class="detailsFooter block" :class="{ 'mobile-hidden': !mobileCardOpen }">
            <router-link :to="{ name: 'course', params: { id: course.id } }">
              {{ $t('details') }} &raquo;
            </router-link>
          </footer>
        </section>

        <section class="cartSection" :class="{ mobileMinimalSection: !mobileCardOpen }">
          <div class="cart-section-content">
            <h2 class="title" v-if="price">
              {{ $n(price, 'currency') }}
            </h2>

            <p class="subtitle" v-if="showDetailedPrice">
              {{ $t('cart.viewdetails') }}
            </p>
            <div
              v-if="
                (course.registrationbegins ||
                  course.registrationendshard ||
                  course.registrationendssoft) &&
                  !course.statuses.includes(HellewiCourseStatus.RegistrationToLessons)
              "
              class="add-to-cart-availibility"
            >
              <b-button
                type="is-primary"
                class="addToCartButton"
                @click="addToCart(course.id)"
                v-if="addButtonShown"
                :disabled="addButtonDisabled"
                icon-left="cart"
                :aria-label="$t('cart.addtocart')"
              >
                {{ $t('cart.addtocart') }}
              </b-button>
            </div>
            <router-link :to="{ name: 'course', params: { id: course.id } }" v-else>
              <b-button
                type="is-primary"
                class="addToCartButton"
                icon-left="calendar"
                :aria-label="
                  course.statuses.includes(HellewiCourseStatus.RegistrationToLessons)
                    ? $t('cart.checktimes')
                    : $t('registration.details')
                "
              >
                {{
                  course.statuses.includes(HellewiCourseStatus.RegistrationToLessons)
                    ? $t('cart.checktimes')
                    : $t('registration.details')
                }}
              </b-button>
            </router-link>

            <CourseInfoDl
              :class="{ 'mobile-hidden': !mobileCardOpen }"
              :course="course"
              :spacing="true"
              :fields="[CourseInfoDlField.RegistrationTime]"
            />
            <div
              v-if="
                course.registrationbegins ||
                  course.registrationendshard ||
                  course.registrationendssoft ||
                  course.statuses.includes(HellewiCourseStatus.Cancelled) ||
                  course.statuses.includes(HellewiCourseStatus.Interrupted)
              "
              class="cart-availability"
            >
              <CourseAvailability
                class="availability-container"
                :class="{ mobileMinimalSection: !mobileCardOpen }"
                v-if="participantcount"
                :participantcount="participantcount"
                :statuses="course.statuses"
              />
            </div>
          </div>
        </section>
        <b-button
          type="is-ghost"
          class="mobile-toggle-open visible-only-mobile"
          @click="toggleMobileCardOpen"
          :aria-label="$t(mobileCardOpen ? 'showLess' : 'showMore')"
        >
          {{ $t(mobileCardOpen ? 'showLess' : 'showMore') }}
          <b-icon :icon="mobileCardOpen ? 'chevron-up' : 'chevron-down'"> </b-icon>
        </b-button>
      </div>
    </div>

    <b-modal
      v-model="mapModalOpen"
      aria-label="Map Modal"
      aria-modal
      v-if="course.location && course.location.latlon"
      class="modal"
    >
      <div class="modal-wrapper">
        <CourseMap
          class="course-map"
          :coordinates="course.location.latlon"
          :text="course.location.name"
        />

        <b-button
          type="is-primary"
          @click="mapModalOpen = false"
          class="closeBtn"
          :aria-label="$t('close')"
        >
          {{ $t('close') }}
        </b-button>
      </div>
    </b-modal>
  </div>
</template>

<script lang="ts">
import { PropType, defineComponent, computed, ref } from '@vue/composition-api';
import { includes, sortBy } from 'lodash/fp';

import { HellewiCoursePartial, HellewiCourseStatus, HellewiParticipantCount } from '../../api';
import { getPriceEuros, getDefaultPrice } from '../../utils/course-utils';
import { formatWeekdays, FormatedWeekday } from '../../utils/date-utils';

import CourseMap from '../course/CourseMap.vue';
import CourseAvailability from '../CourseAvailability.vue';
import CourseInfoDl, { CourseInfoDlField } from '../CourseInfoDl.vue';

export default defineComponent({
  props: {
    course: {
      type: Object as PropType<HellewiCoursePartial>,
      required: true
    },
    participantcount: {
      type: Object as PropType<HellewiParticipantCount>,
      required: false
    }
  },
  components: {
    CourseAvailability,
    CourseInfoDl,
    CourseMap
  },

  setup: (props, ctx) => {
    const mapModalOpen = ref<boolean>(false);
    const mobileCardOpen = ref<boolean>(false);
    const addButtonDisabled = computed<boolean>(
      () =>
        !props.participantcount?.registrationopen ||
        (props.participantcount.full && props.participantcount.sparefull)
    );
    const addButtonShown = computed<boolean>(
      () => !includes(HellewiCourseStatus.RegistrationToLessons, props.course.statuses)
    );
    const showDetailedPrice = computed<boolean>(() =>
      Boolean(
        props.course.prices?.length &&
          (props.course.prices?.length > 1 || props.course.prices[0].installmentgroups?.length)
      )
    );

    const price = computed(() => getPriceEuros(getDefaultPrice(props.course)) || undefined);
    const address = computed(() => {
      if (!props.course.location || !props.course.location.address) {
        return undefined;
      }

      return props.course.location.address;
    });

    const addToCart = (courseId: string) => {
      ctx.emit('add-to-cart', courseId);
    };

    const formattedWeekdays = computed<FormatedWeekday[]>(() => formatWeekdays(props.course?.days));

    const toggleMobileCardOpen = () => {
      mobileCardOpen.value = !mobileCardOpen.value;
    };

    return {
      addButtonDisabled,
      addButtonShown,
      address,
      addToCart,
      CourseInfoDlField,
      formattedWeekdays,
      HellewiCourseStatus,
      mapModalOpen,
      price,
      showDetailedPrice,
      sortBy,
      toggleMobileCardOpen,
      mobileCardOpen
    };
  }
});
</script>
