import { partition } from 'lodash/fp';
import { Ref, SetupContext, onBeforeMount, ref, watch } from '@vue/composition-api';

type useSearchParamsReturnType = {
  query: Ref<string>;
  filters: Ref<string[]>;
  keyword: Ref<string | undefined>;
};

const useSearchParams = (ctx: SetupContext): useSearchParamsReturnType => {
  const query = ref<string>(ctx.root.$route.query.q as string);
  const filters = ref<string[]>([]);
  const keyword = ref<string | undefined>();

  const updateValues = () => {
    query.value = ctx.root.$route.query.q as string;

    if (!query.value) {
      keyword.value = undefined;
      filters.value = [];
      return;
    }

    const [filtersInQuery, keywordsInQuery] = partition(
      (x) => x.includes(':'),
      query.value.split(' ')
    );

    keyword.value = keywordsInQuery.length > 0 ? keywordsInQuery.join(' ') : undefined;
    filters.value = filtersInQuery;
  };

  watch(
    () => ctx.root.$route,
    () => {
      updateValues();
    }
  );

  onBeforeMount(() => {
    updateValues();
  });

  return { query, filters, keyword };
};

export default useSearchParams;
