<style lang="scss" scoped>
.cart-availability {
  margin-bottom: 1.5rem;
}
@media (min-width: $tablet) and (max-width: $desktop) {
  .courseDetails {
    column-count: 2;
  }
}

@include desktop-only {
  .cart-button {
    font-size: 1rem;
  }
}
</style>

<template>
  <div class="registration-box" v-if="course">
    <div v-if="price" class="block">
      <strong class="is-size-3">{{ $n(price, 'currency') }}</strong>
    </div>

    <div class="block">
      <CourseInfoDl
        class="courseDetails"
        :course="course"
        :fields="courseInfoDlFields"
        :spacing="true"
      />
    </div>

    <div
      v-if="
        course.registrationbegins ||
          course.registrationendshard ||
          course.registrationendssoft ||
          course.statuses.includes(HellewiCourseStatus.Cancelled) ||
          course.statuses.includes(HellewiCourseStatus.Interrupted)
      "
      class="cart-availability"
    >
      <div class="block" v-if="!registrationToLessons">
        <CourseAvailability
          :participantcount="course.participantcount"
          :statuses="course.statuses"
        />
      </div>
    </div>
    <div
      v-if="
        (course.registrationbegins || course.registrationendshard || course.registrationendssoft) &&
          !course.statuses.includes(HellewiCourseStatus.Cancelled) &&
          !course.statuses.includes(HellewiCourseStatus.Interrupted)
      "
      class="add-to-cart-availability"
    >
      <!-- don't know why but this button is better without div.block -->
      <b-button
        class="button is-primary is-medium cart-button"
        icon-left="cart"
        :aria-label="$t('cart.addtocart')"
        @click.native="addToCart"
        v-if="!registrationToLessons"
        :disabled="
          !course.participantcount.registrationopen ||
            (course.participantcount.full && course.participantcount.sparefull)
        "
      >
        {{ $t('cart.addtocart') }}
      </b-button>
    </div>
  </div>
</template>

<script lang="ts">
import { computed, defineComponent, PropType } from '@vue/composition-api';

import { includes, sortBy } from 'lodash/fp';

import { HellewiCoursePartial, HellewiCourseStatus } from '../../api';
import { getDefaultPrice, getPriceEuros } from '../../utils/course-utils';
import { formatWeekdays, FormatedWeekday } from '../../utils/date-utils';

import CourseAvailability from './../CourseAvailability.vue';
import CourseInfoDl, { CourseInfoDlField } from '../CourseInfoDl.vue';

export default defineComponent({
  components: {
    CourseAvailability,
    CourseInfoDl
  },
  props: {
    course: {
      type: Object as PropType<HellewiCoursePartial>
    }
  },
  setup(props, ctx) {
    const formattedWeekdays = computed<FormatedWeekday[]>(() => formatWeekdays(props.course?.days));
    const price = computed<number | undefined>(() => getPriceEuros(getDefaultPrice(props.course)));

    const addToCart = () => {
      if (ctx.root.$route.query.unlistedid && props.course?.registrationlink) {
        window.location.href = props.course.registrationlink;
      } else {
        ctx.emit('cart-add');
      }
    };

    const courseInfoDlFields: CourseInfoDlField[] = [
      CourseInfoDlField.Period,
      CourseInfoDlField.Weekdays,
      CourseInfoDlField.RegistrationTime,
      CourseInfoDlField.PriceClasses
    ];

    const registrationToLessons = computed<boolean>(() =>
      includes(HellewiCourseStatus.RegistrationToLessons, props.course?.statuses)
    );

    return {
      addToCart,
      courseInfoDlFields,
      formattedWeekdays,
      getDefaultPrice,
      getPriceEuros,
      HellewiCourseStatus,
      price,
      registrationToLessons,
      sortBy
    };
  }
});
</script>
