var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"card payer-card"},[_c('div',{staticClass:"card-content"},[_c('h2',{staticClass:"title"},[_vm._v(_vm._s(_vm.$t('cart.payer.info')))]),(
        (_vm.required.includes('billingid') || _vm.clientpinrequired) &&
          _vm.brand &&
          _vm.brand.onlycompanybilling === false
      )?_c('div',{staticClass:"block"},[_c('b-field',[_c('b-checkbox',{model:{value:(_vm.payerFromClient),callback:function ($$v) {_vm.payerFromClient=$$v},expression:"payerFromClient"}},[_vm._v(_vm._s(_vm.$t('cart.client.payer')))])],1)],1):_vm._e(),(_vm.brand && _vm.brand.onlycompanybilling === true)?_c('div',{staticClass:"block"},[_c('b-field',[_c('b-checkbox',{model:{value:(_vm.companyBilling),callback:function ($$v) {_vm.companyBilling=$$v},expression:"companyBilling"}},[_vm._v(_vm._s(_vm.$t('cart.payer.companybilling'))+" ")])],1)],1):_vm._e(),(!(_vm.brand && _vm.brand.onlycompanybilling === true))?_c('div',{staticClass:"block"},[_c('b-field',[_c('b-radio',{attrs:{"native-value":"private","disabled":_vm.payerFromClient},model:{value:(_vm.payerType),callback:function ($$v) {_vm.payerType=$$v},expression:"payerType"}},[_vm._v(" "+_vm._s(_vm.$t('cart.payer.private'))+" ")]),_c('b-radio',{attrs:{"native-value":"business","disabled":_vm.payerFromClient},model:{value:(_vm.payerType),callback:function ($$v) {_vm.payerType=$$v},expression:"payerType"}},[_vm._v(" "+_vm._s(_vm.$t('cart.payer.business'))+" ")])],1)],1):_vm._e(),_c('ValidationObserver',{directives:[{name:"show",rawName:"v-show",value:(!(_vm.brand && _vm.brand.onlycompanybilling === true && _vm.companyBilling === false)),expression:"!(brand && brand.onlycompanybilling === true && companyBilling === false)"}],ref:"observer",staticClass:"reg-form",attrs:{"tag":"form"}},_vm._l((_vm.fields),function(field,i){return _c('ValidationProvider',{key:i,attrs:{"rules":{
          required: _vm.required.includes(field.name),
          email: field.inputType === 'email',
          pin: (field.label === 'pin' && field.pattern) || '',
          birthday: (field.label === 'birthday' && field.pattern) || ''
        }},scopedSlots:_vm._u([{key:"default",fn:function(ref){
        var errors = ref.errors;
return _c('div',{},[_c('b-field',{attrs:{"type":{ 'is-danger': errors[0] },"horizontal":field.type === 'boolean',"message":_vm.$t(errors[0]),"label":_vm.$t('fieldlabel.' + field.label),"label-for":field.name + '-' + field.inputType,"custom-class":_vm.required.includes(field.name) ? 'required' : ''}},[(field.type === 'string')?_c('b-input',{attrs:{"lazy":"","type":field.inputType ? field.inputType : 'text',"disabled":_vm.payerFromClient,"id":field.name + '-' + field.inputType},model:{value:(_vm.model[field.name]),callback:function ($$v) {_vm.$set(_vm.model, field.name, (typeof $$v === 'string'? $$v.trim(): $$v))},expression:"model[field.name]"}}):_vm._e(),(field.type === 'number')?_c('b-select',{attrs:{"placeholder":_vm.$t('select'),"disabled":_vm.payerFromClient,"id":field.name + '-' + field.inputType},model:{value:(_vm.model[field.name]),callback:function ($$v) {_vm.$set(_vm.model, field.name, (typeof $$v === 'string'? $$v.trim(): $$v))},expression:"model[field.name]"}},_vm._l((field.oneOf),function(option){return _c('option',{key:option.const,domProps:{"value":option.const}},[_vm._v(" "+_vm._s(option.title)+" ")])}),0):_vm._e(),(field.type === 'boolean')?_c('b-checkbox',{model:{value:(_vm.model[field.name]),callback:function ($$v) {_vm.$set(_vm.model, field.name, $$v)},expression:"model[field.name]"}}):_vm._e(),(errors.length === 0 && _vm.payerAjvErrors.length > 0)?_c('p',{staticClass:"help is-danger"},[_vm._v(" "+_vm._s(_vm.getFieldError(field.name))+" ")]):_vm._e()],1)],1)}}],null,true)})}),1)],1)])}
var staticRenderFns = []

export { render, staticRenderFns }