<!-- eslint-disable vue/multi-word-component-names -->
<style scoped lang="scss">
.callout {
  align-items: center;
  display: flex;
  justify-content: center;
  padding: 0.5em;
  border: 1px solid;
  margin: 1rem 0;
  white-space: pre-wrap;
}

.callouts {
  margin-top: 2rem !important;
  margin-bottom: 2rem !important;

  @media (max-width: 1025px) {
    padding: 0 0.5rem;
  }
}
</style>

<template>
  <div class="container callouts" v-if="callouts.length">
    <div
      class="notification is-primary is-light callout"
      v-for="callout in callouts"
      :key="callout.text"
      v-dompurify-html="callout.text"
    >
      {{ callout.text }}
    </div>
  </div>
</template>

<script lang="ts">
import { defineComponent, onBeforeMount } from '@vue/composition-api';
import { useGetLocalCallouts } from '../hooks/useCalloutsApi';

export default defineComponent({
  setup: () => {
    const { response: callouts, execute: getLocalCallouts } = useGetLocalCallouts();

    onBeforeMount(() => {
      getLocalCallouts();
    });

    return { callouts };
  }
});
</script>
