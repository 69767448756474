var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"search-filters",attrs:{"id":"search-filters"}},[_vm._l((_vm.filterGroups),function(filterGroup){return _c('div',{key:filterGroup.name,staticClass:"filtergroup"},[_c('header',{attrs:{"role":"button","aria-controls":filterGroup.name},on:{"click":function($event){return _vm.toggleGroupOpen(filterGroup.name)}}},[_c('div',[_c('h4',{staticClass:"filtergroup-label"},[_vm._v(" "+_vm._s(_vm.$t(filterGroup.name))+" ")])]),_c('aside',{staticClass:"filter-amount"},[_c('b-button',{staticClass:"filtergroup-button",attrs:{"aria-label":_vm.getFilterGroupToggleButtonLabel(filterGroup.name)}},[_c('b-icon',{attrs:{"icon":_vm.isGroupOpen(filterGroup.name) ? 'chevron-up' : 'chevron-down',"size":"is-medium"}})],1)],1)]),_c('b-collapse',{attrs:{"aria-role":"list","animation":"slide","aria-id":filterGroup.name,"open":_vm.isGroupOpen(filterGroup.name)}},[_c('div',{staticClass:"filters"},_vm._l((filterGroup.filters),function(filter){return _c('div',{directives:[{name:"show",rawName:"v-show",value:(!filter.disabled),expression:"!filter.disabled"}],key:filter.inputId,class:[
            'filter',
            filterGroup.type,
            {
              'has-children': filter.subFilters.length > 0
            }
          ],attrs:{"aria-role":"listitem"}},[(filter.type === _vm.HellewiCatalogItemType.Dateinput)?_c('div',{key:"coursesbeginningdate",class:['filter', 'date'],attrs:{"aria-role":"listitem"}},[_c('div',{staticClass:"filter-container"},[_c('div',{staticClass:"input-container"},[_c('b-field',{attrs:{"label":_vm.$t(("" + (filter.name)))}},[_c('b-datepicker',{attrs:{"placeholder":_vm.$t(("" + (filter.name))),"icon":"calendar-today","icon-right-clickable":"","trap-focus":"","icon-right":'close-circle',"value":_vm.getDatefilterValue(filter.name),"first-day-of-week":1,"years-range":[-1, 1]},on:{"icon-right-click":function($event){return _vm.clearDate(filter)},"input":function($event){return _vm.dateChanged(filter, $event)}}})],1)],1)])]):_vm._e(),(filter.type !== _vm.HellewiCatalogItemType.Dateinput)?_c('div',{staticClass:"filter-container",on:{"change":function($event){return _vm.filterClick(filter)}}},[_c('div',{staticClass:"input-container"},[_c('b-checkbox',{class:[{ 'checkbox-with-subfilters': filter.subFilters.length > 0 }],attrs:{"id":filter.inputId,"type":filterGroup.type,"value":_vm.getFilterCheckboxValue(filter),"native-value":filter.keyword,"disabled":filter.disabled,"indeterminate":_vm.isIndeterminateFilter(filter)}},[_vm._v(" "+_vm._s(filter.translatelabel ? _vm.$t(filter.name) : filter.name)+" ")]),(filter.subFilters.length > 0)?_c('b-button',{staticClass:"subfilter-button",attrs:{"aria-label":_vm.getFilterGroupToggleButtonLabel(filterGroup.name)},on:{"click":function($event){$event.stopPropagation();_vm.toggleSubfilterGroup(
                    filter.keyword,
                    filter.subFilters,
                    !_vm.openSubFilters.find(function (key) { return key === filter.keyword; })
                  )}}},[_c('b-icon',{attrs:{"custom-class":"subfilter-toggle","icon":_vm.openSubFilters.find(function (key) { return key === filter.keyword; })
                      ? 'chevron-up'
                      : 'chevron-down'}})],1):_vm._e()],1),(filter.type !== _vm.HellewiCatalogItemType.Date)?_c('aside',{staticClass:"filter-amount"},[_vm._v(" "+_vm._s(filter.courseCount)+" ")]):_vm._e()]):_vm._e(),(_vm.openSubFilters.find(function (key) { return key === filter.keyword; }))?_c('div',{staticClass:"subfilters"},_vm._l((filter.subFilters),function(subFilter){return _c('div',{directives:[{name:"show",rawName:"v-show",value:(!subFilter.disabled),expression:"!subFilter.disabled"}],key:subFilter.keyword,staticClass:"filter-container",class:['filter', filterGroup.type]},[_c('div',{staticClass:"input-container",on:{"change":function($event){return _vm.filterClick(subFilter)}}},[_c('b-checkbox',{attrs:{"id":subFilter.inputId,"type":filterGroup.type,"value":_vm.getSubFilterCheckboxValue(filter, subFilter),"native-value":subFilter.keyword,"disabled":subFilter.disabled}},[_vm._v(" "+_vm._s(subFilter.name)+" ")])],1),_c('aside',{staticClass:"filter-amount"},[_vm._v(_vm._s(subFilter.courseCount))])])}),0):_vm._e()])}),0)])],1)}),_c('footer',[_c('a',{staticClass:"reset-filter",attrs:{"href":"#"},on:{"click":function($event){$event.preventDefault();return _vm.resetFilterGroups()}}},[_vm._v(" "+_vm._s(_vm.$t('deselectFilters'))+" ")])])],2)}
var staticRenderFns = []

export { render, staticRenderFns }