import { Ref, computed } from '@vue/composition-api';
import { useGetBrand } from './useBrandApi';

const useTitle = (): {
  setTitle: (handle?: string) => string | undefined;
  brandIsLoaded: Ref<boolean>;
} => {
  const { response: brand } = useGetBrand();
  const brandIsLoaded = computed<boolean>(() => brand.value !== undefined);

  const setTitle = (handle?: string) => {
    if (!brand.value) {
      return handle;
    }

    document.title = handle ? `${handle} - ${brand.value.name}` : brand.value.name;
  };

  return { setTitle, brandIsLoaded };
};

export default useTitle;
