import { memoize } from 'lodash/fp';
import { ref } from '@vue/composition-api';
import {
  BrandApi,
  BrandApiInterface,
  Configuration,
  HellewiBrand,
  HellewiPromotion,
  HellewiText
} from '../api';
import { Api, ApiEndpoint, ApiEndpointInitialization, RequestState } from '../utils/api-utils';
import HttpStatusCode from '../utils/http-status-codes';

export const useBrandApi: Api<BrandApiInterface> = memoize(() => {
  const api = ref<BrandApiInterface | undefined>(undefined);

  const changeConfiguration = (configuration: Configuration) => {
    api.value = new BrandApi(configuration);
  };

  return {
    api,
    changeConfiguration
  };
});

export const useGetBrand: ApiEndpoint<void, HellewiBrand | undefined> = memoize(() => {
  const initial = undefined;
  const { api } = useBrandApi();
  const state = ref<RequestState>(RequestState.Uninitialized);
  const response = ref<HellewiBrand | undefined>(initial);
  const status = ref<number | undefined>();

  ApiEndpointInitialization(api, state, response, initial);

  const execute = async () => {
    if (
      !api.value ||
      state.value === RequestState.Uninitialized ||
      // request already ongoing, don't start a new one
      state.value === RequestState.Loading ||
      // don't load again if this is already successfully loaded
      state.value === RequestState.Success
    ) {
      return;
    }

    try {
      state.value = RequestState.Loading;
      response.value = await api.value.getBrand();
      status.value = HttpStatusCode.OK;
      state.value = RequestState.Success;
    } catch (err) {
      response.value = await err.json();
      status.value = err.status;
      state.value = RequestState.Error;
    }
  };

  return {
    initial,
    state,
    response,
    execute,
    status
  };
});

export const useGetPromotions: ApiEndpoint<void, HellewiPromotion[]> = memoize(() => {
  const initial: HellewiPromotion[] = [];
  const { api } = useBrandApi();
  const state = ref<RequestState>(RequestState.Uninitialized);
  const response = ref<HellewiPromotion[]>(initial);

  ApiEndpointInitialization(api, state, response, initial);

  const execute = async () => {
    if (
      !api.value ||
      state.value === RequestState.Uninitialized ||
      // request already ongoing, don't start a new one
      state.value === RequestState.Loading ||
      // don't load again if this is already successfully loaded
      state.value === RequestState.Success
    ) {
      return;
    }

    try {
      state.value = RequestState.Loading;
      response.value = await api.value.listPromotions();
      state.value = RequestState.Success;
    } catch {
      response.value = initial;
      state.value = RequestState.Error;
    }
  };

  return {
    initial,
    state,
    response,
    execute
  };
});

export const useGetHelp: ApiEndpoint<void, HellewiText | undefined> = memoize(() => {
  const initial = undefined;
  const { api } = useBrandApi();
  const state = ref<RequestState>(RequestState.Uninitialized);
  const response = ref<HellewiText | undefined>(initial);

  ApiEndpointInitialization(api, state, response, initial);

  const execute = async () => {
    if (
      !api.value ||
      state.value === RequestState.Uninitialized ||
      state.value === RequestState.Loading ||
      state.value === RequestState.Success
    ) {
      return;
    }

    try {
      state.value = RequestState.Loading;
      response.value = await api.value.getHelp();
      state.value = RequestState.Success;
    } catch {
      response.value = initial;
      state.value = RequestState.Error;
    }
  };

  return {
    initial,
    state,
    response,
    execute
  };
});
