import { memoize } from 'lodash/fp';
import { ref } from '@vue/composition-api';

import {
  Configuration,
  GetPaymentTokenRequest,
  HellewiMyRegistrationsResponse,
  HellewiMyRegistrationsResponseFromJSON,
  HTTPQuery,
  JSONApiResponse,
  MethodOfPayment,
  PaymentApi,
  PaymentApiInterface
} from '../api';
import { Api, ApiEndpoint, ApiEndpointInitialization, RequestState } from '../utils/api-utils';

export interface HellewiPaymentApiInterface extends PaymentApiInterface {
  getPaymentRequest(req: GetPaymentRequest): Promise<HellewiMyRegistrationsResponse>;
}

export class HellewiPaymentApi extends PaymentApi implements HellewiPaymentApiInterface {
  public async getPaymentRequest(req: GetPaymentRequest): Promise<HellewiMyRegistrationsResponse> {
    const response = await this.request({
      headers: {},
      method: 'GET',
      path: `/payment/${req.paymentmethod}/${req.status}`,
      query: req.query
    });

    return new JSONApiResponse(response, (jsonValue) =>
      HellewiMyRegistrationsResponseFromJSON(jsonValue)
    ).value();
  }
}

export const usePaymentApi: Api<HellewiPaymentApiInterface> = memoize(() => {
  const api = ref<HellewiPaymentApiInterface | undefined>(undefined);

  const changeConfiguration = (configuration: Configuration) => {
    api.value = new HellewiPaymentApi(configuration);
  };

  return {
    api,
    changeConfiguration
  };
});

export interface GetPaymentRequest {
  paymentmethod: string;
  status: string;
  query: HTTPQuery;
}

export const useGetPaymentRequest: ApiEndpoint<
  GetPaymentRequest,
  HellewiMyRegistrationsResponse | undefined
> = memoize(() => {
  const initial = undefined;
  const { api } = usePaymentApi();
  const state = ref<RequestState>(RequestState.Uninitialized);
  const response = ref<HellewiMyRegistrationsResponse | undefined>(initial);

  ApiEndpointInitialization(api, state, response, initial);

  const execute = async (req: GetPaymentRequest) => {
    if (
      !api.value ||
      state.value === RequestState.Uninitialized
      // here it doesn't matter if there already is an ongoing request,
      // just make a new one if function is called (don't even cancel the old one)
      // The only way to make these requests is with Payment-component's
      // onBeforeMount, so there should only ever be one of these
    ) {
      return;
    }

    try {
      state.value = RequestState.Loading;
      response.value = await api.value.getPaymentRequest(req);
      state.value = RequestState.Success;
    } catch {
      response.value = initial;
      state.value = RequestState.Error;
    }
  };

  return {
    initial,
    state,
    response,
    execute
  };
});

export const useGetPaymentToken: ApiEndpoint<
  GetPaymentTokenRequest,
  MethodOfPayment | undefined
> = memoize(() => {
  const initial = undefined;
  const { api } = usePaymentApi();
  const state = ref<RequestState>(RequestState.Uninitialized);
  const response = ref<MethodOfPayment | undefined>(initial);
  const errorMessage = ref<string | undefined>();

  ApiEndpointInitialization(api, state, response, initial);

  const execute = async (req: GetPaymentTokenRequest) => {
    if (
      !api.value ||
      state.value === RequestState.Uninitialized ||
      // request already ongoing, don't start a new one
      state.value === RequestState.Loading
    ) {
      return;
    }
    try {
      state.value = RequestState.Loading;
      response.value = await api.value.getPaymentToken(req);
      state.value = RequestState.Success;
    } catch (err) {
      if (typeof err === 'object') {
        // eslint-disable-next-line @typescript-eslint/no-explicit-any
        const e = err as any;
        const res = await e.json();
        errorMessage.value = res.message;
      }
      state.value = RequestState.Error;
    }
  };

  return {
    initial,
    errorMessage,
    state,
    response,
    execute
  };
});
