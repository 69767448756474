<style lang="scss" scoped>
.title {
  margin-bottom: 1rem;
}

.noBrand {
  display: flex;
  margin: 4rem;
  min-width: 100vw;

  .card {
    min-width: 15rem;
  }
}

.card-content {
  display: flex;
  flex-direction: column;
  align-items: center;
  text-align: center;
  padding: 4rem 1rem;
}
</style>

<template>
  <div class="card" v-if="brandIsLoaded">
    <div class="card-content">
      <h2 class="title">{{ $t('404.title') }}</h2>
      <p>{{ $t('404.content') }}</p>
    </div>
  </div>

  <!-- This should only be visible when the user navigates to "/" so no brand or language -->
  <section class="noBrand" v-else>
    <div class="card">
      <div class="card-content">
        <h2>404</h2>
        <p>Sivua ei löytynyt / Page not found</p>
      </div>
    </div>
  </section>
</template>

<script lang="ts">
import { defineComponent, onBeforeMount } from '@vue/composition-api';
import useTitle from '../hooks/useTitle';

export default defineComponent({
  setup: () => {
    const { setTitle, brandIsLoaded } = useTitle();

    onBeforeMount(() => setTitle('404'));

    return { brandIsLoaded };
  }
});
</script>
