<style lang="scss">
.pagination {
  justify-content: center;
}

.pagination-list {
  flex-grow: 0;
  flex-shrink: 0;
}
</style>

<template>
  <div id="courselist">
    <div v-if="isLoading">
      <b-skeleton width="100%" height="20rem" :animated="true" />
      <b-skeleton width="100%" height="20rem" :animated="true" />
      <b-skeleton width="100%" height="20rem" :animated="true" />
    </div>
    <div v-else-if="courses && courses.length < 1">{{ $t('search.noresults') }}</div>
    <CourseCard
      v-for="course in courses"
      :key="course.id"
      :course="course"
      :participantcount="course.participantcount"
      @add-to-cart="addToCart"
    />
    <b-pagination
      v-if="courseCount > COURSES_ON_PAGE"
      v-model="currentPage"
      :per-page="COURSES_ON_PAGE"
      :total="courseCount"
      range-before="3"
      range-after="4"
      order="is-centered"
      aria-next-label="Next"
      aria-previous-label="Previous"
      aria-page-label="Page"
      aria-current-label="Current page"
      @change="changePage"
    >
    </b-pagination>
  </div>
</template>

<script lang="ts">
import { computed, defineComponent, onMounted, ref, PropType, watch } from '@vue/composition-api';
import { includes } from 'lodash/fp';

import { HellewiCourseStatus } from '../../api';
import router from '../../router';
import { useListCourses } from '../../hooks/useCourseApi';
import { useAddToCart } from '../../hooks/useCartApi';
import { stateHasError, stateIsLoading, stateIsSuccess, useToast } from '../../utils/api-utils';

import { Sorting } from '../home/Sort.vue';
import CourseCard from './CourseCard.vue';

export default defineComponent({
  components: { CourseCard },
  props: {
    sort: {
      type: Object as PropType<Sorting>,
      required: false
    }
  },
  setup: (props, ctx) => {
    const COURSES_ON_PAGE = 12;

    const {
      response: listCoursesResponse,
      execute: listCourses,
      state: listCoursesState
    } = useListCourses();
    const {
      response: cartStatus,
      execute: addToCartRequest,
      state: addToCartState,
      errorMessage: addToCartError
    } = useAddToCart();
    const { warnToast, clearErrorToasts, successToast } = useToast(ctx);

    const currentPage = ref<number>(parseInt(ctx.root.$route.query.page as string, 10) || 1);
    const q = ref<string>((ctx.root.$route.query.q as string) || '');

    const courses = computed(() =>
      listCoursesResponse.value.courses.map((c) => ({
        ...c,
        // hide participantcount if course has registration for lessons
        // this should be fixed in API, #368
        participantcount: includes(HellewiCourseStatus.RegistrationToLessons, c.statuses)
          ? undefined
          : c.participantcount
      }))
    );
    const courseCount = computed(() => listCoursesResponse.value.count);
    const isLoading = stateIsLoading(listCoursesState);

    const courseListParams = computed(() => ({
      q: q.value,
      page: currentPage.value,
      limit: COURSES_ON_PAGE,
      sort: props.sort ? [props.sort.field] : undefined,
      sortdir: props.sort ? props.sort.dir : undefined
    }));

    const addToCart = (id: string) => {
      addToCartRequest([{ id }]);
    };

    const changePage = () => {
      const element = document.getElementById('mainContent');

      if (element) {
        setTimeout(() => {
          const pos = element.getBoundingClientRect().top + window.pageYOffset - 80;
          window.scrollTo({ top: pos, behavior: 'smooth' });
        }, 100);
      }

      router.push({
        name: 'home',
        query: {
          q: q.value,
          page: currentPage.value.toString()
        }
      });
    };

    onMounted(() => {
      listCourses(courseListParams.value);
    });

    watch(
      () => ctx.root.$route,
      () => {
        q.value = (ctx.root.$route.query.q as string) || '';
        currentPage.value = parseInt(ctx.root.$route.query.page as string, 10) || 1;
      }
    );

    watch(courseListParams, () => {
      listCourses(courseListParams.value);
    });

    watch(stateHasError(listCoursesState), (now, before) => {
      if (now) {
        warnToast('course.list.error');
      } else if (before) {
        clearErrorToasts();
      }
    });

    watch(addToCartState, () => {
      if (stateHasError(addToCartState).value) {
        if (addToCartError?.value === 'Course too many times in cart') {
          warnToast('cart.add.error.toomany');
        } else {
          warnToast('cart.add.error.default');
        }

        return;
      }

      if (stateIsSuccess(addToCartState).value && cartStatus.value) {
        successToast('cart.add.success');
        clearErrorToasts();
      }
    });

    return {
      COURSES_ON_PAGE,
      courses,
      changePage,
      courseCount,
      addToCart,
      isLoading,
      currentPage
    };
  }
});
</script>
