import { memoize } from 'lodash/fp';
import { ref } from '@vue/composition-api';
import {
  HellewiCartItemId,
  CartApi,
  CartApiInterface,
  Configuration,
  DeleteCartItemRequest,
  HellewiCartItem,
  HellewiCartStatus
} from '../api';
import {
  Api,
  ApiEndPointWithSetter,
  ApiEndpoint,
  ApiEndpointInitialization,
  RequestState
} from '../utils/api-utils';

interface HellewiCartStatusExtended extends HellewiCartStatus {
  manuallyCleared?: boolean;
}

export const useCartApi: Api<CartApiInterface> = memoize(() => {
  const api = ref<CartApiInterface | undefined>(undefined);

  const changeConfiguration = (configuration: Configuration) => {
    api.value = new CartApi(configuration);
  };

  return {
    api,
    changeConfiguration
  };
});

export const useCartItems: ApiEndPointWithSetter<void, HellewiCartItem[] | undefined> = memoize(
  () => {
    const initial = undefined;
    const { api } = useCartApi();
    const state = ref<RequestState>(RequestState.Uninitialized);
    const response = ref<HellewiCartItem[] | undefined>(initial);

    ApiEndpointInitialization(api, state, response, initial);

    const setResponse = (cartItems: HellewiCartItem[] | undefined) => {
      response.value = cartItems;
    };

    const execute = async () => {
      if (
        !api.value ||
        state.value === RequestState.Uninitialized ||
        // request already ongoing, don't start a new one
        state.value === RequestState.Loading
      ) {
        return;
      }

      try {
        state.value = RequestState.Loading;
        response.value = await api.value.listCartItems();
        state.value = RequestState.Success;
      } catch {
        response.value = initial;
        state.value = RequestState.Error;
      }
    };

    return {
      initial,
      state,
      response,
      execute,
      setResponse
    };
  }
);

export const useCartStatus: ApiEndPointWithSetter<
  void,
  HellewiCartStatusExtended | undefined
> = memoize(() => {
  const initial = undefined;
  const { api } = useCartApi();
  const state = ref<RequestState>(RequestState.Uninitialized);
  const response = ref<HellewiCartStatusExtended | undefined>(initial);

  ApiEndpointInitialization(api, state, response, initial);

  const execute = async () => {
    if (
      !api.value ||
      state.value === RequestState.Uninitialized ||
      state.value === RequestState.Loading
    ) {
      return;
    }

    try {
      state.value = RequestState.Loading;
      const res = await api.value.getCartStatus();
      response.value = { ...res, manuallyCleared: false };
      state.value = RequestState.Success;
    } catch {
      response.value = initial;
      state.value = RequestState.Error;
    }
  };

  const setResponse = (res: HellewiCartStatusExtended | undefined) => {
    response.value = res;
    state.value = RequestState.Success;
  };

  return {
    initial,
    state,
    response,
    setResponse,
    execute
  };
});

export const useAddToCart: ApiEndpoint<
  HellewiCartItemId[],
  HellewiCartStatusExtended | undefined
> = memoize(() => {
  const initial = undefined;
  const { api } = useCartApi();
  const state = ref<RequestState>(RequestState.Uninitialized);
  const { response } = useCartStatus();
  const errorMessage = ref<string | undefined>();

  ApiEndpointInitialization(api, state, response, response.value);

  const execute = async (req: HellewiCartItemId[]) => {
    if (
      !api.value ||
      state.value === RequestState.Uninitialized ||
      state.value === RequestState.Loading
    ) {
      return;
    }

    try {
      state.value = RequestState.Loading;
      response.value = await api.value.addCartItem({ hellewiCartItemId: req });
      errorMessage.value = undefined;
      state.value = RequestState.Success;
    } catch (err) {
      if (typeof err === 'object') {
        // eslint-disable-next-line @typescript-eslint/no-explicit-any
        const e = err as any;
        const res = await e.json();
        errorMessage.value = res.message;
      }

      state.value = RequestState.Error;
    }
  };

  return {
    initial,
    state,
    response,
    errorMessage,
    execute
  };
});

export const useDeleteFromCart: ApiEndpoint<
  DeleteCartItemRequest,
  HellewiCartStatusExtended | undefined
> = memoize(() => {
  const initial = undefined;
  const { api } = useCartApi();
  const state = ref<RequestState>(RequestState.Uninitialized);
  const { response } = useCartStatus();

  ApiEndpointInitialization(api, state, response, response.value);

  const execute = async (req: DeleteCartItemRequest) => {
    if (
      !api.value ||
      state.value === RequestState.Uninitialized ||
      state.value === RequestState.Loading
    ) {
      return;
    }

    try {
      state.value = RequestState.Loading;
      const res = await api.value.deleteCartItem(req);
      response.value = { ...res, manuallyCleared: true };
      state.value = RequestState.Success;
    } catch {
      state.value = RequestState.Error;
    }
  };

  return {
    initial,
    state,
    response,
    execute
  };
});
