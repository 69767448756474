import { render, staticRenderFns } from "./CatalogFilters.vue?vue&type=template&id=0cf8edb2&scoped=true"
import script from "./CatalogFilters.vue?vue&type=script&lang=ts"
export * from "./CatalogFilters.vue?vue&type=script&lang=ts"
import style0 from "./CatalogFilters.vue?vue&type=style&index=0&id=0cf8edb2&prod&lang=scss&scoped=true"
import style1 from "./CatalogFilters.vue?vue&type=style&index=1&id=0cf8edb2&prod&global=true&lang=css"


/* normalize component */
import normalizer from "!../../../node_modules/@vue/vue-loader-v15/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "0cf8edb2",
  null
  
)

export default component.exports