<style lang="scss">
main {
  min-height: 45vh;
}

.errorContainer {
  margin: 2rem;
}

#app > div {
  > section,
  button,
  input {
    font-family: var(--brandfont);
  }
  h1,
  h2 {
    font-family: var(--brandheaderfont);
  }
  .button.is-primary {
    background-color: var(--brandcolor);
    &:hover {
      background-color: var(--brandcolor);
      filter: brightness(90%);
    }
    &.is-outlined {
      background-color: transparent;
      border-color: var(--brandcolor);
      color: var(--brandcolor);
      &:hover {
        background-color: var(--brandcolor);
        color: #fff;
      }
    }
  }

  .button.is-primary[disabled],
  fieldset[disabled] .button.is-primary {
    background-color: var(--brandcolor);
    filter: brightness(90%);
  }
  .button.is-info {
    background-color: var(--brandcolor);
  }
}
</style>

<template>
  <div
    :style="{
      '--brandcolor': brand ? brand.color : '',
      '--brandcomplementarycolor': brand ? brand.complementarycolor : '',
      '--brandfont': brand ? brand.font : '',
      '--brandheaderfont': brand ? brand.headerfont : ''
    }"
  >
    <section v-if="i18nIsLoaded && !errorMsg">
      <Header />
      <Callouts />

      <div class="container">
        <main>
          <router-view />
        </main>
      </div>

      <Footer />
    </section>

    <main v-if="errorMsg" class="errorContainer">
      <div class="card">
        <div class="card-content">
          <h1 class="title">{{ $t('error.title') }}</h1>
          <p>{{ $t(errorMsg) }}</p>
        </div>
      </div>
    </main>
  </div>
</template>

<script lang="ts">
// eslint-disable-next-line @typescript-eslint/no-explicit-any
declare const fbq: any;

import {
  computed,
  defineComponent,
  onBeforeMount,
  onBeforeUnmount,
  onMounted,
  ref,
  watch
} from '@vue/composition-api';
import { Configuration } from '../api';
import { useBrandApi, useGetBrand } from '../hooks/useBrandApi';
import { useCalloutsApi } from '../hooks/useCalloutsApi';
import { useCartApi } from '../hooks/useCartApi';
import { useCatalogApi } from '../hooks/useCatalogApi';
import { useCourseApi } from '../hooks/useCourseApi';
import { useExternalApi } from '../hooks/useExternalApi';
import { usePaymentApi } from '../hooks/usePaymentApi';
import { useRegistrationApi } from '../hooks/useRegistrationApi';
import { useSurveyApi } from '../hooks/useSurveyApi';
import Header from './Header.vue';
import Callouts from './Callouts.vue';
import Footer from './Footer.vue';
import router from '../router';
import { stateHasError } from '../utils/api-utils';
import HttpStatusCode from '../utils/http-status-codes';
import VueGtm from '@gtm-support/vue2-gtm';
import Vue from 'vue';
import VueCookieBot from '@ambitiondev/vue-cookiebot-plugin';
import { setupMetaPixel } from '../utils/meta-pixel';

export default defineComponent({
  components: {
    Header,
    Callouts,
    Footer
  },
  props: {
    tenant: {
      type: String,
      required: true
    },
    language: {
      type: String,
      required: true
    }
  },
  i18n: {
    messages: {
      en: {
        'error.title': 'Error',
        'error.general': 'There was an error processing your request.',
        'error.tenantnotfound':
          'Tenant not found, please check the address you provided is correct.'
      },
      fi: {
        'error.title': 'Virhe',
        'error.general': 'Sivua ladattaessa tapahtui virhe.',
        'error.tenantnotfound': 'Haltijaa ei löytynyt, varmista että antamasi osoite on oikein.'
      }
      // sv: {}
    }
  },
  setup: (props, { root }) => {
    const tenant = props.tenant;

    const { changeConfiguration: changeConfigurationBrandApi } = useBrandApi();
    const { changeConfiguration: changeConfigurationCalloutsApi } = useCalloutsApi();
    const { changeConfiguration: changeConfigurationCartApi } = useCartApi();
    const { changeConfiguration: changeConfigurationCatalogApi } = useCatalogApi();
    const { changeConfiguration: changeConfigurationCourseApi } = useCourseApi();
    const { changeConfiguration: changeConfigurationExternalApi } = useExternalApi();
    const { changeConfiguration: changeConfigurationSurveyApi } = useSurveyApi();
    const { changeConfiguration: changeConfigurationPaymentApi } = usePaymentApi();
    const { changeConfiguration: changeConfigurationRegistrationApi } = useRegistrationApi();
    const {
      execute: getBrand,
      state: getBrandState,
      status: getBrandStatus,
      response: brand
    } = useGetBrand();

    const errorMsg = ref<string | undefined>();
    const i18nIsLoaded = ref<boolean>(false);

    const apiConfiguration = computed(() => {
      const proto = location.protocol;
      const host = location.hostname;
      const port = location.port
        ? location.port === '8080' // vue-devbuild => use dev-backend
          ? ':9031'
          : `:${location.port}`
        : '';
      const basePath = `${proto}//${host}${port}/v1/${tenant}/${props.language}`;
      const credentials = location.port === '8080' ? 'include' : undefined;

      return new Configuration({ basePath, credentials });
    });

    const setupCookieBot = async () => {
      if (brand.value && brand.value.cookiebot) {
        Vue.use(VueCookieBot, {
          cookieBotID: brand.value.cookiebot,
          blockingMode: 'auto',
          defaultLocale: root.$i18n?.locale || 'fi'
        });
      }
    };

    const setupGtm = () => {
      // Google tag manager
      if (brand.value && brand.value.gtag) {
        Vue.use(VueGtm, {
          id: brand.value.gtag,
          defer: true,
          compatibility: false,
          nonce: '2726c7f26c',
          enabled: false,
          debug: false,
          loadScript: true,
          vueRouter: router,
          trackOnNextTick: false
        });
      }
      if (root.$gtm) {
        root.$gtm.enable();
      }
    };

    const triggerCookieBot = () => {
      // eslint-disable-next-line @typescript-eslint/no-explicit-any
      if ((root as any).$cookiebot) {
        // eslint-disable-next-line @typescript-eslint/no-explicit-any
        (root as any).$cookiebot.consentBanner();
      }
    };

    const changeConfigurations = (configuration: Configuration) => {
      changeConfigurationBrandApi(configuration);
      changeConfigurationCalloutsApi(configuration);
      changeConfigurationCartApi(configuration);
      changeConfigurationCatalogApi(configuration);
      changeConfigurationCourseApi(configuration);
      changeConfigurationExternalApi(configuration);
      changeConfigurationPaymentApi(configuration);
      changeConfigurationRegistrationApi(configuration);
      changeConfigurationSurveyApi(configuration);
    };
    const setLanguageAndScripts = async () => {
      try {
        const [i18n] = await Promise.all([
          fetch(`${apiConfiguration.value.basePath}/i18n`, { method: 'GET' }),
          getBrand()
        ]);

        setupCookieBot();
        setupGtm();

        root.$i18n.setLocaleMessage(props.language, await i18n.json());
        root.$i18n.locale = props.language;
        i18nIsLoaded.value = true;
      } catch {
        errorMsg.value = 'error.general';
      }
    };

    const updateUserConsent = () => {
      // Define the gtag function if it doesn't exist
      window.dataLayer = window.dataLayer || [];
      function gtag(...args: unknown[]) {
        const dataLayer = window.dataLayer || [];
        dataLayer.push(args);
      }
      // set denied as default for both ad and analytics storage
      gtag('consent', 'default', {
        ad_storage: 'denied',
        analytics_storage: 'denied'
      });

      // Enable ads data redaction by default [optional]
      gtag('set', 'ads_data_redaction', true);

      const consent = true;
      if (consent) {
        // Update the consent to granted
        gtag('consent', 'update', {
          ad_storage: 'granted',
          analytics_storage: 'granted',
          wait_for_update: 500
        });
      }

      // eslint-disable-next-line @typescript-eslint/no-explicit-any
      if (!(window as any).Cookiebot || !root.$gtm) {
        return;
      }

      if (root.$gtm.enabled() !== consent) {
        // Enable or disable Google tag manager according to consent
        root.$gtm.enable(consent);
      }
      /* eslint-disable */
      if (typeof fbq !== 'undefined') {
        // Change Meta Pixel consent
        fbq('consent', consent ? 'grant' : 'revoke');
      } else if (consent && brand.value && brand.value.metapixel) {
        // Setup Meta Pixel
        setupMetaPixel(brand.value.metapixel);
      }
      /* eslint-enable */
    };

    const updateDocumentLanguage = () => {
      document.documentElement.setAttribute('lang', props.language); // Sets the language of the document to English
    };

    onBeforeMount(async () => {
      changeConfigurations(apiConfiguration.value);

      getBrand();
      if (!props.language) {
        router.push({
          path: '/' + props.tenant + '/' + (localStorage.getItem('lang') || 'fi')
        });
      } else {
        // wait for api configuration to be set up before trying to load
        // brand information
        setTimeout(async () => {
          await setLanguageAndScripts();
          triggerCookieBot();
          updateUserConsent();
        }, 1);
      }
    });

    onMounted(() => {
      window.addEventListener('CookiebotOnConsentReady', updateUserConsent);
      updateDocumentLanguage();
    });

    onBeforeUnmount(() => {
      window.removeEventListener('CookiebotOnConsentReady', updateUserConsent);
    });

    watch(apiConfiguration, changeConfigurations);
    watch(stateHasError(getBrandState), (hasError) => {
      if (!hasError) {
        return;
      }
      // redirect to generic not found page when tenant can't be found
      if (getBrandStatus?.value === HttpStatusCode.NOT_FOUND) {
        router.replace({ name: 'NotFoundBrandless' });
      } else {
        errorMsg.value = 'error.general';
      }
    });

    watch(
      () => props.language,
      () => {
        i18nIsLoaded.value = false;
        window.location.reload();
      }
    );

    return {
      apiConfiguration,
      i18nIsLoaded,
      errorMsg,
      brand
    };
  }
});
</script>
