<template>
  <div class="card" id="invoices" v-if="invoices.length > 0">
    <div class="card-content">
      <h1 class="title">{{ $t(titleTranslationKey) }}</h1>
      <b-table
        :data="invoices"
        detailed
        detail-key="referencenumber"
        :show-detail-icon="false"
        class="invoices"
      >
        <b-table-column :label="$t('registration.date')" v-slot="props" width="25%">
          {{ props.row.timestamp | date }}
        </b-table-column>
        <b-table-column :label="$t('registration.referencenumber')" v-slot="props">
          {{ props.row.referencenumber }}
        </b-table-column>
        <b-table-column
          :label="$t('registration.total')"
          v-if="showTotalAmountColumn"
          v-slot="props"
          numeric
        >
          {{ $n(props.row.total.amount / 100, 'currency') }}
        </b-table-column>
        <b-table-column v-if="showPaymentColumn" v-slot="props" numeric>
          <a :href="props.row.paymenturl" v-if="props.row.paymenturl">
            <b-button
              type="is-primary"
              class="addToCartButton"
              icon-left="calendar"
              :aria-label="$t('registration.paymentButton')"
            >
              {{ $t('registration.paymentButton') }}
            </b-button>
          </a>
        </b-table-column>
        <b-table-column v-slot="props" :label="$t('registration.details')" numeric>
          <a @click="props.toggleDetails(props.row)">
            {{ $t('registration.show') }}
            <b-icon
              :icon="
                includes(props.row.referencenumber, props.column.$table.openedDetailed)
                  ? 'chevron-up'
                  : 'chevron-down'
              "
            >
            </b-icon>
          </a>
        </b-table-column>

        <template #detail="props">
          <Invoice :invoice="props.row" />
        </template>
      </b-table>
    </div>
  </div>
</template>

<script lang="ts">
import { defineComponent, PropType } from '@vue/composition-api';
import { includes } from 'lodash/fp';

import { PurchaseInvoiceNumber } from '@/frontend-src/api';

import Invoice from './Invoice.vue';

export default defineComponent({
  components: { Invoice },
  props: {
    invoices: {
      type: Array as PropType<PurchaseInvoiceNumber[]>,
      required: true
    },
    showTotalAmountColumn: {
      type: Boolean,
      required: true
    },
    showPaymentColumn: {
      type: Boolean,
      required: true
    },
    titleTranslationKey: {
      type: String,
      required: true
    }
  },
  setup: () => {
    return { includes };
  }
});
</script>
