<style lang="scss" scoped>
form {
  display: grid;
  grid-template-columns: repeat(2, minmax(0, 1fr));
  gap: 1rem;
  margin-top: 2rem;

  @media (max-width: 600px) {
    grid-template-columns: 1fr;
  }
}

.card {
  border-radius: 13px !important;
  box-shadow: none !important;
  border: 1px solid $lightGrayOutline;
}

#app .card-content {
  padding: 3rem;

  input {
    border-radius: 9px;
  }

  .title {
    font-size: 1.6rem;
  }

  .radio {
    margin-right: 1.5rem;
  }

  .is-horizontal .field-body .field,
  .is-horizontal .field-body {
    flex-grow: unset !important;
  }

  .is-horizontal {
    padding-top: 2rem;
    display: flex;
    justify-content: flex-end;
    direction: rtl;
    align-items: center;

    .field-body {
      padding-top: 0.3rem;
    }
  }

  .is-horizontal .label {
    text-align: left;
  }
}

@media (max-width: 800px) {
  #app .card .card-content {
    padding: 2rem;
  }
}

@media (max-width: 600px) {
  #app .card .card-content {
    padding: 1rem;
  }
}
</style>

<template>
  <div class="card payer-card">
    <div class="card-content">
      <h2 class="title">{{ $t('cart.payer.info') }}</h2>

      <!-- TODO: https://github.com/wildfrost/Ilmoittautuminen/issues/570 -->
      <div
        class="block"
        v-if="
          (required.includes('billingid') || clientpinrequired) &&
            brand &&
            brand.onlycompanybilling === false
        "
      >
        <b-field>
          <b-checkbox v-model="payerFromClient">{{ $t('cart.client.payer') }}</b-checkbox>
        </b-field>
      </div>
      <div class="block" v-if="brand && brand.onlycompanybilling === true">
        <b-field>
          <b-checkbox v-model="companyBilling">{{ $t('cart.payer.companybilling') }} </b-checkbox>
        </b-field>
      </div>
      <!-- TODO: https://github.com/wildfrost/API/issues/316 -->
      <div class="block" v-if="!(brand && brand.onlycompanybilling === true)">
        <b-field>
          <b-radio v-model="payerType" native-value="private" :disabled="payerFromClient">
            {{ $t('cart.payer.private') }}
          </b-radio>
          <b-radio v-model="payerType" native-value="business" :disabled="payerFromClient">
            {{ $t('cart.payer.business') }}
          </b-radio>
        </b-field>
      </div>

      <ValidationObserver
        tag="form"
        class="reg-form"
        ref="observer"
        v-show="!(brand && brand.onlycompanybilling === true && companyBilling === false)"
      >
        <ValidationProvider
          v-for="(field, i) in fields"
          :key="i"
          :rules="{
            required: required.includes(field.name),
            email: field.inputType === 'email',
            pin: (field.label === 'pin' && field.pattern) || '',
            birthday: (field.label === 'birthday' && field.pattern) || ''
          }"
        >
          <div slot-scope="{ errors }">
            <b-field
              :type="{ 'is-danger': errors[0] }"
              :horizontal="field.type === 'boolean'"
              :message="$t(errors[0])"
              :label="$t('fieldlabel.' + field.label)"
              :label-for="field.name + '-' + field.inputType"
              :custom-class="required.includes(field.name) ? 'required' : ''"
            >
              <b-input
                lazy
                v-if="field.type === 'string'"
                v-model.trim="model[field.name]"
                :type="field.inputType ? field.inputType : 'text'"
                :disabled="payerFromClient"
                :id="field.name + '-' + field.inputType"
              />

              <b-select
                v-if="field.type === 'number'"
                v-model.trim="model[field.name]"
                :placeholder="$t('select')"
                :disabled="payerFromClient"
                :id="field.name + '-' + field.inputType"
              >
                <option v-for="option in field.oneOf" :key="option.const" :value="option.const">
                  {{ option.title }}
                </option>
              </b-select>

              <b-checkbox v-if="field.type === 'boolean'" v-model="model[field.name]" />

              <p class="help is-danger" v-if="errors.length === 0 && payerAjvErrors.length > 0">
                {{ getFieldError(field.name) }}
              </p>
            </b-field>
          </div>
        </ValidationProvider>
      </ValidationObserver>
    </div>
  </div>
</template>

<script lang="ts">
import {
  computed,
  defineComponent,
  onBeforeMount,
  PropType,
  ref,
  watch
} from '@vue/composition-api';
import { ValidationProvider, ValidationObserver } from 'vee-validate';
import { filterUndefineds, translate } from '../../utils/misc-utils';
import { AjvError, Client, Payer } from '../../views/Cart.vue';
import { useGetBrand } from '../../hooks/useBrandApi';
import { HellewiTenantType } from '../../api';
import { filter, getOr } from 'lodash/fp';

export default defineComponent({
  components: {
    ValidationObserver,
    ValidationProvider
  },
  props: {
    schema: {
      type: Object,
      required: true
    },
    ajvErrors: {
      type: Array as PropType<AjvError[]>,
      required: false
    },
    validationTrigger: {
      type: Boolean,
      required: false
    },
    client: {
      type: Object as PropType<Client>,
      required: true
    },
    payerFromClientInitial: {
      type: Boolean,
      required: true
    }
  },
  setup: (props, ctx) => {
    const model = ref<Record<string, unknown>>({});
    const payerType = ref<string>('private');
    const required = ref<string[]>([]);
    const clientpinrequired = ref<boolean>(true);
    const fields = ref<Record<string, unknown>[]>([]);
    const observer = ref<InstanceType<typeof ValidationObserver> | null>(null);
    const payerFromClient = ref<boolean>(props.payerFromClientInitial);
    const { response: brand, execute: getBrand } = useGetBrand();
    const companyBilling = ref<boolean>(false);

    const payerAjvErrors = computed<AjvError[]>(
      () => props.ajvErrors?.filter((err) => err.clientid === 'payer') || []
    );

    const getFieldError = (fieldName: string) => {
      const error = payerAjvErrors.value.find((e) => e.field === fieldName);

      if (!error) {
        return '';
      }

      return translate(ctx, `validation.${error.keyword}`);
    };

    const generateFields = () => {
      return filterUndefineds(
        Object.entries(props.schema.properties.payer.properties).map(([key, value]) => {
          let label = key;
          if (
            payerType.value === 'business' ||
            brand.value?.type === HellewiTenantType.SummerSchool
          ) {
            switch (key) {
              case 'firstname':
                if (brand.value?.type === HellewiTenantType.SummerSchool) {
                  label = 'billingfirstname';
                  break;
                } else {
                  return undefined;
                }

              case 'lastname':
                label = 'companyname';
                break;
              case 'billingid':
                label = 'businessid';
                break;
              default:
                break;
            }
          }
          return {
            ...(value as Record<string, unknown>),
            name: key,
            label
          };
        })
      );
    };

    onBeforeMount(() => {
      const clientMinimumRequired = getOr([], '$defs.clientMinimum.required', props.schema);
      const clientRequired = getOr([], '$defs.client.required', props.schema);
      clientpinrequired.value =
        clientMinimumRequired.includes('pin') || clientRequired.includes('pin');
      required.value = props.schema.properties.payer.required;
      fields.value = generateFields();
      getBrand();
    });

    watch(
      () => props.validationTrigger,
      async () => {
        if (!props.validationTrigger || !observer) {
          return;
        }

        // If payer is not required and all fields are empty, we can skip validation
        if (
          !props.schema.required.includes('payer') &&
          filter((v) => v, Object.values(model.value)).length === 0
        ) {
          ctx.emit('validate', true);
        } else {
          const val = await observer.value?.validate();
          ctx.emit('validate', val);
        }
      }
    );

    watch(
      () => props.payerFromClientInitial,
      (pfc) => (payerFromClient.value = pfc)
    );

    watch(model, (value) => {
      ctx.emit('update-payer', value);
    });

    watch(payerType, () => {
      fields.value = generateFields();
    });

    const updatePayerFromClient = (client: Client) => {
      if (!(props.schema.required && props.schema.required.includes('payer'))) {
        return;
      }
      const onlycompanybilling = brand.value?.onlycompanybilling === true;
      if (
        !(onlycompanybilling === true && companyBilling.value === false) &&
        (!props.schema.properties.payer?.properties || !payerFromClient.value)
      ) {
        return;
      }
      payerType.value = 'private';
      // otherwise parse payer's fields from the first client
      const payerFields = Object.keys(props.schema.properties.payer.properties);
      model.value = Object.entries(client.fields).reduce<Payer>((acc, [fieldName, value]) => {
        // Swap back the 'pin' field to 'billingid' for the schema
        // The field name is changed during field generation
        // in RegistrationForm to prevent asking the same info twice
        if (fieldName === 'pin') {
          acc.billingid = value;
        }

        if (payerFields.includes(fieldName)) {
          acc[fieldName] = value;
        }

        return acc;
      }, {});
    };

    watch(
      () => props.client,
      (client) => updatePayerFromClient(client),
      { deep: true }
    );

    watch(payerFromClient, (value) => {
      updatePayerFromClient(props.client);
      ctx.emit('update-payer-from-client', value);
    });

    watch(companyBilling, (value) => {
      if (value === true) {
        model.value = {};
      } else {
        updatePayerFromClient(props.client);
        // ctx.emit('update-payer-from-client', value);
      }
    });

    return {
      fields,
      model,
      required,
      observer,
      payerFromClient,
      payerType,
      payerAjvErrors,
      getFieldError,
      brand,
      companyBilling,
      clientpinrequired
    };
  }
});
</script>
