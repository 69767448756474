<style lang="scss" scoped>
.download-button {
  margin: 1rem 0.5rem 0.5rem 0;
}
</style>
<template>
  <b-button
    class="download-button"
    tag="a"
    :href="file.url"
    type="is-light"
    icon-left="download"
    :aria-label="filename"
    download
  >
    {{ fileName }}
  </b-button>
</template>

<script lang="ts">
import { computed, defineComponent, PropType } from '@vue/composition-api';
import { HellewiFile } from '../../api';

export default defineComponent({
  props: {
    file: {
      type: Object as PropType<HellewiFile>,
      required: true
    }
  },
  setup(props) {
    const fileName = computed(() => {
      const rawFileName = props.file.url
        .split('/')
        .slice(-1)
        .pop();
      return rawFileName ? decodeURIComponent(rawFileName) : '';
    });
    return {
      fileName
    };
  }
});
</script>
