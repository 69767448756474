<style lang="scss" scoped>
.breadcrumbs {
  font-size: 0.75rem;
  .icon {
    margin-right: 0.2rem;
  }
  a {
    margin-right: 0.2rem;
  }
  @media (min-width: $mobile-small) {
    font-size: 0.8rem;
  }
  @media (min-width: $tablet) {
    font-size: 0.9rem;
    .icon {
      margin-right: 0.4rem;
    }
    a {
      margin-right: 0.4rem;
    }
  }
}
</style>

<template>
  <div class="breadcrumbs">
    <span v-if="department">
      <router-link :to="{ name: 'home', query: { q: department.keywords[0] } }">
        {{ department.name }}
      </router-link>
      <b-icon icon="chevron-right" size="is-small" v-if="category || subject" />
    </span>
    <span v-if="category">
      <router-link :to="{ name: 'home', query: { q: category.keywords[0] } }"
        >{{ category.name }}
      </router-link>
      <b-icon icon="chevron-right" size="is-small" v-if="subject" />
    </span>
    <span v-if="subject">
      <router-link
        :to="{
          name: 'home',
          query: {
            // Use both keywords for search: e.g. 'subject:8 subject:3/8'
            // It depends on store settings which are properly interpreted as tags
            // so better to use both
            q:
              subject.keywords.length > 1
                ? `${subject.keywords[0]} ${subject.keywords[1]}`
                : subject.keywords[0]
          }
        }"
        >{{ subject.name }}
      </router-link>
    </span>
  </div>
</template>

<script lang="ts">
import { HellewiCatalogItem, HellewiCourse } from '@/frontend-src/api';
import { defineComponent, PropType } from '@vue/composition-api';

// Custom type where keywords is not null
type HellewiCatalogItemWithKeywords = Omit<HellewiCatalogItem, 'keywords'> & { keywords: string[] };

export default defineComponent({
  props: {
    course: {
      type: Object as PropType<HellewiCourse>,
      required: true
    }
  },
  setup: (props) => {
    const { department, category, subject } = props.course;

    const hasKeywords = (item: HellewiCatalogItem | undefined) =>
      item?.keywords && item?.keywords.length > 0;

    return {
      department: hasKeywords(department)
        ? ((department as any) as HellewiCatalogItemWithKeywords) // eslint-disable-line @typescript-eslint/no-explicit-any
        : null,
      category: hasKeywords(category)
        ? ((category as any) as HellewiCatalogItemWithKeywords) // eslint-disable-line @typescript-eslint/no-explicit-any
        : null,
      // eslint-disable-next-line @typescript-eslint/no-explicit-any
      subject: hasKeywords(subject) ? ((subject as any) as HellewiCatalogItemWithKeywords) : null
    };
  }
});
</script>
