<style lang="scss" scoped>
.paymenttext {
  word-wrap: break-word;
}

.paymentmethods {
  display: grid;
  grid-template-columns: repeat(auto-fill, minmax(min(15rem, 100%), 1fr));
  grid-gap: 1rem;
  margin-top: 2rem;

  .button {
    display: flex;
    flex-direction: column;
    height: 13rem;
    width: 100%;
    padding: 1rem;
    text-align: center;
    text-overflow: ellipsis;
    white-space: pre-wrap;
    > span {
      height: 13rem;
      width: 15rem;
      white-space: initial;
    }
  }
}

.logo {
  display: flex;
  background-position: center;
  background-size: contain;
  background-repeat: no-repeat;
  height: 100%;
  width: 100%;
  margin-bottom: 1rem;
}

.payment-loading {
  position: relative;
  top: 0;
  left: 0;
  height: 100%;
  width: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  border-radius: 6px;

  .loader {
    @include loader;
    height: 80px;
    width: 80px;
    border-width: 8px;
  }
}
</style>

<template>
  <div class="card">
    <div class="card-content">
      <h1 class="title">{{ $t('registration.paymentTitle') }}</h1>

      <div class="paymenttext" v-dompurify-html="paymentText" />

      <section class="paymentmethods">
        <div v-for="(methodOfPayment, i) in methodsOfPayment" :key="i">
          <form
            v-if="methodOfPayment.fields"
            :action="methodOfPayment.action"
            id="paymentform"
            method="POST"
          >
            <input
              v-for="(field, idx) in methodOfPayment.fields"
              :key="idx"
              :value="field.value"
              :name="field.name"
              type="hidden"
            />

            <button type="submit" class="button">
              <span
                class="logo"
                :style="{ backgroundImage: `url('${settings[methodOfPayment.name].logo}')` }"
              />
              {{ $t('payment.methodOfPayment.' + methodOfPayment.infotext) }}
            </button>
          </form>

          <a
            v-if="methodOfPayment.url && !methodOfPayment.requestdata"
            :href="methodOfPayment.url"
            class="button"
            ><span
              class="logo"
              :style="{ backgroundImage: `url('${settings[methodOfPayment.name].logo}')` }"
            />
            {{ $t('payment.methodOfPayment.' + methodOfPayment.infotext) }}
          </a>
          <div
            v-if="methodOfPayment.requestdata"
            class="button"
            @click="handlePaymentRequest(methodOfPayment)"
          >
            <!-- <div class="payment-loading"></div> -->
            <div class="payment-loading" v-if="getPaymentTokenState === 'LOADING'">
              <div class="loader is-loading"></div>
            </div>
            <span
              class="logo"
              :style="{ backgroundImage: `url('${settings[methodOfPayment.name].logo}')` }"
            />
            {{ $t('payment.methodOfPayment.' + methodOfPayment.infotext) }}
          </div>
        </div>
      </section>
    </div>
  </div>
</template>

<script lang="ts">
import { defineComponent, PropType, watch } from '@vue/composition-api';

import paytrailLogo from '../../../frontend-assets/img/paytrail.jpg';
import cpuLogo from '../../../frontend-assets/img/ceepos.png';
import smartumLogo from '../../../frontend-assets/img/SmartumPayLogoBLACK.png';
import epassiLogo from '../../../frontend-assets/img/epassi.png';
import turkuLogo from '../../../frontend-assets/img/turku.png';
import vismapayLogo from '../../../frontend-assets/img/vismapay.png';
import { MethodOfPayment, PaymentServiceName } from '../../../frontend-src/api';

import { useGetPaymentToken } from '../../hooks/usePaymentApi';
import { RequestState, useToast } from '../../utils/api-utils';

const settings: { [key in PaymentServiceName]: { logo: string } } = {
  [PaymentServiceName.Paytrail]: {
    logo: paytrailLogo
  },
  [PaymentServiceName.Cpu]: {
    logo: cpuLogo
  },
  [PaymentServiceName.Smartum]: {
    logo: smartumLogo
  },
  [PaymentServiceName.KulttuuriPassi]: {
    logo: epassiLogo
  },
  [PaymentServiceName.SporttiPassi]: {
    logo: epassiLogo
  },
  [PaymentServiceName.PaytrailApi]: {
    logo: paytrailLogo
  },
  [PaymentServiceName.Turku]: {
    logo: turkuLogo
  },
  [PaymentServiceName.Bambora]: {
    // Vismapay
    logo: vismapayLogo
  }
};

export default defineComponent({
  props: {
    methodsOfPayment: {
      type: Array as PropType<MethodOfPayment[]>,
      required: true
    },
    paymentText: {
      type: String,
      required: true
    }
  },
  setup: (props, ctx) => {
    const {
      execute: getPaymentToken,
      state: getPaymentTokenState,
      response: getPaymentTokenResponse,
      errorMessage: getPaymentTokenErrorMessage
    } = useGetPaymentToken();
    const { warnToast } = useToast(ctx);

    watch(getPaymentTokenState, (state) => {
      if (state === RequestState.Error) {
        if (getPaymentTokenErrorMessage?.value === 'Paymentservice request timeout') {
          warnToast('payment.errors.paymentApiTimeout', true, false);
        } else {
          warnToast('payment.errors.paymentApiError', true, false);
        }
      }
    });
    const handlePaymentRequest = async (methodOfPayment: MethodOfPayment) => {
      if (!methodOfPayment.requestdata) {
        return;
      }
      await getPaymentToken({ requestBody: methodOfPayment });
      const url = getPaymentTokenResponse?.value?.url;
      if (!url) {
        return;
      }
      location.href = url;
    };
    return { settings, handlePaymentRequest, getPaymentTokenState };
  }
});
</script>
