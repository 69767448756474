<!-- eslint-disable vue/multi-word-component-names -->
<style lang="scss">
// these affect the child components' styles, so cannot be scoped
.registration {
  @media (min-width: 1024px) {
    .twoColumn .table .th-wrap {
      flex-direction: row !important;
    }
  }

  .products,
  .invoices {
    > .table-wrapper > table > thead > tr > th {
      font-weight: 400;
      color: $lightGray;
      font-size: 1.2rem;
    }
    .product-cell {
      padding-top: 1.5rem;
      padding-bottom: 1.5rem;
    }
  }

  @media (max-width: 1023px) {
    td::before {
      font-weight: 400;
      color: $lightGray;
    }
  }
}
</style>

<template>
  <div v-if="registration" class="registration">
    <RegistrationCard
      :registration="registration"
      :showRegistrationPrices="showRegistrationPrices"
      :titleTranslationKey="titleTranslationKey"
      :showInvoices="showInvoices"
    />

    <InvoiceCard
      v-if="showInvoices && openInvoices.length > 0"
      :invoices="openInvoices"
      :titleTranslationKey="'registration.openInvoices'"
      :showTotalAmountColumn="true"
      :showPaymentColumn="showPaymentColumn"
    />

    <InvoiceCard
      v-if="showInvoices && closedInvoices.length > 0"
      :invoices="closedInvoices"
      :titleTranslationKey="'registration.closedInvoices'"
      :showTotalAmountColumn="false"
      :showPaymentColumn="false"
    />

    <PaymentCard
      v-if="registration.methodsofpayment && registration.methodsofpayment.length > 0"
      :methodsOfPayment="registration.methodsofpayment"
      :paymentText="registration.paymenttext"
    />
  </div>
</template>

<script lang="ts">
import { computed, defineComponent, PropType } from '@vue/composition-api';
import { includes } from 'lodash/fp';

import {
  HellewiMyRegistrationsResponse,
  HellewiGetRegistrationResponse,
  HellewiPostRegistrationResponse,
  PurchaseInvoiceNumber
} from '../api';

import InvoiceCard from '../components/registration/InvoiceCard.vue';
import PaymentCard from '../components/registration/PaymentCard.vue';
import RegistrationCard from '../components/registration/RegistrationCard.vue';

export default defineComponent({
  components: { InvoiceCard, PaymentCard, RegistrationCard },
  props: {
    registration: {
      type: Object as PropType<
        | HellewiMyRegistrationsResponse
        | HellewiGetRegistrationResponse
        | HellewiPostRegistrationResponse
      >,
      required: true
    },
    titleTranslationKey: {
      type: String,
      required: true
    },
    showRegistrationPrices: {
      type: Boolean,
      required: true
    },
    showInvoices: {
      type: Boolean,
      required: true
    },
    showPaymentColumn: {
      type: Boolean,
      required: true
    }
  },
  setup: (props) => {
    const closedInvoiceFilter = (invoice: PurchaseInvoiceNumber): boolean =>
      invoice.total.amount === 0 || invoice.senttoaccounting;

    const openInvoices = computed<PurchaseInvoiceNumber[]>(() =>
      props.registration.invoices.filter((i) => !closedInvoiceFilter(i))
    );

    const closedInvoices = computed<PurchaseInvoiceNumber[]>(() =>
      props.registration.invoices.filter(closedInvoiceFilter)
    );

    return {
      closedInvoices,
      includes,
      openInvoices
    };
  }
});
</script>
