<style lang="scss" scoped>
.social {
  display: inline-block;
  margin-bottom: 0.5rem;
}

.social-child {
  float: left;
  padding-right: 20px;
}
</style>

<template>
  <div class="social">
    <div class="social-child">
      <a
        class="twitter-share-button"
        :href="`https://twitter.com/intent/tweet?url=${fullpath}`"
        target="_blank"
      >
        <svg
          xmlns="http://www.w3.org/2000/svg"
          x="0px"
          y="0px"
          width="22"
          height="22"
          viewBox="0,0,256,256"
          style="fill:#000000;"
        >
          <g
            fill="#3f759e"
            fill-rule="nonzero"
            stroke="none"
            stroke-width="1"
            stroke-linecap="butt"
            stroke-linejoin="miter"
            stroke-miterlimit="10"
            stroke-dasharray=""
            stroke-dashoffset="0"
            font-family="none"
            font-weight="none"
            font-size="none"
            text-anchor="none"
            style="mix-blend-mode: normal"
          >
            <g transform="scale(10.66667,10.66667)">
              <path
                d="M2.86719,3l6.86914,9.81836l-7.00195,8.18164h2.64648l5.53906,-6.49023l4.54102,6.49023h5.91016l-7.19727,-10.30273l6.57031,-7.69727h-2.60547l-5.14258,6.00977l-4.19727,-6.00977z"
              ></path>
            </g>
          </g>
        </svg>
        {{ $t('share') }}
      </a>
    </div>
    <div class="social-child">
      <a
        class="fb-share-button"
        :href="`https://www.facebook.com/share.php?u=${fullpath}`"
        target="_blank"
      >
        <b-icon icon="facebook" />{{ $t('share') }}
      </a>
    </div>
  </div>
</template>

<script>
import { defineComponent } from '@vue/composition-api';

export default defineComponent({
  setup() {
    const fullpath = window.location;
    return { fullpath };
  }
});
</script>
