<!-- eslint-disable vue/multi-word-component-names -->
<!-- '>>>' syntax doesn't work with lang="scss" -->
<style>
/* .sd-root-modern {
  background-color: white;
} */

.sd-root-modern {
  border-radius: 16px;
  border: 0px black solid;
}
</style>

<template>
  <div class="card">
    <div class="card-content">
      <div v-if="courseinfo">
        <h1 class="title">{{ courseinfo.name }}</h1>
        <ul>
          <li v-if="courseinfo.teacher">
            {{ $t('courseInfoDl.teacher') }}: {{ courseinfo.teacher }}
          </li>
          <li v-if="courseinfo.location">
            {{ $t('courseInfoDl.location') }}: {{ courseinfo.location }}
          </li>
          <li v-if="courseinfo.date">{{ $t('courseInfoDl.period') }}: {{ courseinfo.date }}</li>
        </ul>
      </div>
      <Survey :survey="survey" />
    </div>
  </div>
</template>

<script lang="ts">
import 'survey-core/defaultV2.min.css';

import { defineComponent, onBeforeMount, ref, watch } from '@vue/composition-api';
import { stateIsLoading, stateIsSuccess } from '../utils/api-utils';
import { translate } from '../utils/misc-utils';
import useTitle from '../hooks/useTitle';

import { Model } from 'survey-core/survey.core';
import 'survey-core/i18n/finnish';
import 'survey-core/i18n/swedish';

import { Survey } from 'survey-vue-ui';
import { useGetSurvey, usePostSurvey } from '../hooks/useSurveyApi';
import router from '../router';

import VueRouter from 'vue-router';

const getSignParamsFromRoute = (vueRouter: VueRouter) => {
  const { id } = router.currentRoute.params;
  const {
    cid: cidRaw,
    reqid: reqidRaw,
    expiry: expiryRaw,
    hmac: hmacRaw
  } = vueRouter.currentRoute.query;
  const cid = cidRaw as string;
  const reqid = reqidRaw as string;
  const expiry = new Date(expiryRaw as string);
  const hmac = hmacRaw as string;

  return { id, cid, reqid, expiry, hmac };
};

export default defineComponent({
  components: { Survey: Survey as never },
  setup: (props, ctx) => {
    const {
      response: surveyJsonResp,
      execute: getSurvey,
      state: getSurveyRequestState
    } = useGetSurvey();

    const { execute: postSurvey } = usePostSurvey();
    const { setTitle } = useTitle();

    const isLoading = stateIsLoading(getSurveyRequestState);
    const isSuccess = stateIsSuccess(getSurveyRequestState);

    const parseQueryAndGetSurvey = () => {
      const { id } = router.currentRoute.params;
      const {
        cid: cidRaw,
        reqid: reqidRaw,
        expiry: expiryRaw,
        hmac: hmacRaw
      } = router.currentRoute.query;
      const cid = cidRaw as string;
      const reqid = reqidRaw as string;
      const expiry = new Date(expiryRaw as string);
      const hmac = hmacRaw as string;

      if (!id || !cid || !reqid || !expiry || !hmac) {
        return;
      }
      getSurvey({ id, cid, reqid, expiry, hmac });
    };

    onBeforeMount(() => {
      parseQueryAndGetSurvey();
      setTitle(translate(ctx, 'nav.survey'));
    });

    watch(
      () => ctx.root.$i18n.locale,
      () => {
        parseQueryAndGetSurvey();
      }
    );

    watch(
      () => surveyJsonResp.value,
      () => {
        const jsonvalue = JSON.parse(surveyJsonResp.value as string);
        courseinfo.value = jsonvalue.courseinfo;
        survey.fromJSON(jsonvalue);
        survey.locale = ctx.root.$i18n.locale;
      }
    );

    let courseinfo = ref<{ name?: string; date?: string; teacher?: string; location?: string }>({});

    const survey = new Model();
    survey.locale = ctx.root.$i18n.locale;
    survey.onComplete.add(async (result) => {
      const signParams = getSignParamsFromRoute(router);

      if (
        !signParams.id ||
        !signParams.cid ||
        !signParams.reqid ||
        !signParams.expiry ||
        !signParams.hmac
      ) {
        return;
      }
      await postSurvey({ ...signParams, requestBody: { json: result.data } });
    });

    return { isLoading, isSuccess, survey, courseinfo };
  }
});
</script>
