<style lang="scss">
.search-input {
  width: 100%;

  > span.icon.is-right {
    width: 32px;
  }
}
</style>

<template>
  <form @submit.prevent="search" class="search-input">
    <b-field>
      <b-input
        :id="id"
        class="search-input"
        type="search"
        v-model="inputValue"
        :placeholder="placeholder"
        :icon-right="iconRight ? 'magnify' : ''"
        :icon-right-clickable="true"
        @input="updateValue"
        @icon-right-click="iconRight ? search() : null"
        @keyup.enter="search"
      />
    </b-field>
  </form>
</template>

<script lang="ts">
import useSearchParams from '../../hooks/useSearchParams';
import { defineComponent, ref, watch } from '@vue/composition-api';
import { debounce } from 'lodash/fp';

export default defineComponent({
  props: {
    placeholder: {
      type: String,
      required: true
    },
    iconRight: {
      type: Boolean,
      required: false,
      default: false
    },
    id: {
      type: String,
      required: false
    }
  },
  setup: (props, ctx) => {
    const { keyword } = useSearchParams(ctx);
    const inputValue = ref<string | undefined>(keyword.value);

    const search = () => {
      ctx.emit('search', inputValue.value);
    };

    const debouncedSearch = debounce(500, search);

    const updateValue = () => {
      ctx.emit('updatevalue', inputValue.value);
      debouncedSearch();
    };

    watch(keyword, () => {
      inputValue.value = keyword.value;
    });

    return { inputValue, updateValue, search };
  }
});
</script>
