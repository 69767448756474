import { isEqual, memoize } from 'lodash/fp';
import PCancelable from 'p-cancelable';
import { ref } from '@vue/composition-api';
import {
  Configuration,
  GetRegistrationFormRequest,
  GetRegistrationRequest,
  GetMyRegistrationsRequest,
  HellewiGetRegistrationResponse,
  HellewiPostRegistrationResponse,
  HellewiMyRegistrationsResponse,
  PostMyRegistrationsLoginLinkRequest,
  RegistrationApi,
  RegistrationApiInterface,
  RegistrationPriceNumber,
  PostHellewiRegistrationRequest,
  HellewiCourseProduct
} from '../api';
import { Api, ApiEndpoint, ApiEndpointInitialization, RequestState } from '../utils/api-utils';
import HttpStatusCode from '../utils/http-status-codes';

export const useRegistrationApi: Api<RegistrationApiInterface> = memoize(() => {
  const api = ref<RegistrationApiInterface | undefined>(undefined);

  const changeConfiguration = (configuration: Configuration) => {
    api.value = new RegistrationApi(configuration);
  };

  return {
    api,
    changeConfiguration
  };
});

export const useGetRegistrationForm: ApiEndpoint<
  GetRegistrationFormRequest,
  Record<string, unknown> | undefined
> = memoize(() => {
  const initial = undefined;
  const { api } = useRegistrationApi();
  const state = ref<RequestState>(RequestState.Uninitialized);
  const response = ref<Record<string, unknown> | undefined>(initial);

  ApiEndpointInitialization(api, state, response, initial);

  const execute = async () => {
    if (
      !api.value ||
      state.value === RequestState.Uninitialized ||
      state.value === RequestState.Loading
    ) {
      return;
    }

    try {
      state.value = RequestState.Loading;
      response.value = JSON.parse(await api.value.getRegistrationForm({}));
      state.value = RequestState.Success;
    } catch {
      response.value = initial;
      state.value = RequestState.Error;
    }
  };

  return {
    initial,
    state,
    response,
    execute
  };
});

export const useGetRegistration: ApiEndpoint<
  GetRegistrationRequest,
  HellewiGetRegistrationResponse | undefined
> = memoize(() => {
  const initial = undefined;
  const { api } = useRegistrationApi();
  const state = ref<RequestState>(RequestState.Uninitialized);
  const response = ref<HellewiGetRegistrationResponse | undefined>(initial);
  const ongoing = ref<PCancelable<HellewiGetRegistrationResponse> | undefined>(undefined);
  const currentParams = ref<GetRegistrationRequest | undefined>(undefined);
  const errorMessage = ref<string | undefined>();
  ApiEndpointInitialization(api, state, response, initial);

  const execute = async (params: GetRegistrationRequest) => {
    if (
      !api.value ||
      state.value === RequestState.Uninitialized ||
      (state.value === RequestState.Loading && isEqual(currentParams.value, params)) ||
      (state.value === RequestState.Success && isEqual(currentParams.value, params))
    ) {
      return;
    } else if (ongoing.value) {
      ongoing.value.cancel();
    }

    ongoing.value = new PCancelable(async (resolve, reject, onCancel) => {
      onCancel(() => reject('cancelled'));
      try {
        if (!api.value) {
          return;
        }

        const res = await api.value.getRegistration(params);

        resolve(res);
      } catch (err) {
        // eslint-disable-next-line @typescript-eslint/no-explicit-any
        reject(await (err as any).json());
      }
    });

    try {
      state.value = RequestState.Loading;
      currentParams.value = params;
      response.value = await ongoing.value;
      state.value = RequestState.Success;
    } catch (err) {
      if (err !== 'cancelled') {
        state.value = RequestState.Error;
      }
      // eslint-disable-next-line @typescript-eslint/no-explicit-any
      const error = err as any;
      const expired = error.errors?.find(
        (e: { message: string }) => e.message === 'Expiry time is not valid or is in the past'
      );
      if (expired) {
        errorMessage.value = 'registration.linkexpired';
      }
    }
    ongoing.value = undefined;
  };

  return {
    initial,
    state,
    response,
    execute,
    errorMessage
  };
});

export const usePostRegistration: ApiEndpoint<
  PostHellewiRegistrationRequest,
  HellewiPostRegistrationResponse | undefined
> = memoize(() => {
  const initial = undefined;
  const { api } = useRegistrationApi();
  const state = ref<RequestState>(RequestState.Uninitialized);
  const status = ref<number | undefined>();
  const response = ref<HellewiPostRegistrationResponse | undefined>(initial);

  ApiEndpointInitialization(api, state, response, initial);

  const execute = async (params: PostHellewiRegistrationRequest) => {
    if (
      !api.value ||
      state.value === RequestState.Uninitialized ||
      state.value === RequestState.Loading
    ) {
      return;
    }

    try {
      state.value = RequestState.Loading;
      const responseRaw = await api.value.postRegistrationRaw({
        requestBody: (params as unknown) as { [key: string]: Record<string, unknown> }
      });
      response.value = await responseRaw.value();
      state.value = RequestState.Success;
      status.value = HttpStatusCode.OK;
    } catch (err) {
      if (typeof err === 'object') {
        // eslint-disable-next-line @typescript-eslint/no-explicit-any
        const e = err as any;
        status.value = e?.status;
        response.value = await e?.json();
      }

      state.value = RequestState.Error;
    }
  };

  return {
    initial,
    state,
    response,
    execute,
    status
  };
});

export interface Registration {
  course: {
    id: number;
    price?: {
      id: number;
      installments?: number[];
    };
    lessonid?: number;
    spare?: boolean;
    courseProducts?: HellewiCourseProduct[];
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    [fieldname: string]: any;
  };
  client?: Record<string, unknown>;
  id: number;
  clientid: string;
}

export interface PostRegistrationPriceRequest {
  registrations: Registration[];
  discountcode?: string;
}

export const usePostRegistrationPrice: ApiEndpoint<
  PostRegistrationPriceRequest,
  RegistrationPriceNumber | undefined
> = memoize(() => {
  const initial = undefined;
  const { api } = useRegistrationApi();
  const state = ref<RequestState>(RequestState.Uninitialized);
  const response = ref<RegistrationPriceNumber | undefined>(initial);
  const ongoing = ref<PCancelable<RegistrationPriceNumber> | undefined>(undefined);
  const currentParams = ref<PostRegistrationPriceRequest | undefined>(undefined);

  ApiEndpointInitialization(api, state, response, initial);

  const execute = async (params: PostRegistrationPriceRequest) => {
    const paramsChanged = !isEqual(currentParams.value, params);

    if (
      !api.value ||
      state.value === RequestState.Uninitialized ||
      ((state.value === RequestState.Loading || state.value === RequestState.Success) &&
        !paramsChanged)
    ) {
      return;
    } else if (ongoing.value && paramsChanged) {
      ongoing.value.cancel();
    }

    ongoing.value = new PCancelable(async (resolve, reject, onCancel) => {
      onCancel(() => reject('cancelled'));
      try {
        if (!api.value) {
          return;
        }

        const res = await api.value.postRegistrationPrice({
          requestBody: (params as unknown) as { [key: string]: Record<string, unknown> }
        });

        resolve(res);
      } catch {
        reject();
      }
    });

    try {
      state.value = RequestState.Loading;
      currentParams.value = params;
      response.value = await ongoing.value;
      state.value = RequestState.Success;
    } catch (err) {
      if (err !== 'cancelled') {
        state.value = RequestState.Error;
      }
    }
    ongoing.value = undefined;
  };

  return {
    initial,
    state,
    response,
    execute
  };
});

export const usePostMyRegistrationsLoginLink: ApiEndpoint<
  PostMyRegistrationsLoginLinkRequest,
  void
> = memoize(() => {
  const initial = undefined;
  const { api } = useRegistrationApi();
  const state = ref<RequestState>(RequestState.Uninitialized);
  const response = ref<undefined>(initial);
  const ongoing = ref<PCancelable<void> | undefined>(undefined);
  const currentParams = ref<PostMyRegistrationsLoginLinkRequest | undefined>(undefined);

  ApiEndpointInitialization(api, state, response, initial);

  const execute = async (params: PostMyRegistrationsLoginLinkRequest) => {
    if (
      !api.value ||
      state.value === RequestState.Uninitialized ||
      (state.value === RequestState.Loading && isEqual(currentParams.value, params))
    ) {
      return;
    } else if (ongoing.value) {
      ongoing.value.cancel();
    }

    ongoing.value = new PCancelable(async (resolve, reject, onCancel) => {
      onCancel(() => reject('cancelled'));
      try {
        if (!api.value) {
          return;
        }

        const res = await api.value.postMyRegistrationsLoginLink(params);

        resolve(res);
      } catch {
        reject();
      }
    });

    try {
      state.value = RequestState.Loading;
      await ongoing.value;
      currentParams.value = params;
      state.value = RequestState.Success;
    } catch (err) {
      if (err !== 'cancelled') {
        state.value = RequestState.Error;
      }
    }
    ongoing.value = undefined;
  };

  return {
    initial,
    state,
    response,
    execute
  };
});

export const useGetMyRegistrations: ApiEndpoint<
  GetMyRegistrationsRequest,
  HellewiMyRegistrationsResponse | undefined
> = memoize(() => {
  const initial = undefined;
  const { api } = useRegistrationApi();
  const state = ref<RequestState>(RequestState.Uninitialized);
  const response = ref<HellewiMyRegistrationsResponse | undefined>(initial);
  const ongoing = ref<PCancelable<HellewiMyRegistrationsResponse> | undefined>(undefined);
  const currentParams = ref<GetMyRegistrationsRequest | undefined>(undefined);

  ApiEndpointInitialization(api, state, response, initial);

  const execute = async (params: GetMyRegistrationsRequest) => {
    if (
      !api.value ||
      state.value === RequestState.Uninitialized ||
      (state.value === RequestState.Loading && isEqual(currentParams.value, params)) ||
      (state.value === RequestState.Success && isEqual(currentParams.value, params))
    ) {
      return;
    } else if (ongoing.value) {
      ongoing.value.cancel();
    }

    ongoing.value = new PCancelable(async (resolve, reject, onCancel) => {
      onCancel(() => reject('cancelled'));
      try {
        if (!api.value) {
          return;
        }

        const res = await api.value.getMyRegistrations(params);

        resolve(res);
      } catch {
        reject();
      }
    });

    try {
      state.value = RequestState.Loading;
      currentParams.value = params;
      response.value = await ongoing.value;
      state.value = RequestState.Success;
    } catch (err) {
      if (err !== 'cancelled') {
        state.value = RequestState.Error;
      }
    }
    ongoing.value = undefined;
  };

  return {
    initial,
    state,
    response,
    execute
  };
});
