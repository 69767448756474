<!-- eslint-disable vue/multi-word-component-names -->
<!-- '>>>' syntax doesn't work with lang="scss" -->
<style scoped>
#app .help-content {
  padding: 0 1rem;
}

.help-content >>> h1 {
  font-size: 34px;
}

.help-content >>> h2 {
  font-size: 30px;
}

.help-content >>> h3 {
  font-size: 26px;
}

.help-content >>> h4 {
  font-size: 23px;
}

.help-content >>> p {
  margin: 1rem 0;
}

.help-content >>> ol {
  margin: 1rem 0 1rem 2rem;
}

.help-content >>> ul {
  margin: 1rem 0 1rem 2rem;
  list-style-type: '- ';
}

.help-content >>> blockquote {
  margin-left: 2rem;
}
</style>

<template>
  <div v-if="help && !isLoading" class="card">
    <div class="card-content">
      <article class="help-content" v-dompurify-html="help.text"></article>
    </div>
  </div>
</template>

<script lang="ts">
import { defineComponent, onBeforeMount, watch } from '@vue/composition-api';
import { useGetHelp } from '../hooks/useBrandApi';
import { stateIsLoading } from '../utils/api-utils';
import { translate } from '../utils/misc-utils';
import useTitle from '../hooks/useTitle';

export default defineComponent({
  setup: (props, ctx) => {
    const { response: help, execute: getHelp, state: getHelpState } = useGetHelp();
    const { setTitle } = useTitle();

    const isLoading = stateIsLoading(getHelpState);

    onBeforeMount(() => {
      getHelp();
      setTitle(translate(ctx, 'nav.help'));
    });

    watch(
      () => ctx.root.$i18n.locale,
      () => {
        getHelp();
      }
    );

    return { help, isLoading };
  }
});
</script>
