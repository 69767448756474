var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return (_vm.course)?_c('div',{staticClass:"registration-box"},[(_vm.price)?_c('div',{staticClass:"block"},[_c('strong',{staticClass:"is-size-3"},[_vm._v(_vm._s(_vm.$n(_vm.price, 'currency')))])]):_vm._e(),_c('div',{staticClass:"block"},[_c('CourseInfoDl',{staticClass:"courseDetails",attrs:{"course":_vm.course,"fields":_vm.courseInfoDlFields,"spacing":true}})],1),(
      _vm.course.registrationbegins ||
        _vm.course.registrationendshard ||
        _vm.course.registrationendssoft ||
        _vm.course.statuses.includes(_vm.HellewiCourseStatus.Cancelled) ||
        _vm.course.statuses.includes(_vm.HellewiCourseStatus.Interrupted)
    )?_c('div',{staticClass:"cart-availability"},[(!_vm.registrationToLessons)?_c('div',{staticClass:"block"},[_c('CourseAvailability',{attrs:{"participantcount":_vm.course.participantcount,"statuses":_vm.course.statuses}})],1):_vm._e()]):_vm._e(),(
      (_vm.course.registrationbegins || _vm.course.registrationendshard || _vm.course.registrationendssoft) &&
        !_vm.course.statuses.includes(_vm.HellewiCourseStatus.Cancelled) &&
        !_vm.course.statuses.includes(_vm.HellewiCourseStatus.Interrupted)
    )?_c('div',{staticClass:"add-to-cart-availability"},[(!_vm.registrationToLessons)?_c('b-button',{staticClass:"button is-primary is-medium cart-button",attrs:{"icon-left":"cart","aria-label":_vm.$t('cart.addtocart'),"disabled":!_vm.course.participantcount.registrationopen ||
          (_vm.course.participantcount.full && _vm.course.participantcount.sparefull)},nativeOn:{"click":function($event){return _vm.addToCart($event)}}},[_vm._v(" "+_vm._s(_vm.$t('cart.addtocart'))+" ")]):_vm._e()],1):_vm._e()]):_vm._e()}
var staticRenderFns = []

export { render, staticRenderFns }