import { HellewiAgeLimits } from '../api';

export type AgeLimitTranslation = [string, { age?: string; min?: string; max?: string }];

export const getAgeLimitTranslation = (ageLimits: HellewiAgeLimits): AgeLimitTranslation => {
  const min = ageLimits.minAge?.toString();
  const maxInt = ageLimits.maxAge;
  const max = maxInt?.toString();
  const maxPlusOne = maxInt ? (maxInt + 1).toString() : undefined;

  if (min && max) {
    return min === max ? ['agelimit.absoluteAge', { age: min }] : ['agelimit.minmax', { min, max }];
  }

  return maxPlusOne ? ['agelimit.max', { max: maxPlusOne }] : ['agelimit.min', { min }];
};
