import { setISODay } from 'date-fns';
import { groupBy, map, sortBy, values } from 'lodash/fp';

import { HellewiCourseDay } from '../api';

export interface FormatedWeekday {
  weekday: Date | undefined;
  times: WeekdayTime[];
}

export interface WeekdayTime {
  begins: string | undefined;
  ends: string | undefined;
}

/**
 * Combine an array of HellewiCourseDays with possible multiple times on a same day
 * to an array that has only one entry for each day, and that day's times in a
 * separate array.
 *
 * Weekday is also converted to a javascript date so that it can be formatted
 * with i18n. (date and time are faked with setISODay, so use only the weekday from that)
 *
 * Grouped days are not sorted as it would be difficult to get sunday last.
 * Rely on backed giving the days sorted correctly and groupBy retaining that order.
 *
 * @param days array of HellewiCourseDays to be combined
 * @returns combined weekdays with possibly lots of times on a single weekday
 */
export const formatWeekdays = (days: HellewiCourseDay[] | undefined): FormatedWeekday[] =>
  map(
    (groupedDays) => ({
      weekday: groupedDays[0].weekday ? setISODay(new Date(), groupedDays[0].weekday) : undefined,
      times: sortBy(
        ({ begins }) => begins,
        map(({ begins, ends }) => ({ begins, ends }), groupedDays)
      )
    }),
    values(groupBy((day) => day.weekday, days))
  );
