<!-- eslint-disable vue/multi-word-component-names -->
<style lang="scss" scoped>
.gradient {
  background: linear-gradient(89.96deg, #ffffff 0.03%, rgba(255, 255, 255, 0) 99.96%);
  padding: 4rem;
  border-radius: 12px;
  width: 100%;
}
section {
  filter: drop-shadow(0px 0px 10px rgba(0, 0, 0, 0.05));
  background-position: center;
  background-size: cover;
  margin-bottom: 1rem;

  @media (min-width: $desktop) {
    border-radius: 13px;
    border: 1px solid #f5f5f5;
  }
}
.title {
  margin-bottom: 2rem;
  font-weight: 700;
  font-family: var(--brandheaderfont);
  color: var(--brandcomplementarycolor);
}
.subtitle {
  color: var(--brandcomplementarycolor);
}
.wrapper {
  max-width: 27rem;
}

button {
  margin-left: 1rem;
}

.content {
  flex-direction: row;
  display: flex;
  width: 100%;
}

.field {
  flex: 1;
}

@media (max-width: 1025px) {
  section {
    margin: -1rem -2rem 1rem -2rem;
  }
  .gradient {
    border-radius: 0;
  }
}

@media (max-width: $tablet) {
  section {
    margin: -1rem -1rem 1rem -1rem;
  }
}

@media (max-width: 500px) {
  .gradient {
    padding: 3rem 2rem;
  }
  .title {
    font-size: 1.8rem;
  }
}
@media (max-width: 400px) {
  section {
    margin: -1rem -0.5rem 1rem -0.5rem;
  }
  .gradient {
    padding: 2rem;
  }
  .title {
    font-size: 1.6rem;
  }
}
</style>

<style lang="scss">
.callouts + .container > main > div > section:first-child {
  margin-top: 0;
}
</style>

<template>
  <section v-if="brand" :style="{ backgroundImage: `url('${brand.heroimage}')` }">
    <div class="gradient">
      <div class="wrapper">
        <h2 class="title">{{ brand.herotitle }}</h2>
        <p class="subtitle" v-dompurify-html="brand.herotext" />
        <div class="content">
          <label for="hero-search" class="is-sr-only">{{ $t('search.label') }}</label>
          <SearchInput
            id="hero-search"
            :placeholder="$t('herosearchplaceholder')"
            @updatevalue="updateValue"
            @search="search"
          />
          <b-button type="is-primary" @click="search" :aria-label="$t('search.search')">{{
            $t('search.search')
          }}</b-button>
        </div>
      </div>
    </div>
  </section>
</template>

<script lang="ts">
import { defineComponent, onBeforeMount, ref } from '@vue/composition-api';
import SearchInput from './SearchInput.vue';
import { useGetBrand } from '../../hooks/useBrandApi';

export default defineComponent({
  props: {
    currentKeyword: {
      type: String
    }
  },
  components: {
    SearchInput
  },
  setup: (props, ctx) => {
    const { response: brand, execute: getBrand } = useGetBrand();
    const inputValue = ref<string | undefined>();

    const updateValue = (value: string) => {
      inputValue.value = value;
    };

    const search = () => {
      ctx.emit('search', inputValue.value);
    };

    onBeforeMount(() => {
      getBrand();
    });

    return { search, updateValue, brand };
  }
});
</script>
