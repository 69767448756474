/* eslint-disable */

import { extend } from 'vee-validate';
import { email, regex, required } from 'vee-validate/dist/rules';
import {
  adjustBirthDateForLastHundredYears,
  isParticipantTooOld,
  isParticipantTooYoung,
  parseBirthDateFromPin
} from './utils/agelimit';

export const initVeeValidate = () => {
  extend('required', {
    ...required,
    message: 'validation.required'
  });

  extend('email', {
    ...email,
    message: 'validation.email'
  });

  extend('pin', {
    ...regex,
    message: 'validation.patternPin'
  });

  extend('birthday', {
    ...regex,
    message: 'validation.patternBirthDay'
  });

  extend('participantTooYoung', {
    validate: (value: string, args) => {
      const stringArray = args as string[];
      if (!stringArray[0]) {
        return true;
      }
      const minDate = new Date(stringArray[0]);
      const birthDate = parseBirthDateFromPin(value);
      const parsedBirthDate = adjustBirthDateForLastHundredYears(birthDate);
      return !isParticipantTooYoung(minDate, parsedBirthDate);
    },
    message: 'validation.participantTooYoung'
  });
  extend('participantTooOld', {
    validate: (value: string, args) => {
      const stringArray = args as string[];
      if (!stringArray[0]) {
        return true;
      }
      const maxDate = new Date(stringArray[0]);
      const birthDate = parseBirthDateFromPin(value);
      const parsedBirthDate = adjustBirthDateForLastHundredYears(birthDate);
      return !isParticipantTooOld(maxDate, parsedBirthDate);
    },
    message: 'validation.participantTooOld'
  });
};
