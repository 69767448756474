import './installCompositionApi.ts';
import '../frontend-assets/buefy.scss';
import '@mdi/font/css/materialdesignicons.css';
import 'leaflet/dist/leaflet.css';

import Buefy from 'buefy';
import Vue from 'vue';
import VueI18n from 'vue-i18n';
import VueDOMPurifyHTML from 'vue-dompurify-html';
import App from './App.vue';
import {
  capitalize,
  formatDate,
  formatDateRange,
  formatDateTime,
  formatDateTimeRange,
  formatMidnight,
  formatTime,
  formatTimeRange
} from './filters';
import { initializeI18n } from './i18n';
import router from './router';
import { initVeeValidate } from './vee-validate';

// fix missing GlobalFetch in openapi-generator generated code
declare module './api/runtime' {
  type GlobalFetch = WindowOrWorkerGlobalScope;
}

initVeeValidate();

Vue.use(Buefy);
Vue.use(VueI18n);
Vue.use(VueDOMPurifyHTML, {
  default: {
    ADD_ATTR: ['target']
  }
});

Vue.config.productionTip = false;

Vue.filter('capitalize', capitalize);
Vue.filter('date', formatDate);
Vue.filter('dateRange', formatDateRange);
Vue.filter('dateTime', formatDateTime);
Vue.filter('dateTimeRange', formatDateTimeRange);
Vue.filter('midnight', formatMidnight);
Vue.filter('time', formatTime);
Vue.filter('timeRange', formatTimeRange);

new Vue({
  i18n: initializeI18n(),
  router,
  render: (h) => h(App)
}).$mount('#app');
