<!-- eslint-disable vue/multi-word-component-names -->
<style scoped lang="scss">
.footer-container {
  width: 100%;
  padding: 4rem;
  background: linear-gradient(270deg, rgba(255, 255, 255, 0.31) 0%, rgba(255, 255, 255, 0) 100%),
    $primary;
  background-color: var(--brandcolor);
  flex-direction: column;
  margin-top: 6rem;

  @media (min-width: $desktop) and (max-width: $fullhd) {
    .container {
      padding: 0 2rem;
    }
  }
}

.flex-container {
  display: flex;
  justify-content: space-between;
}

p,
a,
strong {
  color: $white;
}

strong {
  font-weight: $weight-bold;
}

.logo {
  display: flex;
  flex: 1;
  object-fit: contain;
  max-width: 20rem;
  align-self: stretch;
  //placeholder logo
  background: transparent no-repeat center right;
  background-size: contain;
}

.info-container {
  flex: 1;
}

.links .icon {
  color: $white;
  margin-right: 0.7rem;
}

.sr-only:not(:focus):not(:active) {
  clip: rect(0 0 0 0);
  clip-path: inset(50%);
  height: 1px;
  overflow: hidden;
  position: absolute;
  white-space: nowrap;
  width: 1px;
}

@media (max-width: $tablet) {
  .flex-container {
    flex-direction: column;
    justify-content: flex-start;
  }
  .info-container {
    flex: unset;
  }
  .logo {
    margin-top: 1rem;
    height: 10rem;
    background-position: center;
    flex: unset;
  }
}

@media (max-width: 500px) {
  .footer-container {
    padding: 2rem;
  }
}
</style>

<template>
  <footer class="footer-container" v-if="brand">
    <div class="container flex-container">
      <div class="info-container">
        <p class="block-small">
          <strong>{{ brand.name }}</strong>
        </p>
        <p v-if="brand.location" class="block-small">
          {{ brand.location.address }}<br />{{ brand.location.postalcode }}
          {{ brand.location.city }}
        </p>
        <p v-if="phoneLink" class="block-small">
          <a :href="phoneLink">{{ brand.phone }}</a>
        </p>
        <p v-else-if="brand.phone" class="block-small">{{ brand.phone }}</p>
        <p v-if="brand.email" class="block-small">
          <a :href="emailLink">{{ brand.email }}</a>
        </p>
        <p v-if="brand.homepage" class="block-small">
          <a :href="addHttpsToUrl(brand.homepage)">{{ brand.homepage }}</a>
        </p>
        <p v-if="brand.accessibilitystatementurl" class="block-small">
          <a :href="addHttpsToUrl(brand.accessibilitystatementurl)">{{
            $t('nav.accessibilitystatement')
          }}</a>
        </p>
        <p v-if="brand.privacystatementurl" class="block-small">
          <a :href="addHttpsToUrl(brand.privacystatementurl)">{{ $t('nav.privacystatement') }}</a>
        </p>
        <router-link
          v-if="brand.privacystatement"
          :to="{ name: 'help', hash: '#privacystatement' }"
          >{{ $t('privacystatement') }}</router-link
        >
        <div class="links block-small">
          <a v-if="brand.facebook" :href="addHttpsToUrl(brand.facebook)"
            ><b-icon icon="facebook" />
            <div class="sr-only">Facebook</div></a
          >
          <a v-if="brand.instagram" :href="addHttpsToUrl(brand.instagram)"
            ><b-icon icon="instagram" />
            <div class="sr-only">Instagram</div></a
          >
          <a v-if="brand.linkedin" :href="addHttpsToUrl(brand.linkedin)"
            ><b-icon icon="linkedin" />
            <div class="sr-only">LinkedIn</div></a
          >
          <a v-if="brand.twitter" :href="addHttpsToUrl(brand.twitter)"
            ><b-icon icon="twitter" />
            <div class="sr-only">X</div></a
          >
        </div>
      </div>
      <span v-if="brand.logo" class="logo" :style="{ backgroundImage: `url(${brand.logo})` }" />
    </div>
  </footer>
</template>

<script lang="ts">
import { computed, defineComponent, onBeforeMount } from '@vue/composition-api';
import { useGetBrand } from '../hooks/useBrandApi';

export default defineComponent({
  setup: () => {
    const { response: brand, execute: getBrand } = useGetBrand();

    const emailLink = computed(() => 'mailto:' + brand.value?.email);
    const phoneLink = computed(() =>
      brand.value?.phone && brand.value?.phone.match(/^\+?[0-9 ()-]+$/)
        ? `tel:${brand.value?.phone.replace(/[ ()-]/g, '')}`
        : undefined
    );
    const addHttpsToUrl = (url: string) => (url.match(/^http/) ? url : `https://${url}`);

    onBeforeMount(() => {
      getBrand();
    });

    return { addHttpsToUrl, brand, emailLink, phoneLink };
  }
});
</script>
