<style scoped lang="scss">
form {
  display: flex;

  .email-input {
    margin-right: 1rem;
  }

  @media (max-width: 564px) {
    flex-direction: column;

    .email-input {
      margin-bottom: 1rem;
      margin-right: 0;
    }
  }
}
p {
  margin-bottom: 1.5rem;
}

.card-content {
  padding: 4rem 3rem;
}
</style>

<template>
  <div class="card">
    <ValidationObserver ref="observer">
      <ValidationProvider
        :vid="email"
        :rules="{
          required: true,
          email: true
        }"
      >
        <div class="card-content" slot-scope="{ errors }">
          <h2 class="title">{{ $t('nav.myregistrations') }}</h2>
          <p>{{ $t('myregistrations.email.help') }}</p>

          <form @submit.prevent="submit">
            <b-field
              class="email-input"
              :type="{ 'is-danger': errors[0] }"
              :message="$t(errors[0])"
            >
              <b-input lazy :placeholder="$t('fieldlabel.email')" v-model="email" type="email" />
            </b-field>

            <b-button
              class="is-primary"
              type="submit"
              :loading="isLoading"
              @click="submit"
              :aria-label="$t('myregistrations.email.send')"
            >
              {{ $t('myregistrations.email.send') }}
            </b-button>
          </form>
        </div>
      </ValidationProvider>
    </ValidationObserver>
  </div>
</template>

<script lang="ts">
import { defineComponent, onBeforeMount, ref, Ref, watch } from '@vue/composition-api';
import { ValidationObserver, ValidationProvider } from 'vee-validate';

import { PostHellewiMyRegistrationsLoginLinkRequest } from '../api/models/PostHellewiMyRegistrationsLoginLinkRequest';
import { usePostMyRegistrationsLoginLink } from '../hooks/useRegistrationApi';
import useTitle from '../hooks/useTitle';
import { stateHasError, stateIsLoading, stateIsSuccess, useToast } from '../utils/api-utils';
import { onBeforeRouteLeave, translate } from '../utils/misc-utils';

export default defineComponent({
  components: { ValidationObserver, ValidationProvider },
  setup: (props, ctx) => {
    const { setTitle } = useTitle();
    const observer = ref<InstanceType<typeof ValidationObserver> | null>(null);
    const { state, execute } = usePostMyRegistrationsLoginLink();

    const { warnToast, clearErrorToasts, successToast } = useToast(ctx);

    const email: Ref<string> = ref('');
    const isLoading: Ref<boolean> = stateIsLoading(state);

    const submit = async () => {
      const requestBody = {
        email: email.value
      } as PostHellewiMyRegistrationsLoginLinkRequest;

      if (await observer.value?.validate()) {
        execute({ requestBody });
      }
    };

    watch(state, () => {
      if (stateHasError(state).value) {
        warnToast('myregistrations.error');
        return;
      }

      if (stateIsSuccess(state).value) {
        successToast('myregistrations.success');
      }

      clearErrorToasts();
    });

    onBeforeMount(() => setTitle(translate(ctx, 'nav.myregistrations')));
    onBeforeRouteLeave((to, from, next) => {
      clearErrorToasts();
      next();
    });

    return { email, isLoading, observer, submit, ValidationProvider };
  }
});
</script>
