<!-- eslint-disable vue/multi-word-component-names -->
<style lang="scss" scoped>
.card {
  width: 100%;
  margin-bottom: 2rem;
  &:last-child {
    margin-bottom: 0 !important;
  }
}

.buttonSection {
  margin-top: 2rem;
  margin-bottom: 2rem;
}

.buttonSection button:not(:last-child) {
  margin-right: 1rem;
}

.noBorder {
  border: 0;
}

.content {
  display: flex;
  width: 100%;
}

.summary-section {
  width: 35rem;
  margin-left: 2rem;
  position: relative;
}

.summary {
  position: sticky;
  top: 80px;
}

.mobile-buttons {
  display: none;
}

.formSection {
  width: 100%;
}

@media (max-width: 1225px) {
  .content {
    flex-direction: column;
  }
  .summary-section {
    max-width: 100%;
    margin: 0 auto;
  }
  .mobile-buttons {
    display: block;
  }
  .desktop-buttons {
    display: none;
  }
}
</style>

<style>
.modal-card-head p.modal-card-title {
  flex-shrink: unset !important;
}
</style>

<template>
  <div>
    <div v-if="cartItems && cartItems.length > 0 && schema">
      <div class="content">
        <section class="formSection">
          <ClientCard
            v-for="(client, i) in clients"
            :key="client.id"
            :cartItems="cartItems"
            :schema="schema"
            :client="client"
            :clientCount="clients.length"
            :availableCartItems="availableCartItems"
            :ajvErrors="ajvErrors"
            :isPayer="payerFromClient && i === 0"
            :validationTrigger="validationTrigger"
            @update-prices="updateClientPrices"
            @update-client="updateClient"
            @remove-client="removeClient"
            @validate="checkValidation"
            @remove-cart-item="removeCartItem"
          />

          <section class="buttonSection desktop-buttons">
            <b-button
              v-if="clients.length < cartItems.length && availableCartItems.length > 0"
              type="is-primary"
              icon-left="account-plus"
              @click="addClient"
              :aria-label="$t('add.client')"
            >
              {{ $t('add.client') }}
            </b-button>
          </section>

          <PayerCard
            v-if="showPayer"
            :client="clients[0]"
            :schema="schema"
            :ajvErrors="ajvErrors"
            :validationTrigger="validationTrigger"
            :payerFromClientInitial="payerFromClient"
            @update-payer="updatePayer"
            @update-payer-from-client="updatePayerFromClient"
            @validate="checkValidation"
          />
        </section>

        <section class="buttonSection mobile-buttons">
          <b-button
            v-if="clients.length < cartItems.length && availableCartItems.length > 0"
            type="is-primary"
            icon-left="account-plus"
            @click="addClient"
            :aria-label="$t('add.client')"
          >
            {{ $t('add.client') }}
          </b-button>
        </section>

        <section class="summary-section">
          <div class="summary" v-if="purchase">
            <CartSummary
              :cartItems="cartItems"
              :clients="clients"
              :purchase="purchase"
              :postRegIsLoading="postRegIsLoading"
              @check-discount-code="checkDiscountCode"
              @send="startValidation"
              @remove-cart-item="removeCartItem"
            />
          </div>
        </section>
      </div>
    </div>

    <div class="card" v-if="cartItems && cartItems.length === 0 && !isLoading">
      <div class="card-content">
        <h2 class="title">{{ $t('nav.cart') }}</h2>
        <p>{{ $t('cart.empty') }}</p>
      </div>
    </div>
  </div>
</template>

<script lang="ts">
import $RefParser from '@apidevtools/json-schema-ref-parser';

import { JSONSchema } from '@apidevtools/json-schema-ref-parser/dist/lib/types';
import {
  defineComponent,
  onBeforeMount,
  ref,
  computed,
  watch,
  onUnmounted,
  set,
  onMounted
} from '@vue/composition-api';
import { ErrorObject } from 'ajv';
import { ToastProgrammatic as Toast, DialogProgrammatic as Dialog } from 'buefy';
import {
  filter,
  find,
  get,
  groupBy,
  intersection,
  isArray,
  isEmpty,
  isEqual,
  keys,
  map,
  pick,
  reduce,
  some,
  values,
  zipAll
} from 'lodash/fp';

import {
  HellewiCartItem,
  HellewiCoursePrice,
  HellewiCoursePriceInstallment,
  HellewiCourseProduct,
  HellewiTenantType,
  PostHellewiRegistrationPayer,
  PostHellewiRegistrationRegistration
} from '../api';
import ClientCard from '../components/cart/ClientCard.vue';
import PayerCard from '../components/cart/PayerCard.vue';
import CartSummary from '../components/cart/CartSummary.vue';
import useTitle from '../hooks/useTitle';
import {
  filterUndefineds,
  handleWindowUnload,
  onBeforeRouteLeave,
  translate
} from '../utils/misc-utils';
import {
  Registration,
  useGetRegistrationForm,
  usePostRegistration,
  usePostRegistrationPrice
} from '../hooks/useRegistrationApi';
import { useGetBrand } from '../hooks/useBrandApi';
import { useAddToCart, useCartItems, useCartStatus, useDeleteFromCart } from '../hooks/useCartApi';
import { stateHasError, stateIsLoading, stateIsSuccess, useToast } from '../utils/api-utils';
import router from '../router';
import HttpStatusCode from '../utils/http-status-codes';
import Vue from 'vue';

export interface ClientCourseDetails {
  price?: HellewiCoursePrice;
  installmentgroup?: HellewiCoursePriceInstallment;
  courseProducts?: HellewiCourseProduct[];
  questions?: Array<{ id: number; value?: string; optionid?: number }>;
}

export interface Client {
  id: string;
  selectedCartItemIds: number[];
  fields: { [key: string]: string | number | boolean };
  courses: {
    [courseId: string]: ClientCourseDetails;
  };
}

export interface Payer {
  [key: string]: string | number | boolean;
}

export interface AjvError extends ErrorObject {
  clientid: string;
  field: string;
}

export default defineComponent({
  components: {
    ClientCard,
    PayerCard,
    CartSummary
  },
  setup: (props, ctx) => {
    const schema = ref<JSONSchema>();
    const { response: registrationForm, execute: getRegistrationForm } = useGetRegistrationForm();
    const { response: purchase, execute: postRegistrationPrice } = usePostRegistrationPrice();
    const {
      execute: addToCartRequest,
      state: addToCartState,
      errorMessage: addToCartError
    } = useAddToCart();
    const {
      status: postRegStatus,
      state: postRegState,
      execute: postRegistration,
      response: postRegResponse
    } = usePostRegistration();
    const { execute: deleteFromCart, state: deleteFromCartState } = useDeleteFromCart();
    const {
      response: cartItems,
      execute: getCartItems,
      setResponse: setCartItems,
      state: cartItemsState
    } = useCartItems();
    const { response: cartStatus, setResponse: setCartStatus } = useCartStatus();
    const { warnToast, clearErrorToasts, successToast } = useToast(ctx);
    const { setTitle } = useTitle();
    const { response: brand, execute: getBrand } = useGetBrand();

    const clients = ref<Client[]>([]);
    const discountcode = ref<string>('');
    const payer = ref<Payer>({});
    const ajvErrors = ref<AjvError[]>([]);
    const validationResults = ref<boolean[]>([]);
    const deletedItemId = ref<number>(0);
    const validationTrigger = ref<boolean>(false);
    // this default can be changed in schema watcher
    const payerFromClient = ref<boolean>(false);

    const isLoading = stateIsLoading(cartItemsState);
    const postRegIsLoading = stateIsLoading(postRegState);

    const showPayer = computed<boolean>(() => Boolean(schema.value?.properties?.payer));

    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    const registrationsSchemaItemToRegistration = (schemaItem: any): Registration | undefined => {
      const { client: schemaClient, id: schemaId } = schemaItem.properties;
      const schemaClientProps = schemaClient.allOf[0].properties;

      const id = schemaId.const as number;
      const cartItem = find((ci) => ci.id === id, cartItems.value);
      const formClient = find((c) => c.selectedCartItemIds.includes(id), clients.value);

      if (!cartItem || !cartItem.course || !formClient) {
        return undefined;
      }

      const { id: courseId, lessonid } = cartItem.itemid;

      const clientid = formClient.id;
      const formCourse = formClient.courses[`${courseId}-${lessonid}`];
      if (!formCourse) {
        return undefined;
      }
      // empty installments means that the price is paid as whole,
      // convert empty array to undefined
      const installments = !isEmpty(formCourse.installmentgroup?.installments)
        ? formCourse.installmentgroup?.installments.map((i) => i.id)
        : undefined;

      const price = formCourse.price && {
        id: formCourse.price.id,
        installments
      };

      const { spare, sparecounter } = cartItem;

      const questions = formCourse.questions;

      const course = {
        id: Number(courseId),
        lessonid,
        spare,
        sparecounter,
        questions,
        price,
        courseProducts: formCourse.courseProducts
      };

      const client = pick(keys(schemaClientProps), formClient.fields);

      return { clientid, client, id, course };
    };

    const registrations = computed<Registration[]>(() => {
      if (
        !schema.value ||
        !schema.value.properties ||
        !cartItems.value ||
        typeof schema.value.properties.registrations === 'boolean' ||
        !Array.isArray(schema.value.properties.registrations.items)
      ) {
        return [];
      }

      const ret = map(
        registrationsSchemaItemToRegistration,
        schema.value.properties.registrations.items
      );

      return filter((r) => r !== undefined, ret) as Registration[];
    });

    const availableCartItems = computed<HellewiCartItem[]>(() => {
      return (
        cartItems.value?.filter(
          (cartItem) => !clients.value.some((c) => c.selectedCartItemIds.includes(cartItem.id))
        ) || []
      );
    });

    const getCoursePriceDefaults = (items: HellewiCartItem[]) =>
      items.reduce<{ [courseId: string]: ClientCourseDetails }>((acc, item) => {
        if (!item.course?.prices || item.course.prices.length === 0) {
          return acc;
        }

        acc[`${item.itemid.id}-${item.itemid.lessonid}`] = {};

        const defaultPrice =
          item.course.prices.find((p) => p._default === true) || item.course.prices[0];

        if (defaultPrice && registrationForm.value) {
          acc[`${item.itemid.id}-${item.itemid.lessonid}`].price = defaultPrice;

          // schema is parsed from registrationForm and according to typing doesn't
          // include $defs any more (although probably does), so use the raw
          // untyped response registrationForm here
          // eslint-disable-next-line @typescript-eslint/no-explicit-any
          const courseDef = (registrationForm.value as any).$defs[`course-${item.course.id}`];
          const priceInSchema = courseDef.properties.price.oneOf.find(
            // eslint-disable-next-line @typescript-eslint/no-explicit-any
            (p: any) => p.properties.id.const === defaultPrice?.id
          );

          if (priceInSchema && priceInSchema.required.includes('installments')) {
            acc[`${item.itemid.id}-${item.itemid.lessonid}`].installmentgroup =
              defaultPrice.installmentgroups?.[0];
          } else {
            acc[`${item.itemid.id}-${item.itemid.lessonid}`].installmentgroup = {
              name: translate(ctx, 'cart.installments.default'),
              installments: []
            };
          }
        }

        return acc;
      }, {});

    // Add client ID and field name to error object
    const formatAjvErrors = (errors: ErrorObject[]): AjvError[] => {
      return errors.map((err) => {
        const isPayer = err.instancePath.includes('/payer');
        const split = err.instancePath.split('/');
        let field;

        // instancePath doesn't have the field name if the validation keyword is 'required'
        // so it has to be parsed from the error message, located between single quotes
        if (err.keyword === 'required' && err.message) {
          const matches = /'(.*?)'/g.exec(err.message);
          field = matches ? matches[0].replaceAll("'", '') : '';
        } else {
          field = split[split.length - 1];
        }

        const registration = registrations.value[parseInt(split[2], 10)];

        return {
          ...err,
          field,
          clientid: isPayer ? 'payer' : registration.clientid || 'payer'
        };
      });
    };

    const startValidation = () => {
      validationResults.value = [];
      validationTrigger.value = true;
    };

    const addClient = () => {
      if (cartItems.value && clients.value.length >= cartItems.value.length) {
        warnToast('cart.client.maxclients');
        return;
      }

      const courseDefaults = getCoursePriceDefaults(cartItems.value || []);

      clients.value.push({
        id: Date.now().toString(),
        selectedCartItemIds: availableCartItems.value.map((ci) => ci.id),
        fields: {},
        courses: courseDefaults
      });
    };

    const updateClient = (newClient: Client) => {
      clients.value = clients.value.map((c) => (c.id === newClient.id ? newClient : c));
    };

    const updateClientPrices = async (updatedClient: Client) => {
      const index = clients.value.findIndex((client) => client.id === updatedClient.id);

      if (index === -1) {
        return;
      }

      set(clients.value, index, updatedClient);

      postRegistrationPrice({
        registrations: registrations.value,
        discountcode: discountcode.value
      });
    };

    const updatePayer = (newPayer: Payer) => {
      payer.value = newPayer;
    };

    const updatePayerFromClient = (value: boolean) => {
      payerFromClient.value = value;
    };

    const removeCartItem = (id: number) => {
      deleteFromCart({ id });
      deletedItemId.value = id; // need to save this as this is not available in the response watcher
    };

    const removeClient = (id: string) => {
      if (clients.value.length < 2) {
        Toast.open({
          message: ctx.root.$t('cart.client.remove.error') as string,
          position: 'is-bottom',
          type: 'is-danger'
        });

        return;
      }

      clients.value = clients.value.filter((p) => p.id !== id);
    };

    const checkValidation = (result: boolean) => {
      validationResults.value = [...validationResults.value, result];
    };

    const checkDiscountCode = async (code: string) => {
      discountcode.value = code;

      postRegistrationPrice({
        registrations: registrations.value,
        discountcode: discountcode.value
      });
    };

    onBeforeRouteLeave((to, from, next) => {
      if (
        cartItems.value?.length === 0 ||
        postRegStatus?.value === HttpStatusCode.OK ||
        cartStatus.value?.count === 0 ||
        !cartStatus.value?.timeleft
      ) {
        next();
      } else {
        Dialog.confirm({
          title: translate(ctx, 'cart.leavecart.title'),
          message: translate(ctx, 'cart.leavecart.message'),
          confirmText: translate(ctx, 'cart.leavecart.confirm'),
          cancelText: translate(ctx, 'cart.leavecart.cancel'),
          hasIcon: true,
          onConfirm: () => next(),
          onCancel: () => next(false)
        });
      }
    });

    onBeforeMount(() => {
      const { ids } = router.currentRoute.query;
      if (ids) {
        const unlisted = router.currentRoute.query.unlistedid
          ? {
              expiry: String(router.currentRoute.query.expiry),
              reqid: String(router.currentRoute.query.reqid),
              unlistedid: String(router.currentRoute.query.unlistedid),
              hmac: String(router.currentRoute.query.hmac)
            }
          : {};
        const req = isArray(ids)
          ? (ids as string[]).map((id) => ({ id, ...unlisted }))
          : [{ id: ids, ...unlisted }];
        addToCartRequest(req);
      } else {
        getCartItems();
        getRegistrationForm({});
        getBrand();
      }
    });

    onMounted(() => {
      window.addEventListener('beforeunload', handleWindowUnload);
      setTitle(translate(ctx, 'nav.cart'));
    });

    onUnmounted(() => {
      window.removeEventListener('beforeunload', handleWindowUnload);
      clearErrorToasts();
    });

    watch(addToCartState, () => {
      // on both success and error, load items and form
      if (!stateIsLoading(addToCartState).value) {
        getCartItems();
        getRegistrationForm({});
      }

      if (stateHasError(addToCartState).value) {
        if (addToCartError?.value === 'Course too many times in cart') {
          warnToast('cart.add.error.toomany');
        } else {
          warnToast('cart.add.error.default');
        }
      }
    });

    // logic for initializing and updating clients is under schema watcher
    // because getCoursePriceDefaults needs to have schema loaded, so even
    // though the watcher itself doesn't need anything from schema the logic
    // cannot be in cartItems watcher
    watch(cartItems, () => {
      // update schema
      getRegistrationForm({});
    });

    watch(schema, () => {
      if (clients.value.length === 0 && cartItems.value) {
        // initialize clients so that there are as many clients as there are cart
        // items for the most "popular" course
        // each client will as much different courses as possible, first will have
        // all different ones and next ones as much as there are courses availabe
        // in cart
        const grouped = groupBy((ci) => `${ci.itemid.id}-${ci.itemid.lessonid}`, cartItems.value);
        clients.value = zipAll(values(grouped)).map(
          (cartItemsForDifferentCoursesWithUndefineds, i) => {
            const cis = filterUndefineds(cartItemsForDifferentCoursesWithUndefineds);

            return {
              id: String(i + 1),
              selectedCartItemIds: cis.map((item) => item.id),
              fields: {},
              // initialize all clients to have information for all courses
              // so that changing a course from one client to another
              // works correctly
              courses: getCoursePriceDefaults(cartItems.value || [])
            };
          }
        );
        // if any of the registrations has pin-field as hetu, set payerFromClient true
        // (first client will have the most comprehensive set of questions even if all
        // courses would not require hetu)
        // note that the pin field can be optional or required
        if (
          some(
            isEqual(get('$defs.pinHetu', schema.value)),
            map(
              get('properties.client.allOf[1]'),
              get('properties.registrations.items', schema.value)
            )
          ) &&
          brand.value?.type !== HellewiTenantType.SummerSchool
        ) {
          payerFromClient.value = true;
        }
      } else {
        // update existing clients based on new cart items, i.e.
        // remove those clients that don't have cartItems any more
        const cartItemIds = map((ci) => ci.id, cartItems.value);
        clients.value = clients.value.filter(
          (client) => !isEmpty(intersection(client.selectedCartItemIds, cartItemIds))
        );
      }
    });

    watch(deleteFromCartState, () => {
      if (stateHasError(deleteFromCartState).value) {
        warnToast('cart.delete.error');
        return;
      }

      if (stateIsSuccess(deleteFromCartState).value) {
        setCartItems(cartItems.value?.filter((ci) => ci.id !== deletedItemId.value));
        successToast('cart.delete.success');

        if (cartItems.value?.length === 0) {
          router.push({ name: 'home' });
        }
      }

      clearErrorToasts();
    });

    watch(registrationForm, async () => {
      if (!registrationForm.value) {
        return;
      }

      try {
        schema.value = await $RefParser.dereference(registrationForm.value);
      } catch (e) {
        warnToast('cart.schema.error');
      }
    });

    watch(postRegState, () => {
      if (stateIsSuccess(postRegState).value) {
        setCartStatus({ count: 0, timeleft: 0, manuallyCleared: true });
        clearErrorToasts();
        // continue to registration confirmation page, payment options here
        router.push({ name: 'new-registration' });
      } else if (stateHasError(postRegState).value) {
        switch (postRegStatus?.value) {
          case HttpStatusCode.CONFLICT: {
            const duplicateerrors = filter((error) => {
              return error.message === 'Client already registered to course';
            }, postRegResponse.value?.errors);
            const errormessages = map((error) => {
              const r = find((reg) => {
                return reg.id === parseInt(error.resource, 10);
              }, registrations.value);
              const cartItem = find((c) => {
                return c.id === r?.id;
              }, cartItems.value);
              return translate(ctx, 'cart.client.validation.alreadyregisteredinfo', {
                // eslint-disable-next-line @typescript-eslint/no-explicit-any
                name: (r?.client as any).firstname + ' ' + (r?.client as any).lastname,
                course: String(cartItem?.course.name)
              });
            }, duplicateerrors);
            warnToast(errormessages.join('\n'), false);
            break;
          }
          case HttpStatusCode.BAD_REQUEST:
            if (
              some((error) => {
                return error.message === 'Payer cannot by underaged';
              }, postRegResponse.value?.errors)
            ) {
              warnToast('cart.client.validation.underagepayer');
            } else if (
              some((error) => {
                return error.keyword === 'controllist';
              }, postRegResponse.value?.errors)
            ) {
              const clError = find((error) => {
                return error.keyword === 'controllist';
              }, postRegResponse.value?.errors);
              warnToast(clError.message);
            } else if (
              some((error) => {
                return error.keyword === 'minItems';
              }, postRegResponse.value?.errors)
            ) {
              warnToast('cart.client.validation.allcoursesmusthaveclient');
            } else {
              warnToast('cart.client.validation.error');
            }
            ajvErrors.value = formatAjvErrors(postRegResponse.value?.errors || []);
            break;
          default:
            warnToast('cart.client.validation.apierror');
            break;
        }
      }
    });

    watch(registrations, (oldValue, newValue) => {
      if (!oldValue) {
        return;
      }

      const pricesChanged = oldValue.find(
        (r, i) =>
          !newValue[i] ||
          !isEqual(r.course.price, newValue[i].course.price) ||
          !isEqual(r.course.courseProducts, newValue[i].course.courseProducts)
      );

      if (pricesChanged || oldValue.length !== newValue.length) {
        postRegistrationPrice({
          registrations: registrations.value,
          discountcode: discountcode.value
        });
      }
    });

    // This removes possible empty strings from form post so that backend validation does not
    // trigger against them when validating with regexp patterns
    /* eslint-disable @typescript-eslint/no-explicit-any */
    const removeEmpty = function<T>(obj: T): T {
      const inputIsArray = Array.isArray(obj);
      if (!inputIsArray) {
        const input = obj as { [key: string]: any };
        const newObj: { [key: string]: any } = {};
        Object.entries(input).forEach(([k, v]) => {
          if (typeof v === 'object') {
            newObj[k] = removeEmpty(v);
          } else if (v !== '') {
            newObj[k] = input[k];
          }
        });
        return (newObj as unknown) as T;
      } else {
        const input = (obj as unknown) as any[];
        const newObj: any[] = [];
        input.forEach((v: any) => {
          if (typeof v === 'object') {
            newObj.push(removeEmpty(v));
          } else if (v !== '') {
            newObj.push(v);
          }
        });
        return (newObj as unknown) as T;
      }
    };
    /* eslint-enable @typescript-eslint/no-explicit-any */

    // The validation flow:
    // CartSummary passes down an event to this component changing the value of 'validationTrigger' to 'true'
    // PayerCard and RegistrationForm have the 'validationTrigger' as prop and they're watching changes on it,
    // starting validation whenever it turns in to true. The results of the validation for each form are passed down
    // as an event back to this component and the results are collected in the ref 'validationResults'
    watch(validationResults, () => {
      // showPayer means that PayerForm is shown
      const numberOfForms = clients.value.length + Number(showPayer.value);

      // Proceed only if all of the forms have been validated
      if (validationResults.value.length === numberOfForms) {
        validationTrigger.value = false;
      } else {
        return;
      }

      const sendPayer = !(
        (!schema.value ||
          !schema.value.required ||
          !(Array.isArray(schema.value.required) && schema.value.required.includes('payer'))) &&
        filter((v) => v, Object.values(payer.value)).length === 0
      );

      // Check if payer is defined at all and
      // send only the keys that are defined in the schema
      const payerOnlyValidKeys = get('value.properties.payer.properties', schema)
        ? reduce(
            (acc, key) => {
              return { ...acc, [key]: payer.value[key] || undefined };
            },
            {},
            Object.keys(get('value.properties.payer.properties', schema))
          )
        : undefined;

      // Send the request to backend only if all the forms have been validated successfully
      if (validationResults.value.every((result) => result === true)) {
        postRegistration({
          payer:
            sendPayer && payerOnlyValidKeys
              ? (payerOnlyValidKeys as PostHellewiRegistrationPayer)
              : undefined,
          // convert registration.value to correct type
          // Registration defines client as not required but it is required in the backend
          registrations: removeEmpty(
            (registrations.value as unknown) as PostHellewiRegistrationRegistration[]
          ),
          discountcode: discountcode.value
        });
      } else {
        warnToast('cart.client.validation.error');

        Vue.nextTick(() => {
          const firstError = document.querySelector('.input.is-danger:first-of-type');
          if (firstError) {
            const scrollOffset = 130;
            const elementPosition = firstError.getBoundingClientRect().top;
            const offsetPosition = window.pageYOffset + elementPosition - scrollOffset;

            window.scrollTo({
              top: offsetPosition,
              behavior: 'smooth'
            });
          }
          return;
        });
      }
    });

    return {
      addClient,
      ajvErrors,
      availableCartItems,
      cartItems,
      checkValidation,
      clients,
      isLoading,
      payer,
      payerFromClient,
      postRegIsLoading,
      purchase,
      registrations,
      removeCartItem,
      removeClient,
      schema,
      checkDiscountCode,
      showPayer,
      startValidation,
      updateClient,
      updateClientPrices,
      updatePayer,
      updatePayerFromClient,
      validationTrigger
    };
  }
});
</script>
