<!--
  These components are almost the same:
  - MyRegistrations
  - SingleRegistration
  - NewRegistration
  - Payment

  Remember to update all if they require updating.
-->

<template>
  <div v-if="registration" class="registration">
    <Registration
      :registration="registration"
      :titleTranslationKey="titleTranslationKey"
      :showRegistrationPrices="false"
      :showInvoices="true"
      :showPaymentColumn="false"
    />
  </div>

  <div v-else-if="hasError || invalidParameters" class="card">
    <div class="card-content">
      <h1 class="title">{{ $t('payment.title.error') }}</h1>
      <p v-if="errorMessage">{{ $t(errorMessage) }}</p>
      <p v-else class="mt-4">{{ $t('registration.getRegistrationError') }}</p>
    </div>
  </div>
</template>

<script lang="ts">
import { defineComponent, onMounted, ref } from '@vue/composition-api';

import { useGetRegistration } from '../hooks/useRegistrationApi';
import useTitle from '../hooks/useTitle';
import router from '../router';
import { stateHasError } from '../utils/api-utils';
import { translate } from '../utils/misc-utils';

import Registration from '../components/Registration.vue';

export default defineComponent({
  components: { Registration },
  setup: (props, ctx) => {
    const {
      response: registration,
      execute: getRegistration,
      state: getRegisrationState,
      errorMessage
    } = useGetRegistration();
    const { setTitle } = useTitle();

    const {
      referencenumber: referencenumberRaw,
      reqid: reqIdRaw,
      expiry: expiryRaw,
      hmac: hmacRaw
    } = router.currentRoute.query;

    const reqid = reqIdRaw ? (reqIdRaw as string) : undefined;
    const expiry = expiryRaw ? new Date(expiryRaw as string) : undefined;
    const hmac = hmacRaw ? (hmacRaw as string) : undefined;
    const referencenumber = referencenumberRaw ? (referencenumberRaw as string) : undefined;
    const titleTranslationKey = 'registration.registrations';

    const hasError = stateHasError(getRegisrationState);
    const invalidParameters = ref(false);

    onMounted(() => {
      if (referencenumber && reqid && expiry && hmac) {
        getRegistration({ referencenumber, reqid, expiry, hmac });
      } else {
        invalidParameters.value = true;
      }
      setTitle(translate(ctx, titleTranslationKey));
    });

    return {
      hasError,
      invalidParameters,
      registration,
      titleTranslationKey,
      errorMessage
    };
  }
});
</script>
